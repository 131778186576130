import DropdownIcon from "components/icons/DropdownIcon";
import SelectBox, { ISelectBoxOptions } from "devextreme-react/select-box";
import Validator, { RangeRule } from "devextreme-react/validator";
import { useEffect, useRef, useState } from "react";
import { GetNewId } from "shared/utilities/CommonUtility";
import "./styles/PromineoSelectBox.css";

interface Props {
  fieldName?: string;
  required?: boolean;
  widthClass?: string;
  dropdownWidth?: string;
  dropdownWrapperClass?: string;
}

export default function PromineoSelectBox(props: Props & ISelectBoxOptions) {
  const { className, fieldName, required, ...rest } = props;
  const [validatorRules, setValidatorRules] = useState<any[]>([]);

  useEffect(() => {
    const setValidationRules = () => {
      const rules = [];
      if (required) {
        const rule = (
          <RangeRule
            min={0}
            key={`${GetNewId()}-${fieldName}`}
            message={`${fieldName} is required.`}
          />
        );
        rules.push(rule);
      }

      setValidatorRules(rules);
    };

    setValidationRules();
  }, [fieldName, required]);

  const getClassNames = () => {
    let defaultClassNames = `promineo-selectbox`;

    if(props.widthClass){
      defaultClassNames += ` ${props.widthClass}`;
    }else{
      defaultClassNames += " w-[188px]"
    }

    return className ? `${defaultClassNames} ${className}` : defaultClassNames;
  };

  const DropdownButton = () => {
    let dropdownIconClassName = "promineo-select-dropdown-button mt-2 ml-3";

    return <div className={dropdownIconClassName}>{<DropdownIcon />}</div>;
  };

  const handleValueChanged = (evt: any) => {
    if (evt.component.__ignoreEvent) {
      evt.component.__ignoreEvent = false;
      return;
    }

    // Clearing search text should not be considered as value change.
    // So, here we are preventing value change event propagation when clearing the search using backspace.
    if (
      (evt.value === null && !evt.event) ||
      (evt.event &&
        evt.event.originalEvent &&
        evt.event.originalEvent.key === "Backspace")
    ) {
      evt.component.__ignoreEvent = true;
      // This will propagate value change event again.
      // To avoid that we are keeping track of call using __ignoreEvent property from component (suggested by devextreme support).
      // We could have used ref field to do so.
      evt.component.option("value", evt.previousValue);
      return;
    }

    props.onValueChanged?.(evt);
  };

  const dropdownOptions = useRef({
    wrapperAttr: { class: `promineo-select-dropdown-popup ${props.dropdownWrapperClass}` },
    width: props.dropdownWidth || "auto",
  });

  return (
    <SelectBox
      dropDownOptions={dropdownOptions.current}
      dropDownButtonRender={DropdownButton}
      className={getClassNames()}
      {...rest}
      onValueChanged={handleValueChanged}
    >
      <Validator>{validatorRules}</Validator>
    </SelectBox>
  );
}
