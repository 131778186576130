
export default function ReloadIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8763 18.7415V17.0332C11.3485 16.9638 11.8105 16.8388 12.2621 16.6582C12.7132 16.4776 13.1402 16.2415 13.543 15.9499L14.7096 17.1582C14.1263 17.6026 13.5152 17.9601 12.8763 18.2307C12.2374 18.5018 11.5707 18.6721 10.8763 18.7415ZM9.20964 18.7415C7.29297 18.4915 5.70269 17.6618 4.4388 16.2524C3.17491 14.8424 2.54297 13.186 2.54297 11.2832C2.54297 10.2415 2.74102 9.2657 3.13714 8.3557C3.53269 7.44626 4.06741 6.65459 4.7413 5.9807C5.41464 5.30737 6.2063 4.77265 7.1163 4.37654C8.02575 3.98098 9.0013 3.7832 10.043 3.7832H10.168L8.8763 2.49154L10.043 1.2832L13.3763 4.61654L10.043 7.94987L8.8763 6.7832L10.2096 5.44987H10.043C8.41797 5.44987 7.03964 6.01598 5.90797 7.1482C4.77575 8.27987 4.20964 9.6582 4.20964 11.2832C4.20964 12.7276 4.68186 13.9951 5.6263 15.0857C6.57075 16.1757 7.76519 16.8249 9.20964 17.0332V18.7415ZM15.918 15.9499L14.7096 14.7832C15.0013 14.3804 15.2374 13.9532 15.418 13.5015C15.5985 13.0504 15.7235 12.5888 15.793 12.1165H17.5013C17.4319 12.811 17.2619 13.4776 16.9913 14.1165C16.7202 14.7554 16.3624 15.3665 15.918 15.9499ZM17.5013 10.4499H15.793C15.7235 9.97765 15.5985 9.5157 15.418 9.06404C15.2374 8.61293 15.0013 8.18598 14.7096 7.7832L15.918 6.61654C16.3624 7.1582 16.7166 7.75543 16.9805 8.4082C17.2444 9.06098 17.418 9.74154 17.5013 10.4499Z"
        fill="white"
        style={{ fill: "white;", fillOpacity: "1" }}
      />
    </svg>
  );
}
