import BorderComponent from "components/common/BorderComponent";
import PromineoButton, { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoModal from "components/modal/PromineoModal";
import HostFieldSelectionDropdown from "features/setup/configs/config-common/HostFieldSelectionDropdown";
import FieldMappingFormulaCreateRequest from "interfaces/request/FieldMappingFormulaCreateRequest";
import FieldMappingFormulaUpdateRequest from "interfaces/request/FieldMappingFormulaUpdateRequest";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { useCallback, useEffect, useState } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { FieldMappingFormulaType } from "shared/enums/FieldMappingFormulaType";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import { createNewFieldMappingFormulaAsync, loadCurrentUserFieldMappingFormulaAsync, modifyFieldMappingFormulaAsync } from "store/actions/FieldMappingFormulaActions";
import { addOrUpdateCustomFormulaInFormulaPickerDataSource } from "store/actions/FieldMappingFormulaPickerUIAction";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { setFieldMappingFormulaPickerAccordionDataSource } from "store/slices/FieldMappingFormulaPickerUISlice";
import ExampleFormulaPickerDropdown from "./ExampleFormulaPickerDropdown";
import FormulaTesterModal from "./formula-tester/FormulaTesterModal";

interface Props {
  fieldMapping: FieldMappingResponse;
  configDirection: ConfigDirection;
  onCancel: () => void;
  onSave: (data: FieldMappingResponse) => void;
  selectedCodeSet?: number | null;
}

export default function AddMappingFormulaDialog(props: Props) {
  const { fieldMapping, onCancel, onSave } = props;
  const [currentFormulaValue, setCurrentFormulaValue] = useState<string | null | undefined>(fieldMapping.formula);
  const [currentDescriptionValue, setCurrentDescriptionValue] = useState<string | null | undefined>("");
  const [selectedHostField, setSelectedHostField] = useState<HostFieldResponse | null | undefined>();
  const [isFormulaSyntaxValid, setIsFormulaSyntaxValid] = useState<boolean>(true);
  const [formulaUpdateRequest, setFormulaUpdateRequest] = useState<{updateRequest: FieldMappingFormulaUpdateRequest, formula: FieldMappingFormulaResponse} | null>(null);
  const [isFormulaTesterVisible, setIsFormulaTesterVisible] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const selectedConnect = useAppSelector(
    (store) => store.connectorData.selectedDetailedConnector
  );

  const fieldMappingFormulas = useAppSelector(
    (store) => store.fieldMappingFormulaData.fieldMappingFormulas
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadCurrentUserFieldMappingFormulaAsync())
    .unwrap()
    .then((formulaResponse: FieldMappingFormulaResponse[]) => {
      dispatch(setFieldMappingFormulaPickerAccordionDataSource(formulaResponse))
      })
    .finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    if (
      selectedConnect &&
      fieldMapping &&
      fieldMapping.mappedConnectorHostFieldId
    ) {
      const hostField = selectedConnect.hostFields.find(
        (field) => field.id === fieldMapping.mappedConnectorHostFieldId
      );
      setSelectedHostField(hostField);
    }
  }, [selectedConnect, fieldMapping]);

  const checkFormulaValidity = (formula: string) => {
    let isValid = false;
    if (props.configDirection === ConfigDirection.Receiving) {
      if (selectedHostField) {
        isValid = true;
      }
    } else {
      if (selectedHostField && formula.includes("[VALUE]")) {
        isValid = true;
      } else if (!selectedHostField && !formula.includes("[VALUE]")) {
        isValid = true;
      }
    }

    return isValid;
  };

  const onFormulaSave = () => {
    if (currentFormulaValue?.trim()) {
      if (checkFormulaValidity(currentFormulaValue) === false) {
        setIsFormulaSyntaxValid(false);
      } else {
        setIsFormulaSyntaxValid(true);
        const fieldMappingToUpdate: FieldMappingResponse = {
          ...fieldMapping,
          formula: currentFormulaValue,
          mappedConnectorHostFieldName: selectedHostField
            ? selectedHostField.displayText
            : "",
          mappedConnectorHostFieldId: selectedHostField
            ? selectedHostField.id
            : 0,
        };
        onSave(fieldMappingToUpdate);
        onCancel();
      }
    } else {
      toastError("Formula is required");
    }
  };

  const createNewCustomFormula = (
    formulaCreateRequest: FieldMappingFormulaCreateRequest
  ) => {
    displayLoadingPanel();
    dispatch(createNewFieldMappingFormulaAsync(formulaCreateRequest))
      .unwrap()
      .then((result: FieldMappingFormulaResponse) => {
        toastSuccess("Custom formula added.");
        dispatch(addOrUpdateCustomFormulaInFormulaPickerDataSource(result));
      })
      .finally(hideLoadingPanel);
  };

  const updateExistingFormula = (
    formula: FieldMappingFormulaResponse,
    updateRequest: FieldMappingFormulaUpdateRequest
  ) => {
    if(!formula.isCurrentUserCreator){
      toastError("Formula belongs to another user under the tenant.");
    }
    else{
      displayLoadingPanel();
      dispatch(
        modifyFieldMappingFormulaAsync({
          fieldMappingFormulaId: formula.id,
          requestDto: updateRequest,
        })
      )
        .unwrap()
        .then((result: FieldMappingFormulaResponse) => {
          toastSuccess("Custom formula updated.");
          dispatch(addOrUpdateCustomFormulaInFormulaPickerDataSource(result));
        })
        .finally(hideLoadingPanel);
    }
    setFormulaUpdateRequest(null);
  };

  const handleFormulaChangeOnInput = useCallback((evt: any) => {
    setCurrentFormulaValue(evt.event?.target.value);
  }, []);

  const handleDescriptionChangeOnInput = useCallback((evt: any) => {
    setCurrentDescriptionValue(evt.event?.target.value);
  }, []);

  const handleFormulaChangeOnSelection = (
    formula: FieldMappingFormulaResponse
  ) => {
    setCurrentFormulaValue((prevFormulaValue) => {
      if (prevFormulaValue) {
        return `${prevFormulaValue} && ${formula.formula}`;
      } else {
        return formula.formula;
      }
    });
  };

  const handleCreateNewFormulaClick = ()=>{
    let errors : string[] = [];
    let formulaValue = currentFormulaValue?.trim();
    let descriptionValue = currentDescriptionValue?.trim();

    if(formulaValue)
    {
      let formulaToUpdate = fieldMappingFormulas.find(f=> f.formulaType === FieldMappingFormulaType.Custom && f.formula === formulaValue);
      if(formulaToUpdate){
        let formulaUpdateRequest: FieldMappingFormulaUpdateRequest = {
          formula: formulaValue ?? "",
          description: descriptionValue ?? ""
        };
        setFormulaUpdateRequest({updateRequest: formulaUpdateRequest, formula: formulaToUpdate})
      }
      else {
        let formulaCreateRequest: FieldMappingFormulaCreateRequest = {
          formula: formulaValue ?? "",
          description: descriptionValue ?? ""
        }
        createNewCustomFormula(formulaCreateRequest);
      }
    }
    else {
      toastError("Formula is required");
    }
  }

  const showFormulaTesterModal = () => setIsFormulaTesterVisible(true);
  const hideFormulaTesterModal = () => setIsFormulaTesterVisible(false);

  const handleAppendAndClose = (formula: string) => {
    setCurrentFormulaValue((prevFormulaValue) => {
      if (prevFormulaValue?.trim()) {
        return `${prevFormulaValue} && ${formula}`;
      } else {
        return formula;
      }
    });
    hideFormulaTesterModal();
  };

  return (
    <>
      <PromineoModal
        isVisible={true}
        displayDefaultActions={true}
        coloredBg={true}
        width={800}
        height={640}
        editorActionButtonProps={{
          mode: PromineoModalMode.Create,
          cancelButtonCaption: "Cancel",
          createButtonCaption: "Save",
          doneButtonCaption: "Done",
          testButtonCaption: "Formula Tester",
          onCancel: onCancel,
          onCreate: onFormulaSave,
          onDone: onFormulaSave,
          onTest: showFormulaTesterModal,
        }}
      >
        {fieldMapping && (
          <div>
            <div className="font-poppins font-semibold leading-[27px] text-18px mb-4">
              Mapping Formula - {fieldMapping.name}
            </div>
            <BorderComponent customClass="mb-4" />
            <div className="ml-2">
              <div className="font-inter font-normal leading-[18px] text-[14px] mb-4 flex justify-between">
                <div className="w-96">
                  Host field{" "}
                  {props.configDirection === ConfigDirection.Receiving
                    ? ""
                    : "(used as [VALUE] in formula)"}
                </div>
                <HostFieldSelectionDropdown
                  width={435}
                  defaultValue={fieldMapping.mappedConnectorHostFieldId}
                  planningObjectType={props.fieldMapping.planningObjectType}
                  onHostFieldSelectionChange={setSelectedHostField}
                  selectedCodeSet={props.selectedCodeSet}
                  showClearButton={true}
                />
              </div>
              <div className="font-inter font-normal leading-[18px] text-[14px] mb-4 flex justify-between">
                <div className="w-48">Example formula picker</div>
                <ExampleFormulaPickerDropdown
                  onFormulaSelectionChange={handleFormulaChangeOnSelection}
                  mappingPlanningObjectType={fieldMapping.planningObjectType}
                />
              </div>
            </div>

            <div className="font-poppins font-semibold leading-[21px] text-[14px] mb-1">
              Formula
            </div>
            <PromineoMultilineTextEdit
              height={152}
              placeholder="Type..."
              className="whitespace-pre-line leading-[16px] text-[12px] font-inter"
              value={currentFormulaValue ?? undefined}
              onChange={handleFormulaChangeOnInput}
            ></PromineoMultilineTextEdit>

            <div className="font-poppins font-semibold leading-[21px] text-[14px] mt-2 mb-1">
              Description
            </div>
            <PromineoMultilineTextEdit
              height={152}
              placeholder="Type..."
              className="whitespace-pre-line leading-[16px] text-[12px] font-inter"
              value={currentDescriptionValue ?? undefined}
              onChange={handleDescriptionChangeOnInput}
            ></PromineoMultilineTextEdit>

            <PromineoButton onClick={handleCreateNewFormulaClick} variant={PromineoButtonType.BorderlessWithBlueText}
              className="mt-1 font-poppins font-semibold leading-[21px] text-[14px]">
              + Add current formula as custom formula
            </PromineoButton>

            {!isFormulaSyntaxValid && (
              <div className="font-inter leading-[21px] text-[14px] mb-2 flex w-48 text-red text-semibold">
                Invalid syntax
              </div>
            )}
          </div>
        )}
      </PromineoModal>

      {formulaUpdateRequest ? (
        <PromineoConfirmationDialog
          minWidth="450"
          width="400"
          height="auto"
          content={`Are you sure you want to overwrite this custom formula?`}
          subContent="The formula you are about to add already exists. Do you want to overwrite the current custom formula with a new formula and description?"
          onConfirm={()=>{
            updateExistingFormula(formulaUpdateRequest.formula,formulaUpdateRequest.updateRequest)
          }}
          onCancel={() => setFormulaUpdateRequest(null)}
          confirmButtonText="Overwrite"
        ></PromineoConfirmationDialog>
      ) : (
        <></>
      )}

      {isFormulaTesterVisible && (
        <FormulaTesterModal
          onClose={hideFormulaTesterModal}
          onAppendAndClose={handleAppendAndClose}
        />
      )}
    </>
  );
}
