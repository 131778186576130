import ExportAllCalendarsField from "features/common/host-system-parameter-fields/safran/ExportAllCalendarsField";
import ExportAllResourcesField from "features/common/host-system-parameter-fields/safran/ExportAllResourcesField";
import ExportScopeField from "features/common/host-system-parameter-fields/safran/ExportScopeField";
import OtherStructureField from "features/common/host-system-parameter-fields/safran/OtherStructureField";
import RFieldsExportOptionField from "features/common/host-system-parameter-fields/safran/RFieldsExportOptionField";
import UseSNETWhenNoPredecessorsField from "features/common/host-system-parameter-fields/safran/UseSNETWhenNoPredecessors";
import WbsField from "features/common/host-system-parameter-fields/safran/WbsField";
import useSafranSendingParameterDataLoader, {
  SafranSendingParameterDataLoaderProps,
} from "hooks/SafranSendingParameterDataLoader";
import SafranExportConfig from "interfaces/host-system-config/safran/SafranExportConfig";
import { useMemo } from "react";
import {
  exportAllCalendarsPropertyName,
  exportAllResourcesPropertyName,
  exportScopePropertyName,
  otherStructuresPropertyName,
  rFieldsExportOptionPropertyName,
  useSNETWhenNoPredecessorsPropertyName,
  wbsStructureExportFieldNamePropertyName,
} from "shared/constants/SafranHostParameterPropertyName";
import { EditorMode } from "shared/enums/EditorMode";

interface Props {
  exportConfig: SafranExportConfig;
  mode: EditorMode;
  selectedCodeSet: number | null;
  selectedConnectorId: number;
  handleConfigurationValueChanged?: (fieldName: string, value: any) => void;
  isOverride?: boolean;
}

export default function SafranSendingParameterFields(props: Props) {
  const {
    mode,
    exportConfig,
    selectedCodeSet,
    selectedConnectorId,
    handleConfigurationValueChanged,
  } = props;

  const classNames = props.isOverride
    ? "grid grid-cols-3 gap-x-16 gap-y-8"
    : "space-y-6";

  const dataLoaderProps = useMemo<SafranSendingParameterDataLoaderProps>(() => {
    return {
      exportConfig: exportConfig,
      selectedCodeSet: selectedCodeSet,
      selectedConnectorId: selectedConnectorId,
    };
  }, [exportConfig, selectedCodeSet, selectedConnectorId]);

  const {
    wbsStructureCandidates,
    structuresToExportCandidates,
    exportScopes,
    rFieldExportOptions,
  } = useSafranSendingParameterDataLoader(dataLoaderProps);

  return (
    <div className={classNames}>
      <ExportScopeField
        mode={mode}
        dropdownOptions={exportScopes}
        fieldName={exportScopePropertyName}
        selectedValue={exportConfig.exportScope}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <RFieldsExportOptionField
        mode={mode}
        fieldName={rFieldsExportOptionPropertyName}
        dropdownOptions={rFieldExportOptions}
        selectedValue={exportConfig.rFieldsExportOption}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <ExportAllResourcesField
        mode={mode}
        fieldName={exportAllResourcesPropertyName}
        switchButtonValue={exportConfig.exportAllResources}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <ExportAllCalendarsField
        mode={mode}
        fieldName={exportAllCalendarsPropertyName}
        switchButtonValue={exportConfig.exportAllCalendars}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <WbsField
        mode={mode}
        fieldName={wbsStructureExportFieldNamePropertyName}
        wbsCandidates={wbsStructureCandidates}
        wbsValue={exportConfig.wbsStructureExportFieldName}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <OtherStructureField
        mode={mode}
        structuresToExportCandidates={structuresToExportCandidates}
        fieldName={otherStructuresPropertyName}
        selectedValues={exportConfig.otherStructures}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
      <UseSNETWhenNoPredecessorsField
        mode={mode}
        fieldName={useSNETWhenNoPredecessorsPropertyName}
        useSNETWhenNoPredecessorsValue={exportConfig.useSNETWhenNoPredecessors}
        handleConfigurationValueChanged={handleConfigurationValueChanged}
      />
    </div>
  );
}
