import CrossIcon from "components/icons/CrossIcon";
import PromineoModal from "components/modal/PromineoModal";
import PromineoViewGrid from "./grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import FieldDetailValueResponse from "interfaces/response/FieldDetailValueResponse";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";

interface Props {
  fieldName: string;
  allowBlank?: boolean | null;
  values: FieldDetailValueResponse[];
  onClose: () => void;
}

export default function FieldValueModal(props: Props) {
  return (
    <PromineoModal isVisible={true} width={600} height={600}>
      <div className="flex justify-between mb-4">
        <div className="font-bold font-poppins">
          Field Values - {props.fieldName}
        </div>
        <div className="cursor-pointer" onClick={props.onClose}>
          <CrossIcon />
        </div>
      </div>
      <div className="flex mb-2 font-normal text-10px space-x-1">
        <div>Allow blanks:</div>
        <div>{props.allowBlank ? "Yes" : "No"}</div>
      </div>
      <div className="font-medium font-inter">
        <PromineoViewGrid
          dataSource={props.values}
          height={500}
          className="promineo-grid-in-modal"
          gridIdentifier="fieldValuesGrid"
          toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
        >
          <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
          <Column caption={"Value"} dataField={"code"} />
          <Column caption={"Description"} dataField={"description"} />
        </PromineoViewGrid>
      </div>
    </PromineoModal>
  );
}
