interface Props {
  color?: string;
}

export default function TickIcon({ color }: Props) {
  return (
    <svg
      width="13"
      height="10"
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.50091 7.80104L1.70091 5.00104L0.767578 5.93437L4.50091 9.66771L12.5009 1.66771L11.5676 0.734375L4.50091 7.80104Z"
        fill={color}
      />
    </svg>
  );
}
