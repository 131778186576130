import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import EmptyTestResultPlaceholder from "./empty-result/EmptyTestResultPlaceholder";
import { useCallback, useMemo } from "react";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserSender: boolean;
  isCurrentUserOwner: boolean;
  isViewMode: boolean;
  divIdsToExclude: string[];
}

export default function IEATester(props: Props) {
  const { exchangeAgreement, isViewMode, divIdsToExclude } = props;
  const { partnerSigner } = exchangeAgreement;

  const getMarginHeight = useCallback(() => {
    if (isViewMode) {
      return partnerSigner ? 230 : 190;
    } else {
      return partnerSigner ? 145 : 105;
    }
  }, [isViewMode, partnerSigner]);

  const remainingHeightProps = useMemo(() => {
    const marginHeight = getMarginHeight();
    return {
      excludedContainerIds: divIdsToExclude,
      marginHeight,
    }
  }, [props.divIdsToExclude, getMarginHeight]);

  
  const gridHeight = useRemainingContentLayoutHeight(remainingHeightProps);

  return (
    <EmptyTestResultPlaceholder
      exchangeAgreement={exchangeAgreement}
      gridHeight={gridHeight}
      isCurrentUserSender={props.isCurrentUserSender}
      isCurrentUserOwner={props.isCurrentUserOwner}
    />
  );
}
