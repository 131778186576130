import AnalyticsExportConfig from "interfaces/host-system-config/analytics/AnalyticsExportConfig";
import AnalyticsImportConfig from "interfaces/host-system-config/analytics/AnalyticsImportConfig";
import FileStorageExportConfig from "interfaces/host-system-config/fileStorage/FileStorageExportConfig";
import FileStorageImportConfig from "interfaces/host-system-config/fileStorage/FileStorageImportConfig";
import MicrosoftExcelExportConfig from "interfaces/host-system-config/MicrosoftExcelExportConfig";
import MicrosoftExcelImportConfig from "interfaces/host-system-config/MicrosoftExcelImportConfig";
import MicrosoftProjectExportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectExportConfig";
import MicrosoftProjectImportConfig from "interfaces/host-system-config/microsoftProject/MicrosoftProjectImportConfig";
import PrimaveraExportConfig from "interfaces/host-system-config/primavera/PrimaveraExportConfig";
import PrimaveraImportConfig from "interfaces/host-system-config/primavera/PrimaveraImportConfig";
import SafranBaseExportConfig from "interfaces/host-system-config/safran/SafranBaseExportConfig";
import SafranBaseImportConfig from "interfaces/host-system-config/safran/SafranBaseImportConfig";
import SapExportConfig from "interfaces/host-system-config/sap/SapExportConfig";
import { SapImportConfig } from "interfaces/host-system-config/sap/SapImportConfig";
import ConnectorScheduleSimplifiedResponse from "interfaces/response/ConnectorScheduleSimplifiedResponse";
import UserSimplifiedResponse from "interfaces/response/UserSimplifiedResponse";
import { CutoffAdjustmentType } from "shared/enums/feature/analytics-host-system/CutoffAdjustmentType";
import { RevisionType } from "shared/enums/feature/analytics-host-system/RevisionType";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { IncludeMappingType } from "shared/enums/feature/IncludeMappingTypeEnum";
import { PrimaveraActivityLinkRebuildOption } from "shared/enums/feature/PrimaveraActivityLinkRebuildOption";
import { SapExportFilterType } from "shared/enums/feature/sap-host-system/SapExportFilterType";
import { norwegianDateFormatForDevextreme } from "./DateTimeUtility";
import { getValueFromJsonString } from "./JsonUtility";

interface ExchangeParameterConfigs {
  export: string;
  import: string;
}

const microsoftExcelExportParameter: MicrosoftExcelExportConfig = {};
const microsoftExcelImportParameter: MicrosoftExcelImportConfig = {};

const safranImportParameter: SafranBaseImportConfig = {
  activityFilter: "",
  calendarPrefix: "",
};

const safranExportParameter: SafranBaseExportConfig = {
  activityFilter: "",
  exportScope: 0,
};

const analyticsExportParameter: AnalyticsExportConfig = {
  activityFilter: "",
  revisionType: RevisionType.Baseline,
};

const analyticsImportParameter: AnalyticsImportConfig = {
  revisionType: RevisionType.Baseline,
  adjustCutoffFromTransferDate: false,
  cutoffAdjustmentType: CutoffAdjustmentType.ScheduledTransferDate,
};

const microsoftProjectExportParameter: MicrosoftProjectExportConfig = {
  activityFilter: "",
  includeSummaryTask: false,
  wbsStructureExportFieldName: "",
};

const microsoftProjectImportParameter: MicrosoftProjectImportConfig = {};

const primaveraExportParameter: PrimaveraExportConfig = {
  activityFilter: "",
  wbsStructureExportFieldName: "",
  otherStructures: "",
};
const primaveraImportParameter: PrimaveraImportConfig = {
  activityFilter: "",
  epsId: "",
  importAsNewProject: false,
  allowAppendingTasks: true,
  allowAppendResource: true,
  allowAppendingCalendars: true,
  allowAppendingStructures: true,
  doNotImportWbs: false,
  includeMapping: IncludeMappingType.CoreFieldsAndSpecifiedMappings,
  activityLinkRebuildOption:
    PrimaveraActivityLinkRebuildOption.RebuildIfBothActivitiesExist,
};

const sapExportParameter: SapExportConfig = {
  orderFilter: "",
  operationFilter: "",
  exportFilterType: SapExportFilterType.OrderFilter,
};

const sapImportParameter: SapImportConfig = {
  functions: [],
  stopOnError: false,
  includeMapping: IncludeMappingType.CoreFieldsAndSpecifiedMappings,
};

const fileStorageExportParameter: FileStorageExportConfig = {
  activityFilter: "",
};

const fileStorageImportParameter: FileStorageImportConfig = {};

const ExchangeParameterConfig: Record<HostSystem, ExchangeParameterConfigs> = {
  [HostSystem.None]: { export: "", import: "" },
  [HostSystem.MicrosoftExcel]: {
    export: JSON.stringify(microsoftExcelExportParameter),
    import: JSON.stringify(microsoftExcelImportParameter),
  },
  [HostSystem.Safran]: {
    export: JSON.stringify(safranExportParameter),
    import: JSON.stringify(safranImportParameter),
  },
  [HostSystem.IlapAnalytics]: {
    export: JSON.stringify(analyticsExportParameter),
    import: JSON.stringify(analyticsImportParameter),
  },
  [HostSystem.MicrosoftProject]: {
    export: JSON.stringify(microsoftProjectExportParameter),
    import: JSON.stringify(microsoftProjectImportParameter),
  },
  [HostSystem.PrimaveraP6]: {
    export: JSON.stringify(primaveraExportParameter),
    import: JSON.stringify(primaveraImportParameter),
  },
  [HostSystem.SAP]: {
    export: JSON.stringify(sapExportParameter),
    import: JSON.stringify(sapImportParameter),
  },
  [HostSystem.FileStorage]: {
    export: JSON.stringify(fileStorageExportParameter),
    import: JSON.stringify(fileStorageImportParameter),
  },
};

export const getExchangeParameterConfig = (hostSystem: HostSystem) => {
  var parameters = { ...ExchangeParameterConfig[hostSystem] };

  return parameters;
};

export const getExchangeConfigPropertyValue = (
  configJsonString: string,
  propertyName: string
) => {
  return getValueFromJsonString(configJsonString, propertyName);
};

export const shouldDisplayExchangeParametersTab = (
  hostSystem?: HostSystem
): boolean => {
  var shouldDisplay = true;

  if (hostSystem === null || hostSystem === undefined) {
    shouldDisplay = false;
  } else {
    if (
      hostSystem === HostSystem.Safran ||
      hostSystem === HostSystem.IlapAnalytics
    ) {
      shouldDisplay = false;
    }
  }

  return shouldDisplay;
};

export const getScheduleLabel = (
  connectorSchedule: ConnectorScheduleSimplifiedResponse
): string => {
  var scheduleTitle = "-";

  if (connectorSchedule) {
    scheduleTitle = `Id: ${connectorSchedule.scheduleId} - ${connectorSchedule.title}`;
  }

  return scheduleTitle;
};

export const getDisplayExpressionForScheduleSelectorDropdown = (
  connectorSchedule: ConnectorScheduleSimplifiedResponse
): string => {
  return (
    connectorSchedule &&
    `Id: ${connectorSchedule.scheduleId} - ${connectorSchedule.title}`
  );
};

const perCharacterWidth = 5;
const seeMoreContainerWidth = 80;

const getTextContainerWidth = (text: string) => {
  let widthOfContainer = 0;
  if (text) {
    // gap between text and delete icon - 8px
    // width of delete icon - 8px
    // both side padding - 8*2px = 16px
    widthOfContainer = text.length * perCharacterWidth + 32;
  }
  return widthOfContainer;
};

export const getUserToShowCount = (
  containerWidth: number,
  userList: UserSimplifiedResponse[],
  selectedUsers: number[],
  numberOfLines?: number
) => {
  let currnetUserIndex = 0;
  for (
    let currentLine = 1;
    currentLine <= (numberOfLines ?? 0) &&
    currnetUserIndex < selectedUsers.length;
    currentLine++
  ) {
    if (currentLine === numberOfLines) {
      let requiredWidthForLastLine = 0;
      for (let i = currnetUserIndex; i < selectedUsers.length; i++) {
        const user = userList.find((x) => x.id === selectedUsers[i]);
        let textContainerWidth =
          getTextContainerWidth(user?.fullName ?? "") + 8;
        if (
          containerWidth - requiredWidthForLastLine - textContainerWidth >
          seeMoreContainerWidth
        ) {
          currnetUserIndex = i + 1;
        }

        requiredWidthForLastLine += textContainerWidth;
        if (requiredWidthForLastLine > containerWidth) {
          break;
        }
      }

      if (requiredWidthForLastLine <= containerWidth) {
        currnetUserIndex = selectedUsers.length;
      }
    } else {
      let currentLineRemainingWidth = containerWidth;
      let hasGap = false;
      while (currnetUserIndex < selectedUsers.length) {
        const user = userList.find(
          (x) => x.id === selectedUsers[currnetUserIndex]
        );
        let textContainerWidth =
          getTextContainerWidth(user?.fullName ?? "") + (hasGap ? 8 : 0);
        if (textContainerWidth > currentLineRemainingWidth) {
          currnetUserIndex--;
          break;
        } else {
          currentLineRemainingWidth -= textContainerWidth;
          hasGap = true;
          currnetUserIndex++;
        }
      }
    }
  }

  return currnetUserIndex;
};

export const getDateFormattedColumnsForIEATestResultDynamicColumns = (
  columns: any[]
) => {
  return columns.map((column: any) => {
    if (
      column.dataField === "EarlyStart" ||
      column.dataField === "EarlyFinish"
    ) {
      column.dataType = "date";
      column.format = norwegianDateFormatForDevextreme;
    }
    return column;
  });
};
