import IEAActivityFilterDialogHeader from "./IEAActivityFilterDialogHeader";
import FilterExpressionPickerDropdown from "./FilterExpressionPickerDropdown";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { useCallback, useEffect, useState } from "react";
import { ActivityFilter } from "interfaces/common/ActivityFilter";
import { safranActivityFilterList } from "shared/constants/SafranActivityFilter";
import { commonActivityFilterList } from "shared/constants/CommonActivityFilter";
import { analyticsActivityFilterList } from "shared/constants/AnalyticsActivityFilter";
import { msProjectActivityFilterList } from "shared/constants/MsProjectActivityFilter";
import { primaveraActivityFilterList } from "shared/constants/primaveraActivityFilter";
import "./styles/IEAActivityFilter.css";

export interface IEAActivityFilterProps {
  senderHostSystem: HostSystem | undefined;
  isEdit: boolean;
  isInvalid?: boolean;
  filterValue: string;
  onCurrentFilterValueChange: (value: string) => void;
  isForNewIea?: boolean;
}

export default function IEAActivityFilter(props: IEAActivityFilterProps) {
  const { isEdit, filterValue, senderHostSystem, isInvalid } = props;
  const [currentFilterValue, setCurrentFilterValue] =
    useState<string>(filterValue);
  const [filterExpressionData, setFilterExpressionData] = useState<
    ActivityFilter[]
  >([]);

  useEffect(() => {
    let filterExpressionDataSource: ActivityFilter[] = [];
    if (senderHostSystem === HostSystem.Safran) {
      filterExpressionDataSource = safranActivityFilterList.concat(
        commonActivityFilterList
      );
    } else if (senderHostSystem === HostSystem.IlapAnalytics) {
      filterExpressionDataSource = analyticsActivityFilterList.concat(
        commonActivityFilterList
      );
    } else if (senderHostSystem === HostSystem.MicrosoftProject) {
      filterExpressionDataSource = msProjectActivityFilterList.concat(
        commonActivityFilterList
      );
    } else if (senderHostSystem === HostSystem.PrimaveraP6) {
      filterExpressionDataSource = primaveraActivityFilterList.concat(
        commonActivityFilterList
      );
    } else if (senderHostSystem === HostSystem.FileStorage) {
      filterExpressionDataSource = commonActivityFilterList;
    }

    setFilterExpressionData(filterExpressionDataSource);
  }, [senderHostSystem]);

  useEffect(() => {
    props.onCurrentFilterValueChange(currentFilterValue);
  }, [currentFilterValue]);

  const handleActivityFilterChangeOnInput = useCallback((evt: any) => {
    setCurrentFilterValue(evt.event?.target.value);
  }, []);

  const handleActivityFilterChangeOnSelection = (newFilter: string) => {
    setCurrentFilterValue((prevFilterValue) => {
      if (prevFilterValue) {
        return `${prevFilterValue} && ${newFilter}`;
      } else {
        return newFilter;
      }
    });
  };

  return (
    <div>
      <IEAActivityFilterDialogHeader isEdit={isEdit} />
      {isEdit && (
        <div className="font-inter leading-4 text-10px my-4 flex gap-2 flex-row-reverse">
          <FilterExpressionPickerDropdown
            dataSource={filterExpressionData}
            onActivityFilterSelection={handleActivityFilterChangeOnSelection}
          />
        </div>
      )}

      <PromineoMultilineTextEdit
        readOnly={!isEdit}
        height={props.isForNewIea ? 275 : 400}
        placeholder={isEdit ? "Type or paste here..." : ""}
        className={`whitespace-pre-line text-10px font-inter ${
          isInvalid ? "activity-filter-error-border" : ""
        }`}
        value={currentFilterValue ?? undefined}
        onChange={handleActivityFilterChangeOnInput}
      ></PromineoMultilineTextEdit>
      {isInvalid && (
        <div className="text-xs leading-[18px] text-right py-1">
          Cannot submit while Activity filter is empty. Add a filter or turn off
          the filter to submit.
        </div>
      )}
    </div>
  );
}
