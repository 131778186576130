export enum StatusCircleColors {
  Red,
  Orange,
  Green,
  Blue,
  Purple,
  Grey,
}

export interface Props {
  color: StatusCircleColors;
  size?: number;
  iconComponent?: JSX.Element;
}

export default function StatusCircle({ color, size, iconComponent }: Props) {
  const getBackgroundClass = () => {
    if (color === StatusCircleColors.Red) {
      return "bg-red";
    } else if (color === StatusCircleColors.Orange) {
      return "bg-orange";
    } else if (color === StatusCircleColors.Green) {
      return "bg-ilapGreen";
    } else if (color === StatusCircleColors.Blue) {
      return "bg-ilapBlue";
    } else if (color === StatusCircleColors.Purple) {
      return "bg-purple";
    } else {
      return "bg-gray";
    }
  };

  let sizeClasses = "w-3 h-3";
  if (size) {
    sizeClasses = `w-[${size}px] h-[${size}px]`;
  }

  return (
    <div
      className={`${sizeClasses} rounded-full flex items-center justify-center ${getBackgroundClass()}`}
    >
      {iconComponent ? iconComponent : null}
    </div>
  );
}
