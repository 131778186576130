import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import { InactiveIeaEditorTabs } from "./InactiveIEAEditor";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import ContinueWithoutTestButton from "./ContinueWithoutTestButton";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  currentTab: InactiveIeaEditorTabs;
  isPartnerSubmitButtonDisabled: boolean;
  isCurrentUserSender: boolean;
  isCurrentUserPartner: boolean;
  isCurrentUserReceiver: boolean;
  onOwnerSign: () => void;
  onPartnerSign: () => void;
  onGoToTab: (tabIndex: number) => void;
  onPartnerInfoSubmit: () => void;
}

export default function InactiveIEAFooterForCurrentUserIsPartner(props: Props) {
  const { exchangeAgreement, currentTab, isPartnerSubmitButtonDisabled } =
    props;

  if (
    exchangeAgreement.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.SignOff
  ) {
    return (
      <PromineoButton
        variant={PromineoButtonType.Primary}
        text="Sign IEA on behalf of partner"
        onClick={props.onPartnerSign}
      />
    );
  }

  if (
    (props.exchangeAgreement.actionRequiredIfInactive !== undefined &&
      currentTab === InactiveIeaEditorTabs.IeaInfo) ||
    currentTab === InactiveIeaEditorTabs.HostSystemParameters
  ) {
    return (
      <PromineoButton
        variant={PromineoButtonType.Primary}
        text="Next"
        disabled={isPartnerSubmitButtonDisabled}
        onClick={() => props.onGoToTab(currentTab + 1)}
      />
    );
  }

  if (
    exchangeAgreement.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.PerformUploadTest
  ) {
    if (
      exchangeAgreement.latestDataTransferTestEvent === undefined ||
      (exchangeAgreement.latestDataTransferTestEvent.uploadStatus !==
        DataTransferStatus.Success &&
        exchangeAgreement.latestDataTransferTestEvent.uploadStatus !==
          DataTransferStatus.Fail)
    ) {
      return (
        <ContinueWithoutTestButton
          isUpload={true}
          exchangeAgreement={exchangeAgreement}
          isCurrentUserPartner={true}
          isCurrentUserOwner={false}
        />
      );
    }
  }

  if (props.isCurrentUserReceiver) {
    if (
      exchangeAgreement.latestDataTransferTestEvent !== undefined &&
      exchangeAgreement.latestDataTransferTestEvent.downloadStatus !==
        DataTransferStatus.Success &&
      exchangeAgreement.latestDataTransferTestEvent.downloadStatus !==
        DataTransferStatus.Skipped &&
      exchangeAgreement.latestDataTransferTestEvent.uploadStatus ===
        DataTransferStatus.Success
    ) {
      return (
        <ContinueWithoutTestButton
          exchangeAgreement={exchangeAgreement}
          isUpload={false}
          isCurrentUserPartner={true}
          isCurrentUserOwner={false}
        />
      );
    }
  }

  return null;
}
