import {
  EditExchangeAgreementConfigUpdateAcceptanceStatusAsync,
  createExchangeAgreementForOwnerAsync,
  deleteInactiveExchangeAgreementAsync,
  duplicateExistingExchangeAgreementAsync,
  generateExchangeAgreementEventAsync,
  getActiveExchangeAgreementsAsync,
  getActiveSimplifiedExchangeAgreementsAsync,
  getArchiveExchangeAgreementsAsync,
  getConfigChangesPreviewAsync,
  getExchangeAgreementDetailsAsync,
  getNewExchangeAgreementsAsync,
  pauseExchangeAgreementAsync,
  republishExchangeAgreementAsync,
  resumeExchangeAgreementAsync,
  signExchangeAgreementByOwnerAsync,
  signExchangeAgreementByPartnerAsync,
  updateExchangeAgreementAsync,
  createTestEventAsync,
  getUploadTestResultAsync as getTestResultAsync,
  setUploadTestEventStatusAsSkipped,
  getExchangeAgreementDetailedMappingsAsync,
  updateExchangeAgreementForPartnerAsync,
  setDownloadTestEventStatusAsSkipped,
} from "apis/ExchangeAgreementApi";
import ExchangeAgreementConfigUpdateAcceptanceRequest from "interfaces/request/ExchangeAgreementConfigUpdateAcceptanceRequest";
import ExchangeAgreementDuplicateWriteRequest from "interfaces/request/ExchangeAgreementDuplicateWriteRequest";
import ExchangeAgreementUpdateRequest from "interfaces/request/ExchangeAgreementUpdateRequest";
import OwnerExchangeAgreementWriteRequest from "interfaces/request/OwnerExchangeAgreementWriteRequest";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { createAppAsyncThunk } from "store/ThunkUtility";
import ExchangeAgreementFieldMappingDetailResponse from "interfaces/response/ExchangeAgreementFieldMappingDetailResponse";

export const loadActiveExchangeAgreementsAsync = createAppAsyncThunk(
  "ExchangeAgreement/loadActiveExchangeAgreementsAsync",
  async () => {
    return await getActiveExchangeAgreementsAsync();
  }
);

export const loadNewExchangeAgreementsAsync = createAppAsyncThunk(
  "ExchangeAgreement/loadNewExchangeAgreementsAsync",
  async () => {
    return await getNewExchangeAgreementsAsync();
  }
);

export const loadArchiveExchangeAgreementsAsync = createAppAsyncThunk(
  "ExchangeAgreement/loadArchiveExchangeAgreementsAsync",
  async () => {
    return await getArchiveExchangeAgreementsAsync();
  }
);

export const loadActiveSimplifiedExchangeAgreementsAsync = createAppAsyncThunk(
  "ExchangeAgreement/loadActiveSimplifiedExchangeAgreementsAsync",
  async () => {
    return await getActiveSimplifiedExchangeAgreementsAsync();
  }
);

export const loadExchangeAgreementDetailsAsync = createAppAsyncThunk(
  "ExchangeAgreement/loadExchangeAgreementDetailsAsync",
  async (exchangeAgreementId: number) => {
    return await getExchangeAgreementDetailsAsync(exchangeAgreementId);
  }
);

export const createNewExchangeAgreementForOwnerAsync = createAppAsyncThunk(
  "ExchangeAgreement/createExchangeAgreementForOwnerAsync",
  async (exchangeAgreementRequest: OwnerExchangeAgreementWriteRequest) => {
    return await createExchangeAgreementForOwnerAsync(exchangeAgreementRequest);
  }
);

export const duplicateExchangeAgreementAsync = createAppAsyncThunk<
  ExchangeAgreementDetailedResponse,
  {
    exchangeAgreementToDuplicateId: number;
    exchangeAgreementDuplicationRequest: ExchangeAgreementDuplicateWriteRequest;
  }
>("ExchangeAgreement/duplicateExchangeAgreementAsync", async (request) => {
  return await duplicateExistingExchangeAgreementAsync(
    request.exchangeAgreementToDuplicateId,
    request.exchangeAgreementDuplicationRequest
  );
});

export const updateExistingExchangeAgreementForPartnerAsync =
  createAppAsyncThunk<
    ExchangeAgreementDetailedResponse,
    {
      exchangeAgreementId: number;
      updateRequest: ExchangeAgreementUpdateRequest;
    }
  >(
    "ExchangeAgreement/updateExchangeAgreementForPartnerAsync",
    async (request) => {
      return await updateExchangeAgreementForPartnerAsync(
        request.exchangeAgreementId,
        request.updateRequest
      );
    }
  );

export const updateExistingExchangeAgreementAsync = createAppAsyncThunk<
  ExchangeAgreementDetailedResponse,
  { exchangeAgreementId: number; updateRequest: ExchangeAgreementUpdateRequest }
>("ExchangeAgreement/updateExistingExchangeAgreement", async (request) => {
  return await updateExchangeAgreementAsync(
    request.exchangeAgreementId,
    request.updateRequest
  );
});

export const signExchangeAgreementOnBehalfOfPartnerAsync = createAppAsyncThunk(
  "ExchangeAgreement/signExchangeAgreementByPartnerAsync",
  async (exchangeAgreementId: number) => {
    return await signExchangeAgreementByPartnerAsync(exchangeAgreementId);
  }
);

export const signExchangeAgreementOnBehalfOfOwnerAsync = createAppAsyncThunk(
  "ExchangeAgreement/signExchangeAgreementByOwnerAsync",
  async (exchangeAgreementId: number) => {
    return await signExchangeAgreementByOwnerAsync(exchangeAgreementId);
  }
);

export const pauseActiveExchangeAgreementAsync = createAppAsyncThunk(
  "ExchangeAgreement/pauseExchangeAgreementAsync",
  async (exchangeAgreementId: number) => {
    return await pauseExchangeAgreementAsync(exchangeAgreementId);
  }
);

export const resumeActiveExchangeAgreementAsync = createAppAsyncThunk(
  "ExchangeAgreement/resumeExchangeAgreementAsync",
  async (exchangeAgreementId: number) => {
    return await resumeExchangeAgreementAsync(exchangeAgreementId);
  }
);

export const removeInactiveExchangeAgreementAsync = createAppAsyncThunk(
  "ExchangeAgreement/deleteInactiveExchangeAgreementAsync",
  async (exchangeAgreementId: number) => {
    return await deleteInactiveExchangeAgreementAsync(exchangeAgreementId);
  }
);

export const generateExchangeAgreementDataTransferEventAsync =
  createAppAsyncThunk(
    "ExchangeAgreement/generateExchangeAgreementEventAsync",
    async (exchangeAgreementId: number) => {
      return await generateExchangeAgreementEventAsync(exchangeAgreementId);
    }
  );

export const loadConfigChangesPreviewAsync = createAppAsyncThunk(
  "ExchangeAgreement/loadConfigChangesPreview",
  async (exchangeAgreementId: number) => {
    return await getConfigChangesPreviewAsync(exchangeAgreementId);
  }
);

export const republishExchangeAgreementForConfigChangeAsync =
  createAppAsyncThunk(
    "ExchangeAgreement/republishExchangeAgreementForConfigChange",
    async (exchangeAgreementId: number) => {
      return await republishExchangeAgreementAsync(exchangeAgreementId);
    }
  );

export const ModifyExchangeAgreementConfigUpdateAcceptanceStatusAsync =
  createAppAsyncThunk<
    void,
    {
      exchangeAgreementId: number;
      updateRequest: ExchangeAgreementConfigUpdateAcceptanceRequest;
    }
  >(
    "ExchangeAgreement/ModifyExchangeAgreementConfigUpdateAcceptanceStatusAsync",
    async (request) => {
      await EditExchangeAgreementConfigUpdateAcceptanceStatusAsync(
        request.exchangeAgreementId,
        request.updateRequest
      );
    }
  );

export const loadExchangeAgreementDetailedMappingsAsync = createAppAsyncThunk<
  ExchangeAgreementFieldMappingDetailResponse,
  number
>(
  "ExchangeAgreement/LoadExchangeAgreementDetailedMappingsAsync",
  async (exchangeAgreementId: number) => {
    return await getExchangeAgreementDetailedMappingsAsync(exchangeAgreementId);
  }
);

export const addTestEventAsync = createAppAsyncThunk(
  "ExchangeAgreement/addTestEvent",
  async (exchangeAgreementId: number) => {
    return await createTestEventAsync(exchangeAgreementId);
  }
);

export const updateUploadTestEventStatusAsSkippedAsync = createAppAsyncThunk(
  "ExchangeAgreement/updateUploadTestEventStatusAsSkipped",
  async (request: {
    exchangeAgreementId: number;
    isCurrentUserPartner: boolean; // required in slice - in case of partner skipping the test
  }) => {
    return await setUploadTestEventStatusAsSkipped(request.exchangeAgreementId);
  }
);

export const updateDownloadTestEventStatusAsSkippedAsync = createAppAsyncThunk(
  "ExchangeAgreement/updateDownloadTestEventStatusAsSkipped",
  async (request: {
    exchangeAgreementId: number;
    isCurrentUserPartner: boolean; // required in slice - in case of partner skipping the test
    isCurrentUserOwner: boolean; // required in slice - in case of partner skipping the test
  }) => {
    return await setDownloadTestEventStatusAsSkipped(request.exchangeAgreementId);
  }
);

export const loadTestResultAsync = createAppAsyncThunk(
  "ExchangeAgreement/loadTestResult",
  async (testEventId: number) => {
    return await getTestResultAsync(testEventId);
  }
);
