import BorderComponent from "components/common/BorderComponent";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { FormulaTestRequest } from "interfaces/request/FormulaTestRequest";
import { FormulaTestResponse } from "interfaces/response/config-test/FormulaTestResponse";
import { useCallback, useState } from "react";
import { PromineoDataType } from "shared/enums/PromineoDataType";
import { testFormulaAsync } from "store/actions/FormulaTestActions";
import { useAppDispatch } from "store/hooks";
import FormulaVariableEditor, {
  FormulaVariable,
} from "./FormulaVariableEditor";
import "./styles/FormulaTester.css";

interface Props {
  onTestPerformed: (response: FormulaTestResponse) => void;
  onAppendAndClose: (formula: string) => void;
}

interface ExampleFormula {
  name: string;
  value: string;
}

const exampleFormulas: ExampleFormula[] = [
  {
    name: 'Test if value is the string "hello" (case insensitive)',
    value: '[VALUE].ToUpper() = "HELLO"',
  },
  {
    name: "Convert string (yyyy-MM-dd) to DateTime type",
    value: 'DateTime.Parse("2022-09-01")',
  },
  {
    name: "Round a number to 0 decimals",
    value: "Math.Round([VALUE], 0)",
  },
  {
    name: "Take 2 rightmost character of a string value",
    value: "Sys.Right([VALUE],2)",
  },
  {
    name: "Get today's ISO week number",
    value: "CalendarUtility.GetIsoWeek(DateTime.Today)",
  },
  {
    name: 'Get type reference of "General"',
    value: "typeof(General)",
  },
  {
    name: 'Return "X" if value is greater than 5, otherwise "Y"',
    value: 'General.Iif([VALUE]>5,"X","Y")',
  },
  {
    name: "Get average of 3 numbers",
    value: "General.Avg(new double[]{10, 20, 50})",
  },
  {
    name: 'Return empty string ("") if the value is Null (blank)',
    value: 'General.IsNull([VALUE],"")',
  },
  {
    name: "Get the login name of a user",
    value: 'General.GetEnvironmentVariable("USERNAME")',
  },
];

const wrapperClass: string = "formula-tester";

export default function FormulaTester(props: Props) {
  const dispatch = useAppDispatch();

  const [formula, setFormula] = useState<string>("");
  const [selectedExampleFormula, setSelectedExampleFormula] =
    useState<string>();
  const [variable, setVariable] = useState<FormulaVariable>({
    name: "[VALUE]",
    value: "ExampleString",
    type: PromineoDataType.String,
  });

  const [testResponse, setTestResponse] = useState<FormulaTestResponse>();

  // used to get selected text from Formula Editor
  const formulaEditorId = "formulaEditor";
  const [isEditorFocused, setIsEditorFocused] = useState<boolean>(false);

  const handleEditorFocusIn = () => setIsEditorFocused(true);

  const handleFormulaChange = (e: any) => {
    setFormula(e.event?.currentTarget?.value);
  };

  const handleExampleDropdownValueChange = (value: string) => {
    setFormula((prev) => `${prev}${value}`);

    // clear the formula picker dropdown
    setSelectedExampleFormula(undefined);
  };

  const getSelectedFormulaSegment = useCallback(() => {
    if (!isEditorFocused) {
      return undefined;
    }

    // make sure the selected text is not re-used unless the user selects again
    setIsEditorFocused(false);

    const parentElement = document.getElementById(formulaEditorId);
    if (parentElement) {
      const textAreaElement = parentElement.querySelector("textarea");
      if (textAreaElement) {
        const { selectionStart, selectionEnd, value } = textAreaElement;
        const selectedText = value.substring(selectionStart, selectionEnd);
        return selectedText?.trim();
      }
    }

    return undefined;
  }, [isEditorFocused]);

  const handleTestClick = useCallback(() => {
    displayLoadingPanel();

    const selectedText = getSelectedFormulaSegment();

    const formulaToTest = selectedText || formula?.trim();

    if (!formulaToTest) {
      const response: FormulaTestResponse = {
        isSuccess: false,
        message: "Expression cannot be null or empty.",
        formula: formulaToTest,
        result: "",
      };

      setTestResponse(response);
      props.onTestPerformed(response);

      hideLoadingPanel();
      return;
    }

    const request: FormulaTestRequest = {
      formula: formulaToTest,
      variableValue: variable.value,
      variableType: variable.type,
    };

    dispatch(testFormulaAsync(request))
      .unwrap()
      .then((response: FormulaTestResponse) => {
        setTestResponse(response);
        props.onTestPerformed(response);
      })
      .finally(hideLoadingPanel);
  }, [
    dispatch,
    props,
    formula,
    variable.value,
    variable.type,
    getSelectedFormulaSegment,
  ]);

  const handleVariableValueChange = (value: any) => {
    setVariable((prev) => {
      return {
        ...prev,
        value: value,
      };
    });
  };

  const handleVariableTypeChange = (type: PromineoDataType) => {
    let defaultValue: any = "ExampleString";

    if (type === PromineoDataType.Double) {
      defaultValue = 0;
    } else if (type === PromineoDataType.Boolean) {
      defaultValue = true;
    } else if (type === PromineoDataType.DateTime) {
      defaultValue = new Date();
    }

    setVariable((prev) => {
      return {
        ...prev,
        type: type,
        value: defaultValue,
      };
    });
  };

  const handleAppendAndClose = () => {
    props.onAppendAndClose(formula);
  };

  const exampleItemRender = useCallback((item: ExampleFormula) => {
    return (
      <div className="my-0.5 text-sm">
        <div>{item.name}</div>
        <div className="text-ilapBlue">{item.value}</div>
        <BorderComponent customClass="mt-1" />
      </div>
    );
  }, []);

  return (
    <div className={wrapperClass}>
      <div className="flex justify-between items-center mb-4 font-inter">
        <div>Example formula picker:</div>
        <div>
          <PromineoSelectBox
            height={30}
            width={360}
            placeholder="Pick an example formula..."
            dataSource={exampleFormulas}
            displayExpr={"name"}
            valueExpr={"value"}
            value={selectedExampleFormula}
            onValueChange={handleExampleDropdownValueChange}
            itemRender={exampleItemRender}
          />
        </div>
      </div>
      <div className="mb-2">
        <PromineoMultilineTextEdit
          id={formulaEditorId}
          height={160}
          value={formula}
          onChange={handleFormulaChange}
          spellcheck={false}
          onFocusIn={handleEditorFocusIn}
        />
      </div>
      <div className="font-bold">Result</div>
      <div className="font-mono font-semibold mt-2">
        <PromineoMultilineTextEdit
          readOnly
          className="text-red"
          height={100}
          value={
            testResponse?.isSuccess
              ? testResponse?.result
              : testResponse?.message
          }
          spellcheck={false}
        />
      </div>
      <div className="flex justify-end mt-2 gap-2">
        <PromineoButton
          text="Append and close"
          variant={PromineoButtonType.Success}
          onClick={handleAppendAndClose}
        />
        <PromineoButton text="Test" width={100} onClick={handleTestClick} />
      </div>
      <div className="font-bold mt-2">Variable</div>
      <div className="mt-2 items-center">
        <FormulaVariableEditor
          variable={variable}
          onValueChange={handleVariableValueChange}
          onTypeChange={handleVariableTypeChange}
        />
      </div>
    </div>
  );
}
