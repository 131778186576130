import PromineoDropdownButton from "components/common/dropdown-button/PromineoDropdownButton";
import _uniqueId from "lodash/uniqueId";
import { useCallback, useMemo, useRef, useState } from "react";
import "./styles/GridOptionButton.css";

interface Props {
  onResetLayout: () => void;
}

export default function GridOptionButton(props: Props) {
  const dropdownItems = useRef([
    {
      text: "Reset Layout",
      id: 0,
      onClick: props.onResetLayout,
    },
  ]);

  const handleItemClick = useCallback((item: any) => {
    item.itemData.onClick();
  }, []);

  const [dropdownControlId] = useState(`grid-option-dropdown-${_uniqueId()}`);

  const dropdownOptions = useMemo<any>(() => {
    return {
      width: 120,
      position: {
        my: "right top",
        at: "right bottom",
        of: `#${dropdownControlId}`,
        offset: { x: 0, y: 3 },
      },
    };
  }, [dropdownControlId]);

  return (
    <PromineoDropdownButton
      className="promineo-grid-option-button"
      items={dropdownItems.current}
      displayExpr={"text"}
      icon="icon dx-icon-detailslayout"
      onItemClick={handleItemClick}
      id={dropdownControlId}
      dropDownOptions={dropdownOptions}
    />
  );
}
