import { IEATesterTabs } from "./IEATestResultViewer";

interface Props {
  activityCount?: number;
  scheduleCount?: number;
  resourceAssignmentCount?: number;
  selectedTabIndex: IEATesterTabs;
  tabHeaderData: {
    tabTemplate: string;
    template: string;
    text: string;
  };
}

export default function TransferTestTabTitle(props: Props) {
  const tabTemplateText = props.tabHeaderData.tabTemplate;
  const indexPart = tabTemplateText.split("[")[1].split("]")[0];
  const tabIndex = parseInt(indexPart, 10);
  const isActive = props.selectedTabIndex === tabIndex;

  let itemCount = undefined;

  if (tabIndex === IEATesterTabs.Activities) {
    itemCount = props.activityCount;
  } else if (tabIndex === IEATesterTabs.Schedules) {
    itemCount = props.scheduleCount;
  } else if (tabIndex === IEATesterTabs.ResourceAssignments) {
    itemCount = props.resourceAssignmentCount;
  }

  return (
    <div className="space-x-2 flex justify-center items-center">
      <span className="text-sm font-normal">{props.tabHeaderData.text}</span>
      {itemCount ? (
        <div
          className={`py-[1px] px-2 border flext items-center justify-center text-center text-white text-xs font-normal rounded-full ${
            isActive ? "bg-ilapBlue" : "bg-gray"
          }`}
        >
          <span>{itemCount}</span>
        </div>
      ) : null}
    </div>
  );
}
