import React from "react";

export default function SearchPlaceholderIcon() {
  return (
    <svg
      width="223"
      height="136"
      viewBox="0 0 223 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.420391"
        y="32.0141"
        width="179.159"
        height="91.9749"
        rx="6.30587"
        fill="white"
      />
      <rect
        x="0.420391"
        y="32.0141"
        width="179.159"
        height="91.9749"
        rx="6.30587"
        stroke="#EEEEEE"
        stroke-width="0.840782"
      />
      <rect
        x="6.72656"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="6.72656"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="50.4648"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="94.2031"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="137.943"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="112.42"
        y="1.01414"
        width="105.159"
        height="100.014"
        rx="6.30587"
        fill="white"
      />
      <rect
        x="112.42"
        y="1.01414"
        width="105.159"
        height="100.014"
        rx="6.30587"
        stroke="#EEEEEE"
        stroke-width="0.840782"
      />
      <rect
        x="118.727"
        y="9"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EEEEEE"
      />
      <rect
        x="118.727"
        y="24.4102"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="39.8164"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="55.2266"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="70.6328"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="86.0391"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <g clip-path="url(#clip0_21118_49039)">
        <path
          d="M197.777 107.039H195.495L194.686 106.259C197.517 102.966 199.222 98.6906 199.222 94.0395C199.222 83.6684 190.815 75.2617 180.444 75.2617C170.073 75.2617 161.666 83.6684 161.666 94.0395C161.666 104.411 170.073 112.817 180.444 112.817C185.095 112.817 189.37 111.113 192.664 108.282L193.444 109.091V111.373L207.888 125.788L212.193 121.484L197.777 107.039ZM180.444 107.039C173.25 107.039 167.444 101.233 167.444 94.0395C167.444 86.8462 173.25 81.0395 180.444 81.0395C187.637 81.0395 193.444 86.8462 193.444 94.0395C193.444 101.233 187.637 107.039 180.444 107.039Z"
          fill="#727272"
        />
      </g>
      <defs>
        <clipPath id="clip0_21118_49039">
          <rect
            width="69.3333"
            height="69.3333"
            fill="white"
            transform="translate(153 66.5938)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
