import ScrollView from "devextreme-react/scroll-view";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { ReactNode } from "react";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import "./style/IEAScrollView.css";

interface Props {
  childElement: ReactNode;
  divIdsToExclude: string[];
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function IEAScrollView(props: Props) {
  const { childElement } = props;

  const viewHeight = useRemainingContentLayoutHeight({
    excludedContainerIds: props.divIdsToExclude,
    marginHeight:
      props.exchangeAgreement.actionRequiredIfInactive &&
      props.exchangeAgreement.actionRequiredIfInactive ===
        NewExchangeAgreementActionRequired.PerformUploadTest
        ? 35
        : 44,
  });

  return (
    <ScrollView
      className="iea-scrollview"
      showScrollbar="always"
      height={viewHeight}
    >
      {childElement}
    </ScrollView>
  );
}
