import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import ContinueWithoutTestButton from "./ContinueWithoutTestButton";
import { InactiveIeaEditorTabs } from "./InactiveIEAEditor";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  currentTab: InactiveIeaEditorTabs;
  isCurrentUserSender: boolean;
  isCurrentReceiver: boolean;
  onOwnerSign: () => void;
  onGoToTab: (tabIndex: number) => void;
  onSubmit: () => void;
}

export default function InactiveIEAFooterForCurrentUserIsOwner(props: Props) {
  const { exchangeAgreement, currentTab, isCurrentUserSender } = props;

  if (
    exchangeAgreement.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.SignOff
  ) {
    return (
      <PromineoButton
        variant={PromineoButtonType.Primary}
        text="Sign IEA on behalf of Owner"
        onClick={props.onOwnerSign}
      />
    );
  }

  if (
    (exchangeAgreement.actionRequiredIfInactive !== undefined &&
      currentTab === InactiveIeaEditorTabs.IeaInfo) ||
    (currentTab === InactiveIeaEditorTabs.HostSystemParameters &&
      exchangeAgreement.actionRequiredIfInactive !==
        NewExchangeAgreementActionRequired.WaitingForPartner)
  ) {
    return (
      <PromineoButton
        variant={PromineoButtonType.Primary}
        text="Next"
        onClick={() => props.onGoToTab(currentTab + 1)}
      />
    );
  }

  if (
    currentTab === InactiveIeaEditorTabs.HostSystemParameters &&
    exchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForPartner
  ) {
    return (
      <PromineoButton
        variant={PromineoButtonType.Primary}
        text="Submit"
        onClick={props.onSubmit}
      />
    );
  }

  if (isCurrentUserSender) {
    if (
      (exchangeAgreement.uploadTestRequired &&
        exchangeAgreement.latestDataTransferTestEvent === undefined) ||
      (exchangeAgreement.latestDataTransferTestEvent &&
        exchangeAgreement.latestDataTransferTestEvent.uploadStatus !==
          DataTransferStatus.Success &&
        exchangeAgreement.latestDataTransferTestEvent.uploadStatus !==
          DataTransferStatus.Skipped)
    ) {
      return (
        <ContinueWithoutTestButton
          exchangeAgreement={exchangeAgreement}
          isUpload={true}
          isCurrentUserPartner={false}
          isCurrentUserOwner={true}
        />
      );
    }
  }

  if (props.isCurrentReceiver) {
    if (
      exchangeAgreement.latestDataTransferTestEvent !== undefined &&
      exchangeAgreement.latestDataTransferTestEvent.downloadStatus !==
        DataTransferStatus.Success &&
      exchangeAgreement.latestDataTransferTestEvent.downloadStatus !==
        DataTransferStatus.Skipped &&
      exchangeAgreement.latestDataTransferTestEvent.uploadStatus ===
        DataTransferStatus.Success
    ) {
      return (
        <ContinueWithoutTestButton
          exchangeAgreement={exchangeAgreement}
          isUpload={false}
          isCurrentUserPartner={false}
          isCurrentUserOwner={true}
        />
      );
    }
  }

  if (
    exchangeAgreement.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.Complete
  ) {
    return (
      <PromineoButton
        variant={PromineoButtonType.Primary}
        text="Submit"
        onClick={props.onSubmit}
      />
    );
  }

  return null;
}
