import { useCallback, useRef } from "react";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import ConnectorScheduleResponse from "interfaces/response/ConnectorScheduleResponse";
import ConfigCodeSetTemplate from "../../config-common/ConfigCodeSetTemplate";
import "components/common/grid/styles/PromineoUIGrid.css";
import { stringAndNumberCombinationSortComparator } from "shared/utilities/CommonUtility";
import { getFormattedDate } from "shared/utilities/DateTimeUtility";

interface Props {
  config: ConfigDetailResponse;
  height: number;
}

export default function ConfigScheduleViewer(props: Props) {


  const CodeSetTemplate = useCallback(
    (data: { data: { data: ConnectorScheduleResponse } }) => {
      return (
        <ConfigCodeSetTemplate
          userFieldSetId={data.data.data.userFieldSetId}
          connectorId={props.config.connectorId}
        />
      );
    },
    [props.config.connectorId]
  );

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  return (
    <div>
      <PromineoViewGrid
        dataSource={props.config.schedules}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        height={props.height}
        gridIdentifier="configScheduleViewer"
      >
        <Scrolling mode={"virtual"} />
        <Column
          caption={"ID"}
          dataField="scheduleId"
          alignment="left"
          width={150}
          sortingMethod={stringAndNumberCombinationSortComparator}
          sortOrder={"asc"}
        />
        <Column caption={"Title"} dataField="title" alignment="left" />
        <Column
          caption={"Start Date"}
          dataField="startDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getFormattedDate(data.value) : "-"
          }
          width={130}
        />
        <Column
          caption={"Finish Date"}
          dataField="finishDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getFormattedDate(data.value) : "-"
          }
          width={130}
        />
        <Column
          caption={"Last Updated"}
          dataField="lastUpdatedDate"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getFormattedDate(data.value) : "-"
          }
          width={130}
        />
        <Column
          caption={"Code set ID"}
          dataField="userFieldSetId"
          alignment="left"
          width={130}
          cellComponent={CodeSetTemplate}
        />
      </PromineoViewGrid>
    </div>
  );
}
