export enum NewExchangeAgreementActionRequired {
  None,
  Complete,
  SignOff,
  WaitingForPartner,
  WaitingForOwner,
  WaitingForPartnerSign,
  WaitingForOwnerSign,
  PerformUploadTest,
  PerformDownloadTest,
  WaitingForPartnerToTest,
  WaitingForOwnerToTest,
}
