import { unwrapResult } from "@reduxjs/toolkit";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import CrossIcon from "components/icons/CrossIcon";
import PromineoModal from "components/modal/PromineoModal";
import { Column, Scrolling } from "devextreme-react/data-grid";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { useEffect, useState } from "react";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";
import { loadConnectorHostFieldAsync } from "store/actions/ConnectorActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  userFieldSetId?: number;
  connectorId?: number;
  onHiding: () => void;
}

export default function ScheduleCodeSetDialog(props: Props) {
  const dispatch = useAppDispatch();
  const { connectorId, userFieldSetId } = props;

  const [hostFieldDataSource, setHostFieldDataSource] = useState<HostFieldResponse[]>([]);

  useEffect(() => {
    if (connectorId && userFieldSetId) {
      displayLoadingPanel();
      dispatch(
        loadConnectorHostFieldAsync({
          connectorId: connectorId,
          includeAll: false,
          userFieldSetId: userFieldSetId,
          includeAllWithNullUserFieldId: false,
        })
      )
      .then(unwrapResult)
      .then((res: HostFieldResponse[])=>{
        setHostFieldDataSource(res);
      })
      .finally(hideLoadingPanel);
    }
  }, []);

  return (
    <>
      {hostFieldDataSource !== null && (
        <div>
          <PromineoModal
            displayDefaultActions={false}
            isVisible={true}
            width="720"
            height="auto"
            onHiding={props.onHiding}
          >
            <div className="mb-4 flex justify-between">
              <div>{`Code set ${userFieldSetId}`}</div>
              <div onClick={props.onHiding} className="cursor-pointer">
                <CrossIcon />
              </div>
            </div>
            <PromineoViewGrid
              dataSource={hostFieldDataSource}
              height={568}
              className="promineo-grid-in-modal"
              hoverStateEnabled={false}
              gridIdentifier="connectorHostFieldsGrid"
              toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
            >
              <Scrolling mode={"virtual"} rowRenderingMode="virtual" />
              <Column caption={"Host field"} dataField="displayText" />
              <Column caption={"Alias"} dataField="alias" />
              <Column
                caption={"Data type"}
                dataField="dataTypeText"
                alignment={"left"}
              />
            </PromineoViewGrid>
            <div className="border-t border-lightGray mt-6"></div>
          </PromineoModal>
        </div>
      )}
    </>
  );
}
