import BorderComponent from "components/common/BorderComponent";
import { MethodDefinition } from "interfaces/response/config-test/MethodDefinition";
import MethodDefinitionViewer from "./MethodDefinitionViewer";

interface Props {
  name: string;
  methodDefinitions: MethodDefinition[];
}

export default function MethodDefinitionsViewer(props: Props){
  const { name, methodDefinitions } = props;
  return (
    <div>
      <div className="font-bold">{name}</div>
      <BorderComponent customClass="my-1" />
      {methodDefinitions.map((methodDefinition, index) => (
        <div key={index}>
          <MethodDefinitionViewer methodDefinition={methodDefinition} />
        </div>
      ))}
    </div>
  )
}