import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { useCallback, useEffect, useRef } from "react";
import { loadReceivedTemplatesAsync } from "store/actions/TemplateActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";
import "components/common/grid/styles/PromineoUIGrid.css";
import LabelHolder from "components/common/LabelHolder";
import { SupportedLabelEntity } from "shared/enums/feature/SupportedLabelEntity";
import DetailedTemplateResponse from "interfaces/response/DetailedTemplateResponse";
import { useNavigate } from "react-router";
import { TEMPLATES } from "shared/constants/RoutePathConstants";

interface Props {
  height: number;
}

export default function ReceivedTemplate(props: Props) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadReceivedTemplatesAsync()).finally(hideLoadingPanel);
  }, []);

  const receivedTemplates = useAppSelector(
    (store) => store.templateData.receivedTemplates
  );

  const handleRowClick = useCallback(
    (evt: { data: DetailedTemplateResponse }) => {
      navigate(`${TEMPLATES}/${evt.data.id}`);
    },
    []
  );

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const LabelCellTemplate = useCallback((e: any) => {
    return (
      <LabelHolder
        labels={e.data.data.labels}
        labelType={SupportedLabelEntity.Template}
      />
    );
  }, []);

  const LastModifiedCellComponent = useCallback((e: any) => {
    return <>{getFormattedDateTime(e.data.data.lastModified)}</>;
  }, []);

  return (
    <div>
      <PromineoViewGrid
        dataSource={receivedTemplates}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        onRowClick={handleRowClick}
        height={props.height}
        gridIdentifier="receivedTemplatesGrid"
        noDataText="Your received templates will appear here once they are shared with your tenant!"
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column caption={"Title"} dataField={"title"} />
        <Column caption={"ID"} dataField={"id"} alignment="left" />
        <Column
          caption={"Originator"}
          dataField={"ownerTenant"}
          alignment="left"
        />
        <Column
          caption={"Labels"}
          dataField={"labels"}
          cssClass={"custom-control-padding"}
          cellComponent={LabelCellTemplate}
          allowFiltering={false}
        />
        <Column
          caption={"# of own fields"}
          dataField={"fieldCount"}
          alignment="left"
        />
        <Column
          caption={"# of own config"}
          dataField={"ownConfigsCount"}
          alignment="left"
        />
        <Column
          caption={"Last updated"}
          dataField="lastModified"
          alignment="left"
          cellComponent={LastModifiedCellComponent}
        />
        <Column
          caption={"Last updated by"}
          dataField={"lastModifierName"}
          alignment="left"
        />
      </PromineoViewGrid>
    </div>
  );
}
