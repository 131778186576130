import React from "react";

export default function TestFailedIcon() {
  return (
    <svg
      width="223"
      height="136"
      viewBox="0 0 223 136"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.420391"
        y="32.0122"
        width="179.159"
        height="91.9749"
        rx="6.30587"
        fill="white"
      />
      <rect
        x="0.420391"
        y="32.0122"
        width="179.159"
        height="91.9749"
        rx="6.30587"
        stroke="#EEEEEE"
        strokeWidth="0.840782"
      />
      <rect
        x="6.72656"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="6.72656"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="50.4648"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="94.2031"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="137.943"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.943"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="112.42"
        y="1.01219"
        width="105.159"
        height="100.014"
        rx="6.30587"
        fill="white"
      />
      <rect
        x="112.42"
        y="1.01219"
        width="105.159"
        height="100.014"
        rx="6.30587"
        stroke="#EEEEEE"
        strokeWidth="0.840782"
      />
      <rect
        x="118.727"
        y="9"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EEEEEE"
      />
      <rect
        x="118.727"
        y="24.4082"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="39.8145"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="55.2227"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="70.6309"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="86.0391"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <path
        d="M204.335 88.5478L201.045 85.2578L188.001 98.3011L174.958 85.2578L171.668 88.5478L184.711 101.591L171.668 114.634L174.958 117.924L188.001 104.881L201.045 117.924L204.335 114.634L191.291 101.591L204.335 88.5478Z"
        fill="#FF3A3A"
      />
    </svg>
  );
}
