export const replaceAtIndex = (
  str: string,
  ind: number,
  replacement: string
) => {
  var newString =
    str.substring(0, ind) +
    replacement +
    str.substring(ind + replacement.length);
  return newString;
};

export const PascalToCamelCase = (str: string) => {
  var camelCaseString = str;
  if (str[0] !== str[0].toLowerCase()) {
    camelCaseString = replaceAtIndex(str, 0, str[0].toLowerCase());
  }

  return camelCaseString;
};

export const truncateTextToLength = (text: string, length: number) => {
  if (text.length > length) {
    return text.substring(0, length) + "...";
  }
  return text;
};

export const convertToTitleCase = (str: string) => {
  const result = str
    .replace(/([A-Z])/g, " $1")
    .replace(/^./, (firstLetter) => firstLetter.toUpperCase());

  return result.trim();
};
