import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { useCallback, useRef, useState } from "react";
import ViewValueMapButtonCellTemplate from "../../config-common/ViewValueMapButtonCellTemplate";
import ViewValueMapsWithNoContentControlDialog from "./ViewValueMapsWithNoContentControlDialog";
import ViewValueMapsWithContentControlDialog from "./ViewValueMapsWithContentControlDialog";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import "components/common/grid/styles/PromineoUIGrid.css";
import ContentControlCellComponent from "components/common/ContentControlCellComponent";

interface Props {
  configMappings: FieldMappingResponse[];
  height: number;
  displaySearchbar?: boolean;
  isPreviewChange?: boolean;
  gridIdentifier: string;
}

export default function ConfigValueTransformationViewer(props: Props) {
  const { configMappings } = props;
  const [
    displayViewValueMapsWithNoContentControlDialog,
    setDisplayViewValueMapsWithNoContentControlDialog,
  ] = useState(false);

  const [
    displayViewValueMapsWithContentControlDialog,
    setDisplayViewValueMapsWithContentControlDialog,
  ] = useState(false);
  const [templateFieldMapping, setTemplateFieldMapping] =
    useState<FieldMappingResponse | null>(null);

  const ContentControlComponent = useCallback(
    (componentProps: { data: { data: FieldMappingResponse } }) => {
      return (
        <ContentControlCellComponent
          fieldMapping={componentProps.data.data}
          isPreviewChange={props.isPreviewChange}
        />
      );
    },
    []
  );

  const toolbarConfig = useRef({
    dislplaySearchPanel: props.displaySearchbar !== false,
    displayResetButton: true,
  });

  return (
    <div>
      <PromineoViewGrid
        dataSource={configMappings.filter((m) => !m.hasDirectMapping)}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig.current}
        height={props.height}
        gridIdentifier={props.gridIdentifier}
        noDataText="Fields using value transformation will appear here!"
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column
          caption={"Planning object type"}
          dataField={"planningObjectTypeText"}
          alignment="left"
        />
        <Column caption={"ILAP Term"} dataField={"name"} alignment="left" />
        <Column
          caption={"Description"}
          dataField={"description"}
          alignment="left"
        />
        <Column
          caption={"Data type"}
          dataField={"dataTypeText"}
          alignment="left"
        />
        <Column
          caption={"Content Control"}
          alignment="left"
          cellComponent={ContentControlComponent}
          width={"auto"}
        />
        <Column
          caption={"Mapping formula"}
          dataField={"formula"}
          alignment="left"
          cssClass="add-left-border"
        />
        <Column
          caption={"Host field"}
          dataField={"mappedConnectorHostFieldName"}
          alignment="left"
        />
        <Column
          caption={"Value map"}
          alignment="left"
          cssClass={"custom-control-padding"}
          cellRender={(data) => {
            return data.data.valueMaps.length ? (
              <ViewValueMapButtonCellTemplate
                onClick={() => {
                  data.data.allowContentControl
                    ? setDisplayViewValueMapsWithContentControlDialog(true)
                    : setDisplayViewValueMapsWithNoContentControlDialog(true);
                  setTemplateFieldMapping(data.data);
                }}
              />
            ) : (
              <div>-</div>
            );
          }}
        />
      </PromineoViewGrid>
      {displayViewValueMapsWithNoContentControlDialog && (
        <ViewValueMapsWithNoContentControlDialog
          onCancel={() =>
            setDisplayViewValueMapsWithNoContentControlDialog(false)
          }
          fieldMapping={templateFieldMapping}
        />
      )}
      {displayViewValueMapsWithContentControlDialog && (
        <ViewValueMapsWithContentControlDialog
          onCancel={() =>
            setDisplayViewValueMapsWithContentControlDialog(false)
          }
          fieldMapping={templateFieldMapping}
        />
      )}
    </div>
  );
}
