import { PropertyDefinition } from "interfaces/response/config-test/PropertyDefinition";

interface Props {
  propertyDefinition: PropertyDefinition;
}

export default function PropertyDefinitionViewer(props: Props) {
  const { propertyDefinition } = props;

  return (
    <div className="font-mono">
      <span className="text-lightTextGray">{propertyDefinition.dataType}</span>{" "}
      <span className="text-ilapBlue font-bold">{propertyDefinition.name}</span>{" "}
      {propertyDefinition.indexedParameters?.length > 0 && (
        <span>
          <span className="font-bold">this</span>
          <span>{"["}</span>
          {propertyDefinition.indexedParameters.map((param, index) => {
            return (
              <span key={index}>
                <span className="text-lightTextGray">{param.dataType} </span>
                <span>{param.name}</span>
                {index < propertyDefinition.indexedParameters.length - 1 ? ", " : ""}
              </span>
            );
          })}
          <span>{"]"}</span>
        </span>
      )}
      <span>
        {"("}
        {propertyDefinition.accessors.map((accessor, index) => {
          return (
            <span key={index}>
              <span className="text-lightTextGray">{accessor}</span>
              {index < propertyDefinition.accessors.length - 1 ? ", " : ""}
            </span>
          );
        })}
        {")"}
      </span>
    </div>
  );
}
