import PromineoAccordion from "components/common/controls/PromineoAccordion";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import { useCallback, useEffect, useState } from "react";
import { FieldMappingFormulaType } from "shared/enums/FieldMappingFormulaType";
import FormulaPickerAccordionItem from "./FormulaPickerAccordionItem";
import FormulaPickerAccordionTitle from "./FormulaPickerAccordionTitle";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";

interface Props {
  onFormulaSelection: (formula: FieldMappingFormulaResponse) => void;
  mappingPlanningObjectType: PlanningObjectTypes;
}

export interface FormulaPickerAccordionDataSource {
  title: string;
  formulaType: FieldMappingFormulaType;
  gridIdentifier: string;
}

export default function FormulaPickerAccordion(props: Props) {
  const { onFormulaSelection, mappingPlanningObjectType} = props;
  const [accordtionDataSource, setAccordtionDataSource] = useState<
    FormulaPickerAccordionDataSource[]
  >([]);

  useEffect(() => {
    let coreFieldFormulas: FormulaPickerAccordionDataSource = {
      title: "ILAP core fields",
      formulaType: FieldMappingFormulaType.CoreField,
      gridIdentifier: "FormulaPickerAccordionItemCoreFieldsGrid"
    };

    let systemFormulas: FormulaPickerAccordionDataSource = {
      title: "Example formulas",
      formulaType: FieldMappingFormulaType.System,
      gridIdentifier: "FormulaPickerAccordionItemExampleFormulaGrid"
    };

    let customFormulas: FormulaPickerAccordionDataSource = {
      title: "Custom formulas (shared with tenant)",
      formulaType: FieldMappingFormulaType.Custom,
      gridIdentifier: "FormulaPickerAccordionItemCustomFormulaGrid"
    };

    let newDataSource: FormulaPickerAccordionDataSource[] = [
      coreFieldFormulas,
      systemFormulas,
      customFormulas,
    ];
    setAccordtionDataSource(newDataSource);
  }, []);

  const titleComponent = useCallback(
    (gridProp: FormulaPickerAccordionDataSource) => {
      return <FormulaPickerAccordionTitle dataSource={gridProp} />;
    },
    []
  );

  const itemComponent = useCallback(
    (gridProp: FormulaPickerAccordionDataSource) => {
      return (
        <FormulaPickerAccordionItem
          dataSource={gridProp}
          onFormulaSelection={onFormulaSelection}
          mappingPlanningObjectType={mappingPlanningObjectType}
        />
      );
    },
    []
  );

  // selected index is 2 as custom formula are in the index 2
  return (
    <PromineoAccordion
      dataSource={accordtionDataSource}
      collapsible={true}
      itemRender={itemComponent}
      itemTitleRender={titleComponent}
      repaintChangesOnly={true}
      multiple={false}
      defaultSelectedIndex={2}
    />
  );
}
