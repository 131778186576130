import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { addTestEventAsync } from "store/actions/ExchangeAgreementActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  exchangeAgreementId: number;
}

export default function CreateTestButton(props: Props) {
  const dispatch = useAppDispatch();

  const handleCreateTestEventClick = () => {
    displayLoadingPanel();
    dispatch(addTestEventAsync(props.exchangeAgreementId))
      .unwrap()
      .then(() => {
        toastSuccess("Test event created succefully.");
      })
      .finally(hideLoadingPanel);
  };

  return (
    <PromineoButton
      text="Create test event"
      variant={PromineoButtonType.Primary}
      onClick={handleCreateTestEventClick}
    />
  );
}
