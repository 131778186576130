import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { InactiveIeaEditorTabs } from "./InactiveIEAEditor";
import InactiveIEAFooterForCurrentUserIsOwner from "./InactiveIEAFooterForCurrentUserIsOwner";
import InactiveIEAFooterForCurrentUserIsPartner from "./InactiveIEAFooterForCurrentUserIsPartner";
import InactiveIEAFooterForSameOwnerAndPartner from "./InactiveIEAFooterForSameOwnerAndPartner";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isPartnerSubmitButtonDisabled: boolean;
  isCurrentUserOwner: boolean;
  isCurrentUserPartner: boolean;
  selectedExchangeAgreement: ExchangeAgreementDetailedResponse;
  handleDeleteIEAClick: () => void;
  handleOwerIEASign: () => void;
  handleSubmitClick: () => void;
  handleNextClick: (nextTabIndex: number) => void;
  handlePartnerIEASign: () => void;
  handleCancelClick: () => void;
  handleSaveActiveIEA: () => void;
  isSaveChangesButtonDisabled: boolean;
  isActiveIeaSaveButtonDisabled: boolean;
  isActiveIeaEdit?: boolean;
  isCurrentUserSender: boolean;
  isCurrentUserReceiver: boolean;
  currentTab: InactiveIeaEditorTabs;
}

export default function InactiveIEAFooter(props: Props) {
  const {
    isPartnerSubmitButtonDisabled,
    isCurrentUserOwner,
    isCurrentUserPartner,
    selectedExchangeAgreement,
    handleDeleteIEAClick,
    handleOwerIEASign,
    handleSubmitClick,
    handlePartnerIEASign,
    handleSaveActiveIEA,
  } = props;

  return (
    <div className="flex justify-between mt-4">
      <div className="flex space-x-2">
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text="Cancel"
          onClick={props.handleCancelClick}
        />
        {isCurrentUserOwner && !props.isActiveIeaEdit && (
          <PromineoButton
            variant={PromineoButtonType.BorderlessDanger}
            text="Delete IEA"
            onClick={handleDeleteIEAClick}
          />
        )}
      </div>
      <div>
        {isCurrentUserOwner && !isCurrentUserPartner && (
          <InactiveIEAFooterForCurrentUserIsOwner
            exchangeAgreement={selectedExchangeAgreement}
            isCurrentUserSender={props.isCurrentUserSender}
            currentTab={props.currentTab}
            isCurrentReceiver={props.isCurrentUserReceiver}
            onOwnerSign={handleOwerIEASign}
            onGoToTab={props.handleNextClick}
            onSubmit={handleSubmitClick}
          />
        )}

        {props.isActiveIeaEdit && (
          <PromineoButton
            variant={PromineoButtonType.Primary}
            text="Save changes"
            onClick={handleSaveActiveIEA}
            disabled={props.isActiveIeaSaveButtonDisabled}
          />
        )}

        {isCurrentUserPartner && !isCurrentUserOwner && (
          <InactiveIEAFooterForCurrentUserIsPartner
            exchangeAgreement={selectedExchangeAgreement}
            currentTab={props.currentTab}
            isCurrentUserSender={props.isCurrentUserSender}
            isPartnerSubmitButtonDisabled={isPartnerSubmitButtonDisabled}
            isCurrentUserPartner={isCurrentUserPartner}
            isCurrentUserReceiver={props.isCurrentUserReceiver}
            onPartnerSign={handlePartnerIEASign}
            onGoToTab={props.handleNextClick}
            onPartnerInfoSubmit={handleSubmitClick}
            onOwnerSign={handleOwerIEASign}
          />
        )}

        {isCurrentUserOwner && isCurrentUserPartner && (
          <InactiveIEAFooterForSameOwnerAndPartner
            exchangeAgreement={selectedExchangeAgreement}
            currentTab={props.currentTab}
            isPartnerSubmitButtonDisabled={isPartnerSubmitButtonDisabled}
            isActiveIeaEdit={props.isActiveIeaEdit}
            isCurrentUserPartner={isCurrentUserPartner}
            isCurrentUserReceiver={props.isCurrentUserReceiver}
            onOwnerSign={handleOwerIEASign}
            onPartnerSign={handlePartnerIEASign}
            onGoToTab={props.handleNextClick}
            onPartnerInfoSubmit={handleSubmitClick}
          />
        )}
      </div>
    </div>
  );
}
