import { IEATestEventDataProviderHookResponseType } from "hooks/IEATestEventDataProviderHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import TestComparisonResultViewer from "../TestComparisonResultViewer";
import SkippedTestPlaceHolder from "./SkippedTestPlaceHolder";
import WaitingComparisonPlaceHolder from "./WaitingComparisonPlaceHolder";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserOwner: boolean;
  isCurrentUserSender: boolean;
  gridHeight: number;
  testResult: IEATestEventDataProviderHookResponseType;
}

export default function TestComparisonPlaceHolder(props: Props) {
  if (
    (props.exchangeAgreement.uploadTestRequired &&
      props.exchangeAgreement.latestDataTransferTestEvent &&
      props.exchangeAgreement.latestDataTransferTestEvent.uploadStatus !==
        DataTransferStatus.Success) ||
    (props.exchangeAgreement.uploadTestRequired &&
      !props.exchangeAgreement.latestDataTransferTestEvent)
  ) {
    return <WaitingComparisonPlaceHolder />;
  }

  if (
    !props.exchangeAgreement.uploadTestRequired &&
    props.exchangeAgreement.latestDataTransferTestEvent === undefined
  ) {
    return (
      <SkippedTestPlaceHolder
        displayGenerateButton={false}
        exchangeAgreement={props.exchangeAgreement}
        isUpload={true}
      />
    );
  }

  if (
    !props.exchangeAgreement.uploadTestRequired &&
    props.exchangeAgreement.latestDataTransferTestEvent?.uploadStatus ===
      DataTransferStatus.Skipped
  ) {
    return (
      <SkippedTestPlaceHolder
        displayGenerateButton={false}
        exchangeAgreement={props.exchangeAgreement}
        isUpload={true}
      />
    );
  }

  if (
    !props.exchangeAgreement.downloadTestRequired &&
    props.exchangeAgreement.latestDataTransferTestEvent?.downloadStatus ===
      DataTransferStatus.Skipped
  ) {
    return (
      <SkippedTestPlaceHolder
        displayGenerateButton={false}
        exchangeAgreement={props.exchangeAgreement}
        isUpload={false}
      />
    );
  }

  if (
    props.exchangeAgreement.downloadTestRequired &&
    props.exchangeAgreement.latestDataTransferTestEvent &&
    props.exchangeAgreement.latestDataTransferTestEvent.downloadStatus !==
      DataTransferStatus.Success
  ) {
    return <WaitingComparisonPlaceHolder />;
  }

  if (
    props.exchangeAgreement.latestDataTransferTestEvent &&
    props.exchangeAgreement.latestDataTransferTestEvent.uploadStatus ===
      DataTransferStatus.Success
  ) {
    if (
      props.exchangeAgreement.latestDataTransferTestEvent.downloadStatus ===
      DataTransferStatus.Success
    ) {
      return (
        <TestComparisonResultViewer
          exchangeAgreement={props.exchangeAgreement}
          isViewMode={true}
          height={props.gridHeight}
          testResult={props.testResult}
        />
      );
    }
  }

  return null;
}
