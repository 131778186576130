import SearchPlaceholderIcon from "components/icons/SearchPlaceholderIcon";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

export default function SendersUploadSuccessPlaceHolder() {
  return (
    <TestResultPlaceHolder
      icon={<SearchPlaceholderIcon />}
      title="Upload Success"
      subtitle={"Upload is successful in sender's execution component."}
    />
  );
}
