import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import SignatureDetails from "./SignatureDetails";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function OwnerPartnerSignatureDetail(props: Props) {
  const { exchangeAgreement } = props;
  return (
    <div className="flex space-x-6">
      {exchangeAgreement.partnerSignatureTime &&
        exchangeAgreement.partnerSigner && (
          <SignatureDetails
            userName={exchangeAgreement.partnerSigner.fullName}
            dateTime={exchangeAgreement.partnerSignatureTime}
            tenantName={exchangeAgreement.partnerTenant.name}
            participents="Partner"
          />
        )}

      {exchangeAgreement.ownerSignatureTime &&
        exchangeAgreement.ownerSigner && (
          <SignatureDetails
            userName={exchangeAgreement.ownerSigner.fullName}
            dateTime={exchangeAgreement.ownerSignatureTime}
            tenantName={exchangeAgreement.ownerTenant.name}
            participents="Owner"
          />
        )}
    </div>
  );
}
