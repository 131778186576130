import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import ExportToXlsxButton from "./ExportToXlsxButton"; // Adjust the path as needed
import ViewTestEventLogButton from "./ViewTestEventLogButton"; // Adjust the path as needed

interface ToolbarItemsProps {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  testResult: {
    handleComparisonExport: () => void;
    handleUploadExport: () => void;
    handleDownloadExport: () => void;
  };
  resultType?: "Upload" | "Download";
}

export default function TestResultViewerToolbarItems({
  exchangeAgreement,
  testResult,
  resultType,
}: ToolbarItemsProps) {
  const handleExport = !resultType
    ? testResult.handleComparisonExport
    : resultType === "Upload"
    ? testResult.handleUploadExport
    : testResult.handleDownloadExport;

  return (
    <div className="flex space-x-2 mr-2">
      {(resultType === "Upload" || resultType === "Download") && (
        <ViewTestEventLogButton
          exchangeAgreement={exchangeAgreement}
          isUpload={resultType === "Upload"}
        />
      )}
      <ExportToXlsxButton onClick={handleExport} />
    </div>
  );
}
