import FilterTile from "components/common/FilterTile";
import ComparisonIcon from "components/icons/ComparisonIcon";
import DownloadIcon from "components/icons/DownloadIcon";
import UploadIcon from "components/icons/UploadIcon";
import { useState } from "react";

export enum FilterSelectionType {
  UploadTest,
  TestComparison,
  DownloadTest,
}

interface Props {
  onChange: (selectedFilter: FilterSelectionType) => void;
}

export default function FilterButtons(props: Props) {
  const [selectedFilter, setSelectedFilter] = useState<FilterSelectionType>(
    FilterSelectionType.UploadTest
  );

  const handleChange = (selectedFilter: FilterSelectionType) => {
    setSelectedFilter(selectedFilter);
    props.onChange(selectedFilter);
  };

  return (
    <div className="flex space-x-2">
      <FilterTile
        label={"Upload Test"}
        iconComponent={<UploadIcon />}
        isSelected={selectedFilter === FilterSelectionType.UploadTest}
        onClick={() => handleChange(FilterSelectionType.UploadTest)}
      />
      <FilterTile
        label={"Test Comparison"}
        isSelected={selectedFilter === FilterSelectionType.TestComparison}
        iconComponent={<ComparisonIcon />}
        onClick={() => handleChange(FilterSelectionType.TestComparison)}
      />
      <FilterTile
        label={"Download Test"}
        iconComponent={<DownloadIcon />}
        isSelected={selectedFilter === FilterSelectionType.DownloadTest}
        onClick={() => handleChange(FilterSelectionType.DownloadTest)}
      />
    </div>
  );
}
