import TabPanel, { ITabPanelOptions } from "devextreme-react/tab-panel";
import "./styles/PromineoTabPanel.css";

interface Props {
  hideTabs?: boolean;
}

export default function PromineoTabPanel(props: Props & ITabPanelOptions) {
  const { ...tabPanelOptions } = props;
  return (
    <TabPanel
      {...tabPanelOptions}
      className={`promineo-tab-panel ${props.className ?? ""} ${
        props.hideTabs ? "tabs-hidden" : ""
      }`}
    ></TabPanel>
  );
}
