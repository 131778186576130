import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import DataTransferEventLogResponse from "interfaces/response/DataTransferEventLogResponse";
import DataTransferLogResponse from "interfaces/response/DataTransferLogResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useState } from "react";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";
import { loadDownloadDataTransferLogAsync, loadUploadDataTransferLogAsync } from "store/actions/DataTransferActions";
import { useAppDispatch } from "store/hooks";
import DetailsLogModal from "../active-ieas/event-log/DetailsLogModal";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isFromPlaceHolder?: boolean;
  isUpload: boolean;
}

export default function ViewTestEventLogButton(props: Props) {
  const dispatch = useAppDispatch();
  const [isDetailsLogModalVisible, setIsDetailsLogModalVisible] =
    useState(false);
  const [detailsLogs, setDetailsLogs] =
    useState<DataTransferEventLogResponse[]>();

  const handleDetailLogClick = () => {
    if (props.exchangeAgreement?.latestDataTransferTestEvent?.id) {
      displayLoadingPanel();
      if(props.isUpload){
        dispatch(
          loadUploadDataTransferLogAsync(
            props.exchangeAgreement.latestDataTransferTestEvent.id
          )
        )
          .unwrap()
          .then((logResponse: DataTransferLogResponse) => {
            setDetailsLogs(logResponse.dataTransferEventLogs);
            setIsDetailsLogModalVisible(true);
          })
          .finally(hideLoadingPanel);
      }else{
        dispatch(
          loadDownloadDataTransferLogAsync(
            props.exchangeAgreement.latestDataTransferTestEvent.id
          )
        )
          .unwrap()
          .then((logResponse: DataTransferLogResponse) => {
            setDetailsLogs(logResponse.dataTransferEventLogs);
            setIsDetailsLogModalVisible(true);
          })
          .finally(hideLoadingPanel);
      }
    }
  };

  const handleOnCloseClick = () => {
    setIsDetailsLogModalVisible(false);
  };

  const description = `${props.isUpload ? 'Upload': 'Download'} details log of ${
    props.exchangeAgreement.latestDataTransferTestEvent?.uploadedAt
      ? getFormattedDateTime(
          props.exchangeAgreement.latestDataTransferTestEvent?.uploadedAt
        )
      : "-"
  }`;

  let dataTransferStatusText = "";
  if (props.isUpload) {
    dataTransferStatusText = "Upload ";
    if (
      props.exchangeAgreement.latestDataTransferTestEvent?.uploadStatus ===
      DataTransferStatus.Fail
    ) {
      dataTransferStatusText += "Failed";
    } else if (
      props.exchangeAgreement.latestDataTransferTestEvent?.uploadStatus ===
      DataTransferStatus.Success
    ) {
      dataTransferStatusText += "Success";
    }
  } else {
    dataTransferStatusText = "Download ";
    if (
      props.exchangeAgreement.latestDataTransferTestEvent?.downloadStatus ===
      DataTransferStatus.Fail
    ) {
      dataTransferStatusText += "Failed";
    } else if (
      props.exchangeAgreement.latestDataTransferTestEvent?.downloadStatus ===
      DataTransferStatus.Success
    ) {
      dataTransferStatusText += "Success";
    }
  }

  return (
    <>
      <PromineoButton
        variant={
          props.isFromPlaceHolder
            ? PromineoButtonType.BorderlessWithBlueText
            : PromineoButtonType.Primary
        }
        text={"View detail log"}
        onClick={handleDetailLogClick}
      />
      {isDetailsLogModalVisible &&
        props.exchangeAgreement.latestDataTransferTestEvent &&
        detailsLogs && (
          <DetailsLogModal
            eventLogId={props.exchangeAgreement.latestDataTransferTestEvent?.id}
            title={props.exchangeAgreement.title}
            description={description}
            dataTransferStatus={
              props.exchangeAgreement.latestDataTransferTestEvent.uploadStatus
            }
            dataTransferStatusText={dataTransferStatusText}
            logDetailsResponse={detailsLogs}
            onCancel={handleOnCloseClick}
            isTestEvent={true}
          />
        )}
    </>
  );
}
