import { DataGrid } from "devextreme-react";
import { useCallback, useEffect, useMemo, useRef } from "react";
import GridOptionButton from "../controls/buttons/GridOptionButton";

function useGridOptionButtonRenderer(ref: React.Ref<DataGrid<any, any>> | undefined) {
  const gridRef = useRef<any>(null);

  useEffect(() => {
    if (ref) {
      gridRef.current = ref;
    }
  }, [ref]);

  const gridOptionButtonRender = useCallback(() => {
    const resetLayouts = () => {
      if (gridRef.current && gridRef.current.instance) {
        gridRef.current.instance.state({});
      }
    };
    return <GridOptionButton onResetLayout={resetLayouts} />;
  }, [gridRef]);

  const memoizedValue = useMemo(
    () => ({
      gridOptionButtonRender,
    }),
    [gridOptionButtonRender]
  );

  return memoizedValue;
}

export default useGridOptionButtonRenderer;
