import AddIcon from "components/icons/AddIcon";
import { Offset, Position } from "devextreme-react/autocomplete";
import ScrollView from "devextreme-react/scroll-view";
import { useState } from "react";
import { PromineoButtonType } from "../buttons/PromineoButton";
import PromineoButtonWithMultiSelectionPopover from "../buttons/PromineoButtonWithMultiSelectionPopover";
import PromineoPopover from "../PromineoPopover";
import PromineoChipComponent from "./PromineoChipComponent";

interface Props {
  componentId: string;
  canRemoveChip?: boolean;
  showAddButton?: boolean;
  selectedItemsToShow: any[];
  hiddenSelectedItems: any[];
  itemAddCandidateList: any[];
  onSelectedItemsChange?: (userIdList: number[]) => void;
  searchPlaceholder?: string;
  chipContainerGridIdentifier: string;
}

export default function PromineoChipContainer(props: Props) {
  const [isSeeMorePopOverVisible, setIsSeeMorePopOverVisible] =
    useState<boolean>(false);

  const showSeeMoreDropDown = () => {
    setIsSeeMorePopOverVisible(true);
  };

  const hideSeeMoreDropDown = () => {
    setIsSeeMorePopOverVisible(false);
  };

  const onRemoveClick = (id: number) => {
    if (props.onSelectedItemsChange) {
      const newSelectedItems = [
        ...props.selectedItemsToShow.map((m) => m.id),
        ...props.hiddenSelectedItems.map((m) => m.id),
      ];
      props.onSelectedItemsChange(newSelectedItems.filter((m) => m !== id));
    }
  };

  const onAddClick = (idList: number[]) => {
    if (props.onSelectedItemsChange) {
      const newSelectedItems = [
        ...props.selectedItemsToShow.map((m) => m.id),
        ...props.hiddenSelectedItems.map((m) => m.id),
        ...idList,
      ];
      props.onSelectedItemsChange(newSelectedItems);
    }
  };

  return (
    <>
      <div>
        <div className="flex flex-wrap gap-x-2 my-2 gap-y-2">
          {props.selectedItemsToShow.map((m) => {
            return (
              <PromineoChipComponent
                key={m.id}
                text={m.text}
                isDisabled={m.isDisabled && props.canRemoveChip}
                onRemoveClick={() => onRemoveClick(m.id)}
                canRemoveChip={props.canRemoveChip}
              />
            );
          })}
          {props.hiddenSelectedItems &&
            props.hiddenSelectedItems.length > 0 && (
              <div
                id={
                  props.componentId
                    ? `${props.componentId}-popover-seemore-button`
                    : ""
                }
              >
                <PromineoChipComponent
                  text={`+${props.hiddenSelectedItems.length} more`}
                  onClick={showSeeMoreDropDown}
                  canRemoveChip={false}
                  shouldHighlight={isSeeMorePopOverVisible}
                />
              </div>
            )}
        </div>
        {props.showAddButton && (
          <PromineoButtonWithMultiSelectionPopover
            className="hover:bg-transparent"
            id={props.componentId ? `${props.componentId}-popover-button` : ""}
            text="Add representative"
            LeftIcon={<AddIcon />}
            variant={PromineoButtonType.BorderlessWithBlueText}
            onSelectionChange={onAddClick}
            items={props.itemAddCandidateList}
            searchPlaceholder={props.searchPlaceholder}
            multiSelectionButtonPopoverGridIdentifier={
              props.chipContainerGridIdentifier
            }
          />
        )}
      </div>
      {isSeeMorePopOverVisible && (
        <PromineoPopover
          width={130}
          height={"auto"}
          maxHeight={200}
          visible={isSeeMorePopOverVisible}
          target={`#${props.componentId}-popover-seemore-button`}
          onHiding={hideSeeMoreDropDown}
        >
          <Position my={{ x: "right", y: "top" }}>
            <Offset x={30} y={-5} />
          </Position>
          <ScrollView showScrollbar="always" className="max-h-48">
            <div className="grid gap-y-2 p-2">
              {props.hiddenSelectedItems &&
                props.hiddenSelectedItems.length &&
                props.hiddenSelectedItems.map((m) => {
                  return (
                    <PromineoChipComponent
                      key={m.id}
                      text={m.text}
                      isDisabled={m.isDisabled && props.canRemoveChip}
                      widthClass={"max-w-[110px] w-fit"}
                      onRemoveClick={() => onRemoveClick(m.id)}
                      canRemoveChip={props.canRemoveChip}
                    />
                  );
                })}
            </div>
          </ScrollView>
        </PromineoPopover>
      )}
    </>
  );
}
