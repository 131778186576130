import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import useLinkedExchangeAgreementsToConfigAndSchedules from "hooks/LinkedExchangeAgreementsToConfigAndSchedulesHook";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import truncate from "lodash/truncate";
import { useMemo } from "react";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { LoadingStatus } from "shared/enums/LoadingStatus";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import { truncateTextToLength } from "shared/utilities/StringUtility";
import LinkedExchangeAgreementsAccordion from "../config-common/LinkedExchangeAgreementsAccordion";

interface Props {
  config: ConfigDetailResponse;
  changedScheduleIds: number[];
  configDirection: ConfigDirection;
  confirmButtonText: string;
  onConfirm: () => void;
  onCancel: () => void;
}

export default function ConfigScheduleSelectionChangeConfirmationDialog(
  props: Props
) {
  const linkedExchangeAgreementLookupRequest = useMemo(() => {
    return {
      configId: props.config.id,
      scheduleIdsToLookup: props.changedScheduleIds,
    };
  }, [props.config, props.changedScheduleIds]);

  const { linkedExchangeAgreementsWithSchedules, loadingStatus } =
    useLinkedExchangeAgreementsToConfigAndSchedules(
      linkedExchangeAgreementLookupRequest
    );

  const subContent = useMemo(() => {
    const direction = ConfigDirection[props.configDirection].toLowerCase();

    let exchangeAgreementCount = 0;
    let exchangeAgreementNames: string[] = [];
    let scheduleNames: string[] = [];

    for (const [scheduleId, exchangeAgreements] of Object.entries(
      linkedExchangeAgreementsWithSchedules
    )) {
      exchangeAgreementCount += exchangeAgreements.length;
      let schedule = props.config.schedules.find(
        (s) => s.id === Number(scheduleId)
      );

      let scheduleName = `${schedule?.scheduleId} ${truncateTextToLength(
        schedule?.title ?? "",
        40
      )}`;

      scheduleNames.push(scheduleName);
      exchangeAgreements.forEach((ex) => {
        exchangeAgreementNames.push(`${ex.id} ${ex.title}`);
      });
    }
    const countText =
      exchangeAgreementCount === 1
        ? "1 active IEA"
        : `${exchangeAgreementCount} active IEAs`;

    const accordionDataSource = [
      {
        title: `List of active IEAs affected`,
        item: exchangeAgreementNames.join(", "),
      },
    ];

    var changedSchedulesTitle = scheduleNames.join(", ");
    props.config.schedules
      .filter((s) => props.changedScheduleIds.includes(s.id))
      .map((s) => truncate(`${s.id} ${s.title}`, { length: 40 }))
      .join(", ");

    return (
      <div>
        <div className="mb-2 break-words">
          {`By deselecting schedules "${changedSchedulesTitle}", `}
          <span className="font-bold">{`${countText}`}</span>
          {` will not have a ${direction} config.`}
        </div>
        <LinkedExchangeAgreementsAccordion dataSource={accordionDataSource} />
      </div>
    );
  }, [
    linkedExchangeAgreementsWithSchedules,
    props.configDirection,
    props.changedScheduleIds,
  ]);

  if (
    loadingStatus === LoadingStatus.Pending ||
    loadingStatus === LoadingStatus.Loading
  ) {
    return null;
  }

  if (loadingStatus === LoadingStatus.Failed) {
    props.onCancel();
    return null;
  }

  if (loadingStatus === LoadingStatus.Success) {
    if (Object.keys(linkedExchangeAgreementsWithSchedules).length === 0) {
      props.onConfirm();
    }
  }

  return (
    <PromineoConfirmationDialog
      actionOptions={{
        mode: PromineoModalMode.Modify,
      }}
      content={<div>Deselect schedules</div>}
      subContent={subContent}
      confirmButtonText={props.confirmButtonText}
      onConfirm={props.onConfirm}
      onCancel={props.onCancel}
    />
  );
}
