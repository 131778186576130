import PrmineoRoundedTabPanel from "components/common/controls/PrmineoRoundedTabPanel";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import DownloadIcon from "components/icons/DownloadIcon";
import UpArrowIcon from "components/icons/UpArrowIcon";
import UploadIcon from "components/icons/UploadIcon";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/tab-panel";
import { IEATestEventDataProviderHookResponseType } from "hooks/IEATestEventDataProviderHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import moment from "moment";
import { useCallback, useMemo, useState } from "react";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import {
  getFormattedDateTime,
  norwegianDateFormat,
} from "shared/utilities/DateTimeUtility";
import "./style/IEATestResultViewer.css";
import TestResultViewerToolbarItems from "./TestResultViewerToolbarItems";
import TransferTestTabTitle from "./TransferTestTabTitle";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isViewMode: boolean;
  height: number;
  testResult: IEATestEventDataProviderHookResponseType;
}

export enum IEATesterTabs {
  Activities,
  Schedules,
  ResourceAssignments,
}

export default function TestComparisonResultViewer(props: Props) {
  const { testResult } = props;
  const [selectedTabIndex, setSelectedTabIndex] = useState<IEATesterTabs>(
    IEATesterTabs.Activities
  );

  const handleSelectedItemChange = useCallback((index: number) => {
    setSelectedTabIndex(index);
  }, []);

  const titleRenderer = useCallback(
    (data: any) => {
      return (
        <TransferTestTabTitle
          tabHeaderData={data}
          activityCount={testResult.comparisonResult?.activity.data.length}
          resourceAssignmentCount={
            testResult.comparisonResult?.resourceAssignment.data.length
          }
          scheduleCount={testResult.comparisonResult?.schedule.data.length}
          selectedTabIndex={selectedTabIndex}
        />
      );
    },
    [selectedTabIndex, props.testResult]
  );

  const toolBarConfigs = useMemo(() => {
    return {
      dislplaySearchPanel: true,
      customToolbarRenderer: () => (
        <TestResultViewerToolbarItems
          exchangeAgreement={props.exchangeAgreement}
          testResult={props.testResult}
        />
      ),
    };
  }, [props.exchangeAgreement, props.testResult]);

  const gridHeight =
    props.height - (props.exchangeAgreement.partnerSigner ? 40 : 20);

  const cellRender = useCallback((rowInfo: any) => {
    const dataField: string = rowInfo.column.dataField;
    const data = rowInfo.data[dataField];
    if (!data) {
      return null;
    }
    const isDateField =
      dataField === "EarlyStart" || dataField === "EarlyFinish";
    const separatedData = data.split(" | ");
    const isCodeField = dataField.toLocaleLowerCase() === "code";

    let downloadData = separatedData[1];
    let uploadData = separatedData[0];

    if (isDateField) {
      if (downloadData.trim()) {
        try {
          downloadData = moment
            .utc(downloadData)
            .local()
            .format(norwegianDateFormat);
        } catch {}
      }
      if (uploadData.trim()) {
        try {
          uploadData = moment
            .utc(uploadData)
            .local()
            .format(norwegianDateFormat);
        } catch {}
      }
    }

    return (
      <div className="text-sm">
        <div className="flex items-center space-x-2 justify-center h-[20px]">
          <div className="flex items-center justify-center gap-x-2">
            {downloadData}{" "}
            {downloadData && isCodeField ? <DownloadIcon /> : null}
          </div>
        </div>
        <div className="flex gap-x-2 justify-center">
          {isCodeField && (
            <div className="ml-8 mt-2">
              <UpArrowIcon />
            </div>
          )}
          <div className="mt-4 flex gap-x-2">
            <span className="italic">{uploadData}</span>{" "}
            {uploadData && isCodeField ? <UploadIcon /> : null}
          </div>
        </div>
      </div>
    );
  }, []);

  const transferTimeDisplay = useCallback(() => {
    if (props.testResult.uploadedAt && props.testResult.downloadedAt) {
      return (
        <div className="flex gap-x-3 ml-4 mt-4">
          <div className="flex gap-x-2">
            <UploadIcon></UploadIcon>
            {`Upload test completed: ${getFormattedDateTime(
              props.testResult.uploadedAt
            )}`}
          </div>
          <div className="flex gap-x-2">
            <DownloadIcon></DownloadIcon>
            {`Download test completed: ${getFormattedDateTime(
              props.testResult.downloadedAt
            )}`}
          </div>
        </div>
      );
    }

    return null;
  }, [props.testResult]);

  return (
    <div>
      <PrmineoRoundedTabPanel
        onSelectedIndexChange={handleSelectedItemChange}
        defaultSelectedIndex={selectedTabIndex}
      >
        <Item text={"Activity fields"} tabRender={titleRenderer}>
          <PromineoViewGrid
            gridIdentifier="test-comparison-activity-fields-grid"
            className="test-result-viewer-grid"
            height={gridHeight}
            width={"100%"}
            dataSource={testResult.comparisonResult?.activity.data ?? []}
            toolbarConfig={toolBarConfigs}
            additionalWidget={<>{transferTimeDisplay()}</>}
            noDataText={
              props.exchangeAgreement.uploadTestRequired &&
              (props.exchangeAgreement.latestDataTransferTestEvent
                ?.uploadStatus === DataTransferStatus.Pending ||
                props.exchangeAgreement.latestDataTransferTestEvent
                  ?.uploadStatus === DataTransferStatus.Running)
                ? "Test results will appear here"
                : ""
            }
          >
            <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
            {testResult.comparisonResult?.activity.columns.map((column) => {
              return (
                <Column
                  {...column}
                  key={column.dataField}
                  fixed={false}
                  width={200}
                  cellRender={cellRender}
                  visibleIndex={column.visibleIndex}
                />
              );
            })}
          </PromineoViewGrid>
        </Item>
        <Item text={"Schedule fields"} tabRender={titleRenderer}>
          <PromineoViewGrid
            height={gridHeight}
            width={"100%"}
            gridIdentifier="test-comparison-schedule-fields-grid"
            dataSource={testResult.comparisonResult?.schedule.data}
            toolbarConfig={toolBarConfigs}
            additionalWidget={<>{transferTimeDisplay()}</>}
            className="test-result-viewer-grid"
          >
            <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
            {testResult.comparisonResult?.schedule.columns.map((column) => {
              return (
                <Column
                  {...column}
                  key={column.dataField}
                  visibleIndex={column.visibleIndex}
                  fixed={false}
                  width={200}
                  cellRender={cellRender}
                />
              );
            })}
          </PromineoViewGrid>
        </Item>
        <Item text={"Resource assignments"} tabRender={titleRenderer}>
          <PromineoViewGrid
            gridIdentifier="test-comparison-resource-assignments-grid"
            height={gridHeight}
            width={"100%"}
            dataSource={testResult.comparisonResult?.resourceAssignment.data}
            toolbarConfig={toolBarConfigs}
            additionalWidget={<>{transferTimeDisplay()}</>}
            className="test-result-viewer-grid"
          >
            <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
            {testResult.comparisonResult?.resourceAssignment.columns.map(
              (column) => {
                return (
                  <Column
                    {...column}
                    key={column.dataField}
                    visibleIndex={column.visibleIndex}
                    fixed={false}
                    width={200}
                    cellRender={cellRender}
                  />
                );
              }
            )}
          </PromineoViewGrid>
        </Item>
      </PrmineoRoundedTabPanel>
    </div>
  );
}
