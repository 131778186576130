import PromineoViewGrid, {
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ExchangeAgreementEventLogResponse from "interfaces/response/ExchangeAgreementEventLogResponse";
import DataTransferEventLogResponse from "interfaces/response/DataTransferEventLogResponse";
import { useCallback, useRef, useState } from "react";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import {
  getFormattedTimestamp,
  getFormattedDateTime,
  getFormattedTime,
} from "shared/utilities/DateTimeUtility";
import DetailsLogModal from "./DetailsLogModal";
import DataTransferStatusCircle from "components/common/DataTransferStatusCircle";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import {
  loadUploadDataTransferLogAsync,
  loadDownloadDataTransferLogAsync,
} from "store/actions/DataTransferActions";
import { useDispatch } from "react-redux";
import { AppDispatch } from "store/store";
import { unwrapResult } from "@reduxjs/toolkit";
import DataTransferLogResponse from "interfaces/response/DataTransferLogResponse";
import "components/common/grid/styles/PromineoUIGrid.css";

interface Props {
  eventLogs: ExchangeAgreementEventLogResponse[];
  exchangeAgreementId: number;
  exchangeAgreementTitle: string;
  height: number;
}

export default function EventLogsGrid(props: Props) {
  const { eventLogs, exchangeAgreementId, exchangeAgreementTitle, height } =
    props;

  const dispatch = useDispatch<AppDispatch>();

  const [detailsLog, setDetailsLog] = useState<DataTransferEventLogResponse[]>(
    []
  );
  const [isDetailsLogModalVisible, setIsDetailsLogModalVisible] =
    useState<boolean>(false);
  const [detailsLogDescription, setDetailsLogDescription] =
    useState<string>("");
  const [dataTransferStatus, setDataTransferStatus] =
    useState<DataTransferStatus>(DataTransferStatus.Success);
  const [dataTransferStatusText, setDataTransferStatusText] =
    useState<string>("");

  const handleViewUploadLogClick = useCallback(
    (eventLog: ExchangeAgreementEventLogResponse) => {
      displayLoadingPanel();
      dispatch(loadUploadDataTransferLogAsync(eventLog.id))
        .then(unwrapResult)
        .then((logResponse: DataTransferLogResponse) => {
          setDetailsLog(logResponse.dataTransferEventLogs);
        })
        .finally(hideLoadingPanel);

      setDetailsLogDescription(
        `Upload details log of ${
          eventLog.uploadedAt ? getFormattedDateTime(eventLog.uploadedAt) : "-"
        }`
      );
      setDataTransferStatus(eventLog.uploadStatus);

      if (
        (eventLog.uploadStatus === DataTransferStatus.Pending ||
          eventLog.uploadStatus === DataTransferStatus.Overdue) &&
        eventLog.conflictResolveOption > 0
      ) {
        setDataTransferStatusText(
          `Upload ${eventLog.uploadStatusText} (Download initially failed due to conflict(s) but ready to be re-run)`
        );
      } else {
        setDataTransferStatusText(`Upload ${eventLog.uploadStatusText}`);
      }

      setIsDetailsLogModalVisible(true);
    },
    []
  );

  const handleViewDownloadLogClick = useCallback(
    (eventLog: ExchangeAgreementEventLogResponse) => {
      displayLoadingPanel();
      dispatch(loadDownloadDataTransferLogAsync(eventLog.id))
        .then(unwrapResult)
        .then((logResponse: DataTransferLogResponse) => {
          setDetailsLog(logResponse.dataTransferEventLogs);
        })
        .finally(hideLoadingPanel);

      setDataTransferStatus(eventLog.downloadStatus);

      if (
        (eventLog.downloadStatus === DataTransferStatus.Pending ||
          eventLog.downloadStatus === DataTransferStatus.Overdue) &&
        eventLog.conflictResolveOption > 0
      ) {
        setDataTransferStatusText(
          `Download ${eventLog.downloadStatusText} (Download initially failed due to conflict(s) but ready to be re-run)`
        );
      } else {
        setDataTransferStatusText(`Download ${eventLog.downloadStatusText}`);
      }

      setDetailsLogDescription(
        `Download details log of ${
          eventLog.downloadedAt
            ? getFormattedDateTime(eventLog.downloadedAt)
            : "-"
        }`
      );

      setIsDetailsLogModalVisible(true);
    },
    []
  );

  const handleOnCloseClick = useCallback(() => {
    setIsDetailsLogModalVisible(false);
  }, []);

  const showViewUploadLogOption = useCallback(
    (eventLog: ExchangeAgreementEventLogResponse) => {
      return eventLog.hasUploadLog;
    },
    []
  );

  const showViewDownloadLogOption = useCallback(
    (eventLog: ExchangeAgreementEventLogResponse) => {
      return eventLog.hasDownloadLog;
    },
    []
  );

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    items: [
      {
        onClick: (data) => handleViewUploadLogClick(data.data),
        text: "View upload detail log",
        visibleFn: (data) => showViewUploadLogOption(data.data),
      },
      {
        onClick: (data) => handleViewDownloadLogClick(data.data),
        text: "View download detail log",
        visibleFn: (data) => showViewDownloadLogOption(data.data),
      },
    ],
  });

  const GetStatusCell = useCallback(
    (status: DataTransferStatus, statusText: string) => {
      return (
        <>
          <div className="flex space-x-2">
            <div>
              <DataTransferStatusCircle status={status} />
            </div>
            <div>{statusText}</div>
          </div>
        </>
      );
    },
    []
  );

  const UploadStatusCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return (
        <>
          {GetStatusCell(
            e.data.data.uploadStatus,
            e.data.data.uploadStatusText
          )}
        </>
      );
    },
    []
  );

  const DownloadStatusCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return (
        <>
          {GetStatusCell(
            e.data.data.downloadStatus,
            e.data.data.downloadStatusText
          )}
        </>
      );
    },
    []
  );

  const PlannedUploadCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return <>{getFormattedDateTime(e.data.data.transferTime)}</>;
    },
    []
  );

  const UploadWarningCountCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return e.data.data.uploadWarningCount ? (
        <>{e.data.data.uploadWarningCount}</>
      ) : (
        <>{"-"}</>
      );
    },
    []
  );

  const UploadedAtCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return e.data.data.uploadedAt ? (
        <>{getFormattedTimestamp(e.data.data.uploadedAt)}</>
      ) : (
        <>{"-"}</>
      );
    },
    []
  );

  const UploadExecutionTimeCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return e.data.data.uploadExecutionTime ? (
        <>{getFormattedTime(e.data.data.uploadExecutionTime)}</>
      ) : (
        <>{"-"}</>
      );
    },
    []
  );

  const DownloadWarningCountCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return e.data.data.downloadWarningCount ? (
        <>{e.data.data.downloadWarningCount}</>
      ) : (
        <>{"-"}</>
      );
    },
    []
  );

  const DownloadedAtCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return e.data.data.downloadedAt ? (
        <>{getFormattedTimestamp(e.data.data.downloadedAt)}</>
      ) : (
        <>{"-"}</>
      );
    },
    []
  );

  const DownloadExecutionTimeCellComponent = useCallback(
    (e: { data: { data: ExchangeAgreementEventLogResponse } }) => {
      return e.data.data.downloadExecutionTime ? (
        <>{getFormattedTime(e.data.data.downloadExecutionTime)}</>
      ) : (
        <>{"-"}</>
      );
    },
    []
  );

  const toolbarConfig = useRef({
    displayResetButton: true,
    dislplaySearchPanel: false,
  });

  return (
    <>
      <PromineoViewGrid
        height={height}
        dataSource={eventLogs}
        className="event-logs-grid promineo-ui-grid"
        rowOperationConfig={rowOperationConfig.current}
        gridIdentifier="eventLogsGrid"
        toolbarConfig={toolbarConfig.current}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"Planned upload"}
          dataField="transferTime"
          alignment="left"
          cellComponent={PlannedUploadCellComponent}
        />
        <Column
          caption={"Status upload"}
          cellComponent={UploadStatusCellComponent}
        />
        <Column
          caption={"#Upload warnings"}
          dataField="uploadWarningCount"
          alignment="center"
          cellComponent={UploadWarningCountCellComponent}
        />
        <Column
          caption={"Upload timestamp"}
          dataField="uploadedAt"
          alignment="left"
          cellComponent={UploadedAtCellComponent}
        />
        <Column
          caption={"Upload execution time"}
          dataField="uploadExecutionTime"
          alignment="left"
          cellComponent={UploadExecutionTimeCellComponent}
        />

        <Column
          caption={"Status download"}
          cellComponent={DownloadStatusCellComponent}
        />
        <Column
          caption={"#Download warnings"}
          dataField="downloadWarningCount"
          alignment="center"
          cellComponent={DownloadWarningCountCellComponent}
        />
        <Column
          caption={"Download timestamp"}
          dataField="downloadedAt"
          alignment="left"
          cellComponent={DownloadedAtCellComponent}
        />
        <Column
          caption={"Download execution time"}
          dataField="downloadExecutionTime"
          alignment="left"
          cellComponent={DownloadExecutionTimeCellComponent}
        />
      </PromineoViewGrid>
      {isDetailsLogModalVisible && (
        <DetailsLogModal
          eventLogId={exchangeAgreementId}
          title={exchangeAgreementTitle}
          description={detailsLogDescription}
          dataTransferStatus={dataTransferStatus}
          dataTransferStatusText={dataTransferStatusText}
          logDetailsResponse={detailsLog}
          onCancel={handleOnCloseClick}
          isTestEvent={false}
        />
      )}
    </>
  );
}
