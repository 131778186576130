import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import PromineoModal from "components/modal/PromineoModal";
import ViewValueMapsDialogHeader from "./ViewValueMapsDialogHeader";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";

interface Props {
  fieldMapping: FieldMappingResponse | null;
  onCancel: () => void;
}

export default function ViewValueMapsWithContentControlDialog(props: Props) {
  const { fieldMapping, onCancel } = props;

  return (
    <PromineoModal
      isVisible={true}
      displayDefaultActions={true}
      coloredBg={true}
      width={720}
      height={640}
    >
      {fieldMapping && (
        <div>
          <ViewValueMapsDialogHeader
            onCancel={onCancel}
            fieldMappingName={fieldMapping.name}
          />
          <div>
            <PromineoViewGrid
              dataSource={props.fieldMapping?.valueMaps}
              height={560}
              className="promineo-grid-in-modal"
              gridIdentifier="viewValueMapsWithContentControlGrid"
              toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
            >
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column
                caption={"Template value"}
                dataField={"fieldValueText"}
                alignment="left"
              />
              <Column
                caption={"Template value description"}
                dataField={"fieldValueDescription"}
                alignment="left"
              />
              <Column
                caption={"Host value"}
                dataField={"value"}
                alignment="left"
              />
              <Column
                caption={"Host value description"}
                dataField={"description"}
                alignment="left"
              />
            </PromineoViewGrid>
          </div>
        </div>
      )}
    </PromineoModal>
  );
}
