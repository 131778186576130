import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { Item } from "devextreme-react/tab-panel";
import WizardModalWithStepper from "features/common/WizardModalWithStepper";
import useActivityFilterHostParameterDataHook, {
  ActivityFilterHostParameterDataHookProps,
} from "hooks/new-iea/ActivityFilterHostParameterDataHook";
import useHostParametersOverrideIeaDataHook, {
  HostParameterOverrideDataHookProps,
} from "hooks/new-iea/HostParameterOverrideIeaDataHook";
import useStepValidation from "hooks/new-iea/IEACreationStepValidationHook";
import useStepOneNewIeaDataHook from "hooks/new-iea/StepOneNewIeaDataHook";
import useStepThreeNewIeaDataHook from "hooks/new-iea/StepThreeNewIeaDataHook";
import useStepTwoNewIeaDataHook from "hooks/new-iea/StepTwoNewIeaDataHook";
import ExchangeAgreementDuplicateWriteRequest from "interfaces/request/ExchangeAgreementDuplicateWriteRequest";
import OwnerExchangeAgreementWriteRequest from "interfaces/request/OwnerExchangeAgreementWriteRequest";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NEW_IEAS } from "shared/constants/RoutePathConstants";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";
import { ExchangeRoleEnum } from "shared/enums/feature/ExchangeRoleEnum";
import { toastSuccess } from "shared/utilities/ToastUtility";
import {
  loadConfigForExchangeAgreementAsync,
  loadOwnerConfigForExchangeAgreementAsync,
} from "store/actions/ConfigActions";
import {
  createNewExchangeAgreementForOwnerAsync,
  duplicateExchangeAgreementAsync,
} from "store/actions/ExchangeAgreementActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetConfigForExchangeAgreement } from "store/slices/ConfigSlice";
import ActivityFilterHostParameterOverride from "../../common/ActivityFilterHostParameterOverride";
import IEAHostParameterOverride from "../../common/IEAHostParameterOverride";
import StepOneNewIEA from "./StepOneNewIEA";
import StepThreeNewIEA from "./StepThreeNewIEA";
import StepTwoNewIEA from "./StepTwoNewIEA";
import { HostSystem } from "shared/enums/feature/HostSystem";

interface Props {
  onClose: () => void;
  exchangeAgreementToDuplicate?: ExchangeAgreementDetailedResponse | null;
}

export default function NewIEAWizard(props: Props) {
  const totalStepForSender = 5;
  const totalStepForReceiver = 4;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [currentStep, setCurrentStep] = useState(1);
  const [totalStep, setTotalStep] = useState(totalStepForReceiver);
  const [
    isCancelConfirmationDialogVisible,
    setIsCancelConfirmationDialogVisible,
  ] = useState(false);
  const [
    isTemplateNotExistForDuplicationDialogVisible,
    setIsTemplateNotExistForDuplicationDialogVisible,
  ] = useState(false);

  const [showWizard, setShowWizard] = useState<boolean>(
    props.exchangeAgreementToDuplicate ? false : true
  );

  const [isActivityFilterValid, setIsActivityFilterValid] =
    useState<boolean>(true);
  const [isActivityFilterOn, setIsActivityFilterOn] = useState<boolean>(true);

  const onActivityFilterToggle = (value: boolean) => {
    setIsActivityFilterOn(value);
  };

  const isOwnerConfigListLoaded = useRef<boolean>(false);

  const goToNextStep = () => {
    setCurrentStep((prev) => (prev < totalStep ? prev + 1 : prev));
  };

  const goToPrevStep = () => {
    setCurrentStep((prev) => (prev > 1 ? prev - 1 : prev));
  };

  const handleNextOrSubmitClick = () => {
    if (currentStep === totalStep) {
      handleSubmitIEA();
    } else {
      goToNextStep();
    }
  };

  const loggedInUser = useAppSelector((state) => state.userData.mySelfResponse);
  const ownerConfigList = useAppSelector(
    (state) => state.configData.ownerConfigForExchangeAgreement
  );
  const ownerConfig = useAppSelector(
    (state) => state.configData.configForExchangeAgreement
  );

  // Step 1 hook
  const stepOneHookProps = useMemo(() => {
    return {
      configListForExchangeAgreement: ownerConfigList,
      exchangeAgreementToDuplicate: props.exchangeAgreementToDuplicate,
    };
  }, [ownerConfigList, props.exchangeAgreementToDuplicate]);

  const { coreInfoRequest, stepOneProps, isCoreInfoRequestValid } =
    useStepOneNewIeaDataHook(stepOneHookProps);

  // Step 2 hook
  const stepTwoHookProps = useMemo(() => {
    return {
      direction: coreInfoRequest.exchangeRole,
      loggedInUser: loggedInUser!,
      ownerConfig: ownerConfig,
      ownerConfigList: ownerConfigList,
      templateId: coreInfoRequest.templateId,
      exchangeAgreementToDuplicate: props.exchangeAgreementToDuplicate,
    };
  }, [
    coreInfoRequest,
    loggedInUser,
    ownerConfigList,
    ownerConfig,
    props.exchangeAgreementToDuplicate,
  ]);

  const { ownerConfigRequest, stepTwoProps, isOwnerConfigRequestValid } =
    useStepTwoNewIeaDataHook(stepTwoHookProps);

  // Step 3 hook
  const stepThreeHookProps = useMemo(() => {
    return {
      ownerExchangeRole:
        coreInfoRequest.exchangeRole === ConfigDirection.Receiving
          ? ExchangeRoleEnum.Receiver
          : ExchangeRoleEnum.Sender,
      exchangeAgreementToDuplicate: props.exchangeAgreementToDuplicate,
    };
  }, [coreInfoRequest, props.exchangeAgreementToDuplicate]);

  const { stepThreeProps, scheduleRequest, isScheduleRequestValid } =
    useStepThreeNewIeaDataHook(stepThreeHookProps);

  // Step 4 hook
  const stepFourHookProps = useMemo<HostParameterOverrideDataHookProps>(() => {
    return {
      direction: coreInfoRequest.exchangeRole,
      codeSet: ownerConfigRequest.ownerSchedule?.userFieldSetId,
      connectorId: ownerConfigRequest.connectorId,
      hostSystemParameters: ownerConfig?.hostSystemParameters,
      hostSystem: ownerConfigRequest.selectedConnector?.hostSystem,
      hostSystemName: ownerConfigRequest.selectedConnector?.hostSystemName,
      exchangeAgreementToDuplicate: props.exchangeAgreementToDuplicate,
    };
  }, [
    coreInfoRequest,
    ownerConfigRequest,
    ownerConfig,
    props.exchangeAgreementToDuplicate,
  ]);

  const {
    hostParameterOverrideProps,
    hostParameterRequest,
    isHostParameterRequestValid,
    getUpdatedHostParametersForSaving,
  } = useHostParametersOverrideIeaDataHook(stepFourHookProps);

  // Step 5 hook
  const stepFiveHookProps =
    useMemo<ActivityFilterHostParameterDataHookProps>(() => {
      return {
        hostSystem: ownerConfigRequest.selectedConnector?.hostSystem!,
        hostSystemParameters: hostParameterRequest,
        direction: coreInfoRequest.exchangeRole,
        sapActivityFilterProps: {
          frequencyType: scheduleRequest.frequencyType,
        },
        exchangeAgreementToDuplicate: props.exchangeAgreementToDuplicate,
      };
    }, [
      coreInfoRequest,
      ownerConfigRequest,
      scheduleRequest,
      hostParameterRequest,
    ]);

  const {
    activityFilterHostParameterOverrideProps,
    hostParametersWithActivityFilterRequest,
    isHostParametersWithActivityFilterRequestValid,
  } = useActivityFilterHostParameterDataHook(stepFiveHookProps);

  // End of Step hook

  useEffect(() => {
    if (props.exchangeAgreementToDuplicate && isOwnerConfigListLoaded.current) {
      let ownerConfigDirection =
        props.exchangeAgreementToDuplicate.direction ===
        DirectionEnum.OwnerToPartner
          ? ConfigDirection.Sending
          : ConfigDirection.Receiving;
      let templateExisitsForSourceIEA = ownerConfigList.find(
        (c) =>
          c.direction === ownerConfigDirection &&
          c.template.id === props.exchangeAgreementToDuplicate?.template.id
      );

      if (!templateExisitsForSourceIEA) {
        setIsTemplateNotExistForDuplicationDialogVisible(true);
      } else {
        setShowWizard(true);
      }
    }
  }, [props.exchangeAgreementToDuplicate, isOwnerConfigListLoaded.current]);

  useEffect(() => {
    if (coreInfoRequest.exchangeRole === ConfigDirection.Sending) {
      setTotalStep(totalStepForSender);
    } else {
      setTotalStep(totalStepForReceiver);
    }
  }, [coreInfoRequest]);

  useEffect(() => {
    displayLoadingPanel();
    dispatch(loadOwnerConfigForExchangeAgreementAsync()).finally(() => {
      isOwnerConfigListLoaded.current = true;
      hideLoadingPanel();
    });
  }, []);

  useEffect(() => {
    if (
      ownerConfigRequest.connectorId &&
      ownerConfigRequest.scheduleId &&
      ownerConfigRequest.selectedTemplateId
    ) {
      displayLoadingPanel();
      dispatch(
        loadConfigForExchangeAgreementAsync({
          templateId: ownerConfigRequest.selectedTemplateId,
          connectorId: ownerConfigRequest.connectorId,
          scheduleId: ownerConfigRequest.scheduleId,
          direction: ownerConfigRequest.selectedDirection,
        })
      ).finally(hideLoadingPanel);
    }

    return () => {
      dispatch(resetConfigForExchangeAgreement());
    };
  }, [
    ownerConfigRequest.selectedDirection,
    ownerConfigRequest.selectedTemplateId,
    ownerConfigRequest.connectorId,
    ownerConfigRequest.scheduleId,
  ]);

  const areAllStepDataValid = useStepValidation({
    isCoreInfoRequestValid,
    isOwnerConfigRequestValid,
    isScheduleRequestValid,
    isHostParameterRequestValid,
    isHostParametersWithActivityFilterRequestValid,
    currentStep,
  });

  const handleSubmitIEA = () => {
    if (
      (coreInfoRequest.exchangeRole === ConfigDirection.Sending &&
        currentStep !== totalStepForSender) ||
      (coreInfoRequest.exchangeRole === ConfigDirection.Receiving &&
        currentStep !== totalStepForReceiver) ||
      !areAllStepDataValid
    ) {
      return;
    }

    const hostSystem = ownerConfigRequest.selectedConnector?.hostSystem!;

    if (
      isActivityFilterOn &&
      ownerConfigRequest.selectedDirection == ConfigDirection.Sending &&
      hostSystem !== HostSystem.SAP
    ) {
      var isActivityFilterValid = true;
      const activityFilter =
        activityFilterHostParameterOverrideProps.generalActivityFilter
          ?.filterValue;

      if (!activityFilter) {
        isActivityFilterValid = false;
      }

      setIsActivityFilterValid(isActivityFilterValid);

      if (!isActivityFilterValid) {
        return;
      }
    }

    if (props.exchangeAgreementToDuplicate) {
      const exchangeAgreementDuplicationRequest: ExchangeAgreementDuplicateWriteRequest =
        {
          title: coreInfoRequest.title,
          comments: scheduleRequest.comments,
          startDate: scheduleRequest.startDate,
          expirationDate: scheduleRequest.expirationDate,
          executionCronExpression: scheduleRequest.executionCronExpression,
          predecessorAgreementId: scheduleRequest.predecessorAgreementId,
          frequencyType: scheduleRequest.frequencyType,
          lagInMinutes: scheduleRequest.lagInMinutes,
          ownerConfigSettings: JSON.stringify(
            getUpdatedHostParametersForSaving(
              hostParametersWithActivityFilterRequest ?? hostParameterRequest
            )
          ),
          ownerConnectorId: ownerConfigRequest.connectorId,
          ownerConnectorScheduleId: ownerConfigRequest.scheduleId,
          ownerRepresentativeIdentifiers:
            ownerConfigRequest.ownerRepresentatives,
          partnerRepresentativeId: coreInfoRequest.partnerRepresentativeId,
          partnerTenantId: coreInfoRequest.partnerTenantId,
        };

      displayLoadingPanel();
      dispatch(
        duplicateExchangeAgreementAsync({
          exchangeAgreementToDuplicateId: props.exchangeAgreementToDuplicate.id,
          exchangeAgreementDuplicationRequest:
            exchangeAgreementDuplicationRequest,
        })
      )
        .unwrap()
        .then((response: ExchangeAgreementDetailedResponse) => {
          toastSuccess("IEA duplicated successfully.");
          navigate(`${NEW_IEAS}/${response.id}`);
        })
        .finally(hideLoadingPanel);
    } else {
      const exchangeAgreementRequest: OwnerExchangeAgreementWriteRequest = {
        title: coreInfoRequest.title,
        comments: scheduleRequest.comments,
        startDate: scheduleRequest.startDate,
        expirationDate: scheduleRequest.expirationDate,
        executionCronExpression: scheduleRequest.executionCronExpression,
        predecessorAgreementId: scheduleRequest.predecessorAgreementId,
        partnerTenantId: coreInfoRequest.partnerTenantId,
        partnerRepresentativeId: coreInfoRequest.partnerRepresentativeId,
        templateId: ownerConfigRequest.selectedTemplateId,
        connectorId: ownerConfigRequest.connectorId,
        scheduleId: ownerConfigRequest.scheduleId,
        ownerRepresentativeIdentifiers: ownerConfigRequest.ownerRepresentatives,
        frequencyType: scheduleRequest.frequencyType,
        labelIdentifiers: scheduleRequest.labelIdentifiers,
        configSettings: JSON.stringify(
          getUpdatedHostParametersForSaving(
            hostParametersWithActivityFilterRequest ?? hostParameterRequest
          )
        ),
        ownerExchangeRole:
          coreInfoRequest.exchangeRole === ConfigDirection.Sending
            ? ExchangeRoleEnum.Sender
            : ExchangeRoleEnum.Receiver,
        lagInMinutes: scheduleRequest.lagInMinutes,
      };

      displayLoadingPanel();
      dispatch(
        createNewExchangeAgreementForOwnerAsync(exchangeAgreementRequest)
      )
        .unwrap()
        .then((response: ExchangeAgreementDetailedResponse) => {
          toastSuccess("IEA created successfully.");
          navigate(`${NEW_IEAS}/${response.id}`);
        })
        .finally(hideLoadingPanel);
    }
  };

  return (
    <>
      {showWizard && (
        <WizardModalWithStepper
          title={
            props.exchangeAgreementToDuplicate ? "Duplicate IEA" : "New IEA"
          }
          totalStep={totalStep}
          currentStep={currentStep}
          isDataValidToProceed={areAllStepDataValid}
          onBack={() => {
            setIsActivityFilterValid(true);
            goToPrevStep();
          }}
          onCancel={props.onClose}
          onNextOrSubmit={handleNextOrSubmitClick}
          isModified={true}
          cancelConfirmationMessage={
            props.exchangeAgreementToDuplicate
              ? "Are you sure you want to cancel duplicating the IEA?"
              : "Are you sure you want to cancel creating the IEA?"
          }
          content={
            <PromineoTabPanel
              selectedIndex={currentStep - 1}
              repaintChangesOnly={true}
              hideTabs={true}
            >
              <Item>
                <StepOneNewIEA {...stepOneProps} />
              </Item>
              <Item>
                <StepTwoNewIEA {...stepTwoProps} />
              </Item>
              <Item>
                <StepThreeNewIEA {...stepThreeProps} />
              </Item>
              <Item>
                <IEAHostParameterOverride {...hostParameterOverrideProps} />
              </Item>
              <Item>
                <ActivityFilterHostParameterOverride
                  {...activityFilterHostParameterOverrideProps}
                  onActivityFilterToggle={onActivityFilterToggle}
                  isActivityFilterInValid={!isActivityFilterValid}
                />
              </Item>
            </PromineoTabPanel>
          }
        ></WizardModalWithStepper>
      )}

      {isCancelConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={() => {
            props.onClose();
            setIsCancelConfirmationDialogVisible(false);
          }}
          onCancel={() => {
            setIsCancelConfirmationDialogVisible(false);
          }}
          content={`Are you sure you want to cancel ${
            props.exchangeAgreementToDuplicate ? "duplicating" : "creating"
          } the IEA?`}
          cancelButtonText="No"
          confirmButtonText="Yes"
        />
      )}

      {isTemplateNotExistForDuplicationDialogVisible && (
        <PromineoConfirmationDialog
          hideCancelButton={true}
          onConfirm={() => {
            props.onClose();
            setIsTemplateNotExistForDuplicationDialogVisible(false);
          }}
          content={`Cannot duplicate Exchange agreement as no owner config candidate ws found with the same connector and template`}
          confirmButtonText="Go back"
        />
      )}
    </>
  );
}
