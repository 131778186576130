import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import InformationBar from "components/common/InformationBar";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { Item } from "devextreme-react/tab-panel";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { EditorMode } from "shared/enums/EditorMode";
import { SupportedHostSystemConfigurationType } from "shared/types/HostSystemConfigurationTypes";
import { getHostSystemConfigurationValue } from "shared/utilities/HostSystemConfigurationUtility";
import { loadConfigByIdAsync } from "store/actions/ConfigActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetCurrentConfig } from "store/slices/ConfigSlice";
import DirectMappingEditor from "../config-edit/direct-mapping/DirectMappingEditor";
import HostSystemParameters from "../host-system/HostSystemParameters";
import ConfigScheduleViewer from "./config-schedule/ConfigScheduleViewer";
import ConfigValueTransformationViewer from "./config-value-transformation/ConfigValueTransformationViewer";
import ConfigViewerHeader from "./ConfigViewerHeader";
import TemplateChangeViewer from "./TemplateChangeViewer";

export default function ConfigViewer() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const [hostSystemConfiguration, setHostSystemConfiguration] =
    useState<SupportedHostSystemConfigurationType>();
  const [selectedCodeSet, setSelectedCodeSet] = useState<number | null>(null);
  const [displayTemplateChangeViewer, setDisplayTemplateChangeViewer] =
    useState(false);

  useEffect(() => {
    if (param.configId) {
      const configId = Number(param.configId);
      displayLoadingPanel();
      dispatch(loadConfigByIdAsync(configId)).finally(hideLoadingPanel);
    }

    return ()=>{
      dispatch(resetCurrentConfig());
    }
  }, []);

  const configToView = useAppSelector(
    (store) => store.configData.currentConfig
  );

  const headerDivId: string = "config-viewer-header-title";
  const excludedContainerIds: string[] = [headerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 130,
  });

  useEffect(() => {
    var hostSystemConfiguraion = undefined;

    if (configToView && configToView.connectorHostSystem) {
      hostSystemConfiguraion = getHostSystemConfigurationValue(
        configToView.connectorHostSystem,
        configToView.direction,
        configToView.hostSystemParameters
      );
    }

    if (hostSystemConfiguraion) {
      setHostSystemConfiguration(hostSystemConfiguraion);
    }

    if (configToView && configToView.schedules?.length > 0) {
      let codeSet = configToView.schedules[0].userFieldSetId ?? null;
      setSelectedCodeSet(codeSet);
    }
  }, [configToView]);

  const handleViewTemplateChangePreview = () => {
    setDisplayTemplateChangeViewer(true);
  };

  const handleHideTemplateChangeViewer = () => {
    setDisplayTemplateChangeViewer(false);
  };

  if (!configToView) {
    return <></>;
  }

  return (
    <>
      <div>
        <div id={headerDivId}>
          <ConfigViewerHeader
            config={configToView}
            onViewTemplateUpdateClick={handleViewTemplateChangePreview}
          />
          <div>
            {configToView.updateRequired && (
              <InformationBar
                text={"The template that is used in this config has updates."}
                action={{
                  text: "View updates",
                  onClick: handleViewTemplateChangePreview,
                }}
              />
            )}
          </div>
        </div>
        <PromineoTabPanel>
          <Item title={"Schedule"}>
            <div className="p-4">
              <ConfigScheduleViewer config={configToView} height={gridHeight} />
            </div>
          </Item>
          <Item title={"Direct Mapping"}>
            <div className="p-4">
              <DirectMappingEditor
                fieldMappings={configToView.mappings.filter((m) => m.fieldId)}
                height={gridHeight}
                isEdit={false}
                gridIdentifier="configViewerDirectMapping"
              />
            </div>
          </Item>
          <Item title={"Value Transformations"}>
            <div className="p-4">
              <ConfigValueTransformationViewer
                configMappings={configToView.mappings}
                height={gridHeight}
                gridIdentifier="configViewerValueTransformation"
              />
            </div>
          </Item>
          <Item title={"Host system parameters"}>
            {configToView && configToView.connectorHostSystem && (
              <div className="p-4">
                <HostSystemParameters
                  height={gridHeight}
                  mode={EditorMode.View}
                  direction={configToView.direction}
                  selectedCodeSet={selectedCodeSet}
                  selectedConnectorId={configToView.connectorId}
                  hostSystem={configToView.connectorHostSystem}
                  hostSystemConfiguration={hostSystemConfiguration}
                />
              </div>
            )}
          </Item>
        </PromineoTabPanel>
      </div>
      {displayTemplateChangeViewer && (
        <TemplateChangeViewer
          onCancel={handleHideTemplateChangeViewer}
          config={configToView}
          selectedCodeSet={selectedCodeSet}
        />
      )}
    </>
  );
}
