import { Column, Scrolling } from "devextreme-react/data-grid";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import TemplateShareResponse from "interfaces/response/TemplateShareResponse";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";

interface Props {
  sharedWithTenantsGridData: TemplateShareResponse[];
  templateId: number;
}

export default function ShareWithTenantsViewGrid(props: Props) {
  return (
    <div className="mt-20.5px">
      <PromineoViewGrid
        width={670}
        height={520}
        noDataText="Tenants will appear here once they are shared."
        dataSource={props.sharedWithTenantsGridData}
        gridIdentifier="sharedTenantsGrid"
        toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"Partner Tenant"}
          dataField={"tenantName"}
          alignment="left"
        />
        <Column
          caption={"# of partner configs"}
          dataField={"configCount"}
          alignment="left"
        />
      </PromineoViewGrid>
    </div>
  );
}
