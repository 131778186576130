import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useState } from "react";
import {
  updateDownloadTestEventStatusAsSkippedAsync,
  updateUploadTestEventStatusAsSkippedAsync,
} from "store/actions/ExchangeAgreementActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isUpload: boolean;
  isCurrentUserPartner: boolean;
  isCurrentUserOwner: boolean;
}

export default function ContinueWithoutTestButton({
  exchangeAgreement,
  isUpload,
  isCurrentUserPartner,
  isCurrentUserOwner,
}: Props) {
  const dispatch = useAppDispatch();
  const [
    isSkipTestEventConfirmationDialogVisible,
    setIsSkipTestEventConfirmationDialogVisible,
  ] = useState(false);

  const handleContinueWithoutTestClick = () => {
    setIsSkipTestEventConfirmationDialogVisible(true);
  };

  const handleCancelContinueWithoutTestClick = () => {
    setIsSkipTestEventConfirmationDialogVisible(false);
  };

  const handleConfirmContinueWithoutTestClick = () => {
    if (exchangeAgreement) {
      displayLoadingPanel();
      if (isUpload) {
        dispatch(
          updateUploadTestEventStatusAsSkippedAsync({
            exchangeAgreementId: exchangeAgreement.id,
            isCurrentUserPartner,
          })
        ).finally(hideLoadingPanel);
      } else {
        dispatch(
          updateDownloadTestEventStatusAsSkippedAsync({
            exchangeAgreementId: exchangeAgreement.id,
            isCurrentUserPartner,
            isCurrentUserOwner
          })
        ).finally(hideLoadingPanel);
      }
    }
    setIsSkipTestEventConfirmationDialogVisible(false);
  };
  return (
    <>
      <PromineoButton
        text="Skip test"
        variant={PromineoButtonType.BorderlessWithBlueText}
        onClick={handleContinueWithoutTestClick}
      />
      {isSkipTestEventConfirmationDialogVisible && (
        <PromineoConfirmationDialog
          onConfirm={handleConfirmContinueWithoutTestClick}
          onCancel={handleCancelContinueWithoutTestClick}
          content="Are you sure?"
          subContent={`The ${
            isUpload ? "upload" : "download"
          } test enables you to find and fix errors before the IEA starts. It is highly recommended to take the test.`}
          cancelButtonText="Cancel"
          confirmButtonText="Continue"
        />
      )}
    </>
  );
}
