import { IEATestEventDataProviderHookResponseType } from "hooks/IEATestEventDataProviderHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import IEATestResultViewer from "../IEATestResultViewer";
import CreateTestEventPlaceHolder from "./CreateTestEventPlaceHolder";
import PendingUploadTestPlaceHolder from "./PendingUploadTestPlaceHolder";
import RunningTestEventPlaceHolder from "./RunningTestEventPlaceHolder";
import SendersUploadSuccessPlaceHolder from "./SendersUploadSuccessPlaceHolder";
import SignedIeaPlaceholder from "./SignedIeaPlaceholder";
import SkippedTestPlaceHolder from "./SkippedTestPlaceHolder";
import TestFailedPlaceHolder from "./TestFailedPlaceHolder";
import WaitingForOwnerSignTestPlaceHolder from "./WaitingForOwnerSignTestPlaceHolder";
import WaitingForPartnerSignTestPlaceHolder from "./WaitingForPartnerSignTestPlaceHolder";
import WaitingForOwnerOrPartnerPlaceHolder from "./WaitingForSenderPlaceHolder";

export interface UploadTestPlaceHolderProps {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserSender: boolean;
  isCurrentUserOwner: boolean;
  gridHeight: number;
  testResult: IEATestEventDataProviderHookResponseType;
}

const UploadTestPlaceholderRenderer = ({
  gridHeight,
  isCurrentUserOwner,
  isCurrentUserSender,
  exchangeAgreement,
  testResult,
}: UploadTestPlaceHolderProps) => {
  const iea = exchangeAgreement;

  if (
    iea.latestDataTransferTestEvent &&
    iea.latestDataTransferTestEvent.uploadStatus === DataTransferStatus.Success
  ) {
    return (
      <IEATestResultViewer
        exchangeAgreement={iea}
        isViewMode={true}
        height={gridHeight}
        testResult={testResult}
        resultType="Upload"
      />
    );
  }

  if (
    (iea.uploadTestRequired &&
      iea.actionRequiredIfInactive ===
        NewExchangeAgreementActionRequired.WaitingForPartnerToTest) ||
    iea.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForOwnerToTest
  ) {
    return <WaitingForOwnerOrPartnerPlaceHolder transferType="upload" />;
  }

  if (
    iea.latestDataTransferTestEvent &&
    iea.latestDataTransferTestEvent.uploadStatus === DataTransferStatus.Success
  ) {
  }

  if (
    iea.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForPartner &&
    iea.uploadTestRequired
  ) {
    return <WaitingForOwnerOrPartnerPlaceHolder transferType="upload" />;
  }

  if (
    iea.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.PerformUploadTest
  ) {
    const latestTestEvent = iea.latestDataTransferTestEvent;

    if (!latestTestEvent) {
      return (
        <CreateTestEventPlaceHolder
          exchangeAgreement={iea}
          isCurrentUserOwner={isCurrentUserOwner}
          isCurrentUserSender={isCurrentUserSender}
        />
      );
    }

    switch (latestTestEvent.uploadStatus) {
      case DataTransferStatus.Fail:
        return (
          <TestFailedPlaceHolder
            exchangeAgreement={iea}
            transferType="upload"
          />
        );
      case DataTransferStatus.Running:
        return (
          <RunningTestEventPlaceHolder
            exchangeAgreement={iea}
            isCurrentUserOwner={isCurrentUserOwner}
            isCurrentUserSender={isCurrentUserSender}
            transferType="Upload"
          />
        );
      default:
        return (
          <PendingUploadTestPlaceHolder
            exchangeAgreement={iea}
            isCurrentUserOwner={isCurrentUserOwner}
            isCurrentUserSender={isCurrentUserSender}
          />
        );
    }
  }

  if (
    iea.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForOwner &&
    iea.uploadTestRequired
  ) {
    return <WaitingForOwnerOrPartnerPlaceHolder transferType="upload" />;
  }

  if (
    iea.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.WaitingForPartnerSign
  ) {
    return (
      <WaitingForPartnerSignTestPlaceHolder
        exchangeAgreement={exchangeAgreement}
      />
    );
  }

  if (
    iea.actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.WaitingForOwnerSign
  ) {
    return <WaitingForOwnerSignTestPlaceHolder />;
  }

  if (
    iea.actionRequiredIfInactive === NewExchangeAgreementActionRequired.SignOff
  ) {
    const latestTestEvent = exchangeAgreement.latestDataTransferTestEvent;
    const uploadTestRequired = exchangeAgreement.uploadTestRequired;

    if (
      !latestTestEvent ||
      latestTestEvent.uploadStatus === DataTransferStatus.Skipped ||
      uploadTestRequired === false
    ) {
      return (
        <SkippedTestPlaceHolder
          exchangeAgreement={iea}
          isUpload={true}
          displayGenerateButton={
            isCurrentUserSender && !exchangeAgreement.partnerSigner
          }
        />
      );
    }

    if (latestTestEvent.uploadStatus === DataTransferStatus.Success) {
      return <SendersUploadSuccessPlaceHolder />;
    }
  }

  if (iea.actionRequiredIfInactive === undefined) {
    return <SignedIeaPlaceholder exchangeAgreement={exchangeAgreement} />;
  }

  return null;
};

export default UploadTestPlaceholderRenderer;
