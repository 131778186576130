import StatusCircle, {
  StatusCircleColors,
} from "components/common/controls/StatusCircle";
import TickIcon from "./TickIcon";

export default function TickCircleIcon() {
  return (
    <StatusCircle
      iconComponent={<TickIcon color="white" />}
      color={StatusCircleColors.Green}
      size={21}
    />
  );
}
