import BorderComponent from "components/common/BorderComponent";
import { TypeSpecification } from "interfaces/response/config-test/TypeSpecification";
import MethodDefinitionsViewer from "./MethodDefinitionsViewer";
import PropertyDefinitionsViewer from "./PropertyDefinitionsViewer";

interface Props {
  showNonStatic?: boolean;
  showStatic?: boolean;
  typeSpecification: TypeSpecification;
}

export default function TypeSpecificationViewer(props: Props) {
  const {
    typeSpecification,
    showNonStatic,
    showStatic,
  } = props;

  const dataTypeDisplayWidth = "w-[550px]"; // more than this width can cause this section width change unexpectedly
  return (
    <div>
      <div title={typeSpecification.dataTypeFull} className={`${dataTypeDisplayWidth} truncate`}>
        Result Data Type:{" "}
        <span className="font-bold text-ilapBlue">
          {typeSpecification.dataTypeFull}
        </span>
      </div>
      <BorderComponent customClass="my-2" />
      <div>
        Format Explanation:{" "}
        <span className="text-lightTextGray">{`<DataType>`}</span>
        <span className="text-ilapBlue">{`<Property or Method>`}</span>
        <span>{`(<Parameter(s)>)`}</span>
      </div>
      {showNonStatic && (
        <div>
          <div className="my-4">
            <MethodDefinitionsViewer
              name={"METHODS"}
              methodDefinitions={typeSpecification.methods}
            />
          </div>
          <div className="my-4">
            <PropertyDefinitionsViewer
              name={"PROPERTIES"}
              propertyDefinitions={typeSpecification.properties}
            />
          </div>
        </div>
      )}
      {showStatic && (
        <div>
          <div className="my-4">
            <MethodDefinitionsViewer
              name={`STATIC METHODS for ${typeSpecification.dataType}`}
              methodDefinitions={typeSpecification.staticMethods}
            />
          </div>
          <div className="my-4">
            <PropertyDefinitionsViewer
              name={`STATIC PROPERTIES for ${typeSpecification.dataType}`}
              propertyDefinitions={typeSpecification.staticProperties}
            />
          </div>
        </div>
      )}
    </div>
  );
}
