import { useCallback, useMemo } from "react";

export interface GridStateStoreHookProps {
  gridIdentifier: string;
  storeFilters: boolean;
}

export interface GridStateStoreHookType {
  loadState: () => any;
  saveState: (data: any) => void;
}

export default function useGridStateStoreHook(
  props: GridStateStoreHookProps
): GridStateStoreHookType {
  const columnsPropertyName = "columns";
  const filterValuesPropertyName = "filterValues";

  // Currently, we are storing only column's state
  const propertiesToSave = new Set<string>([columnsPropertyName]);

  const getRequiredPropertiesToSave = useCallback((gridState: any) => {
    let dataForStoringState: any = {};
    if (gridState) {
      Object.keys(gridState).forEach((key) => {
        if (propertiesToSave.has(key)) {
          let propertyDataToSave = gridState[key];
          if (key === columnsPropertyName) {
            // Do not store applied filters on columns.
            propertyDataToSave.forEach((column: any) => {
              delete column[filterValuesPropertyName];
            });
          }
          dataForStoringState[key] = propertyDataToSave;
        }
      });
    }
    return dataForStoringState;
  }, []);

  const handleSave = useCallback(
    (data: any) => {
      if (props.gridIdentifier) {
        const stateToSave = getRequiredPropertiesToSave(data);
        localStorage.setItem(props.gridIdentifier, JSON.stringify(stateToSave));
      }
    },
    [getRequiredPropertiesToSave, props.gridIdentifier]
  );

  const handleLoad = useCallback(() => {
    if (props.gridIdentifier) {
      const storedState = localStorage.getItem(props.gridIdentifier);
      return JSON.parse(storedState ?? "{}");
    }

    return null;
  }, [props.gridIdentifier]);

  const response = useMemo<GridStateStoreHookType>(() => {
    return {
      loadState: handleLoad,
      saveState: handleSave,
    };
  }, [handleSave, handleLoad]);

  return response;
}
