
export default function DownloadIcon() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10 0.367188C4.49 0.367188 0 4.85719 0 10.3672C0 15.8772 4.49 20.3672 10 20.3672C15.51 20.3672 20 15.8772 20 10.3672C20 4.85719 15.51 0.367188 10 0.367188ZM10 18.3672C5.59 18.3672 2 14.7772 2 10.3672C2 5.95719 5.59 2.36719 10 2.36719C14.41 2.36719 18 5.95719 18 10.3672C18 14.7772 14.41 18.3672 10 18.3672ZM12.59 6.95719L14 8.36719L10 12.3672L6 8.36719L7.41 6.95719L9 8.53719V4.36719H11V8.53719L12.59 6.95719ZM15 15.3672H5V13.3672H15V15.3672Z"
        fill="#8E8E8E"
        style={{
          fill: "#8E8E8E",
          fillOpacity: 1,
        }}
      />
    </svg>
  );
}
