import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Paging, Scrolling } from "devextreme-react/data-grid";
import FieldDetailsResponse from "interfaces/response/FieldDetailsResponse";
import FieldDetailsWithSummaryResponse from "interfaces/response/FieldDetailsWithSummaryResponse";
import FieldMappingWithSummaryResponse from "interfaces/response/FieldMappingWithSummaryResponse";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";
import FieldUpdateCellTemplate from "./FieldUpdateCellTemplate";

interface Props {
  fields:
    | FieldDetailsWithSummaryResponse[]
    | FieldMappingWithSummaryResponse[]
    | FieldDetailsResponse[];
  height: number;
  displayUpdateColumn?: boolean;
  noDataText?: string;
  gridIdentifier: string;
}

export default function TemplateChangePreviewGrid(props: Props) {
  return (
    <PromineoViewGrid
      dataSource={props.fields}
      className="promineo-ui-grid"
      height={props.height}
      noDataText={props.noDataText}
      gridIdentifier={props.gridIdentifier}
      toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
    >
      <Paging enabled={false} />
      <Scrolling mode={"standard"} />
      <Column
        caption={"Planning object type"}
        dataField="planningObjectTypeText"
      />
      <Column caption={"ILAP term"} dataField="name" />
      <Column caption={"Description"} dataField="description" />

      <Column caption={"Data type"} dataField="dataTypeText" />
      <Column
        caption={"Content control"}
        dataField="contentControlLevelText"
        alignment="left"
      />
      <Column
        caption={"# of values"}
        dataField="valueCount"
        alignment={"left"}
      />
      <Column
        cssClass={"custom-control-padding ignore-row-click"}
        caption={"Update details"}
        dataField="updateText"
        width={330}
        visible={props.displayUpdateColumn === true}
        cellComponent={FieldUpdateCellTemplate}
      />
    </PromineoViewGrid>
  );
}
