import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import ConnectorDetailsResponse from "interfaces/response/connector/ConnectorDetailsResponse";
import CoreFieldResponse from "interfaces/response/CoreFieldResponse";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import HostFieldResponse from "interfaces/response/HostFieldResponse";
import { useEffect, useState } from "react";
import { HostSystem } from "shared/enums/feature/HostSystem";
import {
  GetNewId,
  deepCopyObject,
  getClipboardData,
} from "shared/utilities/CommonUtility";
import { getCoreFieldMappingResponseFromCoreFieldResponse } from "shared/utilities/ConfigUtility";
import { toastError } from "shared/utilities/ToastUtility";
import { loadConnectorHostFieldAsync } from "store/actions/ConnectorActions";
import { useAppDispatch } from "store/hooks";
import { setCurrentConfig } from "store/slices/ConfigSlice";

interface Props {
  destinationConfig: ConfigDetailResponse | null;
  selectedConnectorDetail: ConnectorDetailsResponse | null;
  isConnectorNullFieldsLoaded: boolean;
  coreFields: CoreFieldResponse[];
  showErrorModal: () => void;
  isConfigDataLoaded: boolean;
  setIsConfigDataLoaded: (isLoaded: boolean) => void;
}

interface ValidationErrors {
  validationMessages: string[];
  warningCount: number;
}

export default function useImportConfigFromClipboard(props: Props) {
  const {
    destinationConfig,
    selectedConnectorDetail,
    isConnectorNullFieldsLoaded,
    showErrorModal,
    coreFields,
    isConfigDataLoaded,
    setIsConfigDataLoaded,
  } = props;

  const dispatch = useAppDispatch();
  const [validationErrors, setValidationErrors] = useState<ValidationErrors>({
    validationMessages: [],
    warningCount: 0,
  });
  const [isConnectorUserFieldsLoaded, setIsConnectorUserFieldsLoaded] =
    useState<boolean>(false);

  const getConfigCodeSet = (config: ConfigDetailResponse) => {
    return config.schedules && config.schedules.length !== 0
      ? config.schedules[0].userFieldSetId
      : null;
  };

  // connector host fields will be loaded for the current connector and current selected code set if there is any
  // and will be available in the "selectedConnectorDetail"
  useEffect(() => {
    if (destinationConfig && selectedConnectorDetail && !isConfigDataLoaded) {
      const currentCodeSet = getConfigCodeSet(destinationConfig);
      if (currentCodeSet) {
        displayLoadingPanel();
        dispatch(
          loadConnectorHostFieldAsync({
            connectorId: selectedConnectorDetail.id,
            includeAll: false,
            userFieldSetId: currentCodeSet,
            includeAllWithNullUserFieldId: false,
          })
        )
          .catch(() => {
            toastError(
              `Failed to load host fields for connector with id ${selectedConnectorDetail.id}.`
            );
          })
          .finally(() => {
            setIsConnectorUserFieldsLoaded(true);
            hideLoadingPanel();
          });
      }
    }
  }, [
    destinationConfig,
    dispatch,
    selectedConnectorDetail,
    isConfigDataLoaded,
  ]);

  useEffect(() => {
    // if config data is already loaded or any of the required data is not available, we do not proceed
    if (
      isConfigDataLoaded ||
      !destinationConfig ||
      !selectedConnectorDetail ||
      coreFields.length === 0 ||
      !isConnectorUserFieldsLoaded ||
      !isConnectorNullFieldsLoaded
    ) {
      return;
    }

    const getConfigFromClipboard = async () => {
      const configInClipboard = await getClipboardData();

      const messages: string[] = [];
      let warningCount = 0;

      const addWarningLine = (
        warning: string,
        increaseWarningCount: boolean = true
      ) => {
        messages.push(warning);
        if (increaseWarningCount) {
          warningCount++;
        }
      };

      // This function will not increase the warning count
      const addInfoLine = (line: string) => {
        addWarningLine(line, false);
      };

      // This function will not increase the warning count
      const addSeparatorLine = () => {
        addInfoLine("------------------------------------------");
      };

      const validateConnectorHostSystemAndConfigDirection = (
        existingConfig: ConfigDetailResponse,
        configToImport: ConfigDetailResponse
      ) => {
        // if the connector and the config direction does not match, we do not import anything
        const isValid =
          configToImport.connectorHostSystem ===
            existingConfig.connectorHostSystem &&
          configToImport.direction === existingConfig.direction;
        if (!isValid) {
          addWarningLine(
            `Could not import config - The connector host system '${
              HostSystem[configToImport.connectorHostSystem ?? 0]
            }' and direction '${
              configToImport.directionText
            }' does not match with the destination config.`
          );
        }
        return isValid;
      };

      const setHostSystemParametersForConfig = (
        config: ConfigDetailResponse,
        importConfig: ConfigDetailResponse
      ) => {
        config.hostSystemParameters = importConfig.hostSystemParameters;
      };

      const getHostFieldNameWithAlias = (hostField: HostFieldResponse) => {
        return `${hostField.name}${
          hostField.alias ? ` (${hostField.alias})` : ""
        }`;
      };

      try {
        const configToImport = JSON.parse(
          configInClipboard
        ) as ConfigDetailResponse;
        const existingConfig = deepCopyObject(
          destinationConfig
        ) as ConfigDetailResponse;

        if (
          !validateConnectorHostSystemAndConfigDirection(
            existingConfig,
            configToImport
          )
        ) {
          return;
        }

        const existingConfigCodeSet = getConfigCodeSet(existingConfig);

        const setHostFieldForMapping = (
          fieldMapping: FieldMappingResponse,
          fieldMappingToImport: FieldMappingResponse
        ) => {
          if (!fieldMappingToImport.mappedConnectorHostFieldName) {
            return;
          }

          if (
            fieldMapping.planningObjectType !==
            fieldMappingToImport.planningObjectType
          ) {
            addWarningLine(
              `Could not map host field '${fieldMappingToImport.mappedConnectorHostFieldName}' - Planning object type does not match for term '${fieldMappingToImport.name}'`
            );
            internalWarningCount++;
            return;
          }

          let hostFieldsAvailableInConnector =
            selectedConnectorDetail?.hostFields?.filter(
              (hf) =>
                hf.planningObjectType ===
                  fieldMappingToImport.planningObjectType &&
                getHostFieldNameWithAlias(hf) ===
                  fieldMappingToImport.mappedConnectorHostFieldName
            );

          if (
            !hostFieldsAvailableInConnector ||
            hostFieldsAvailableInConnector.length === 0
          ) {
            addWarningLine(
              `Could not map host field '${fieldMappingToImport.mappedConnectorHostFieldName}' - Does not exist in the destination connector`
            );
            internalWarningCount++;
            return;
          }

          let hostFieldAvailableInCodeSet =
            hostFieldsAvailableInConnector?.find(
              (hf) =>
                !hf.userFieldSetId ||
                (existingConfigCodeSet &&
                  existingConfigCodeSet === hf.userFieldSetId)
            );

          if (!hostFieldAvailableInCodeSet) {
            addWarningLine(
              `Could not map host field '${fieldMappingToImport.mappedConnectorHostFieldName}' - Does not exist in the destination codeset`
            );
            internalWarningCount++;
            return;
          }

          fieldMapping.mappedConnectorHostFieldId =
            hostFieldAvailableInCodeSet.id;
          fieldMapping.mappedConnectorHostFieldName =
            hostFieldAvailableInCodeSet.name;
        };

        const setValueMaps = (
          fieldMapping: FieldMappingResponse,
          fieldMappingToImport: FieldMappingResponse
        ) => {
          // if no value maps exists in the source, we do not need to do anything
          if (!fieldMappingToImport.valueMaps?.length) {
            return;
          }

          if (
            fieldMapping.allowContentControl !==
            fieldMappingToImport.allowContentControl
          ) {
            addWarningLine(
              `Could not set any value map - Allow content control value does not match for ${fieldMappingToImport.name}.`
            );
            internalWarningCount++;
            return;
          }

          fieldMappingToImport.valueMaps = fieldMappingToImport.valueMaps.map(
            (vm) => {
              vm._key_ = GetNewId();
              vm.configFieldMappingId = fieldMapping.id;
              return vm;
            }
          );

          // if content control is not allowed, we keep the existing maps and append the imported maps
          if (!fieldMapping.allowContentControl) {
            fieldMapping.valueMaps = [
              ...fieldMapping.valueMaps,
              ...fieldMappingToImport.valueMaps,
            ];
            return;
          }

          let valueMapNotAbleToSetCount = 0;

          // if content control is allowed, then..
          fieldMappingToImport.valueMaps.forEach((vmImport) => {
            // if the (template_value, host_value) pair exists, do nothing
            const existingMap = fieldMapping.valueMaps.find(
              (vm) =>
                vm.fieldValueText === vmImport.fieldValueText &&
                vm.value === vmImport.value
            );
            if (existingMap) {
              return;
            }

            // if the map does not exist in destination, but the value is available there, then append the map
            const existingValue = fieldMapping.values.find(
              (v) => v.code === vmImport.fieldValueText
            );
            if (existingValue) {
              // we keep the field value id and description the same as the existing one
              fieldMapping.valueMaps.push({
                ...vmImport,
                configTemplateFieldValueId:
                  existingValue.configTemplateFieldValueId,
                fieldValueId: existingValue.id,
                fieldValueText: existingValue.code,
                fieldValueDescription: existingValue.description,
              });
            } else {
              valueMapNotAbleToSetCount++;
              addWarningLine(
                `Could not set value map - '${vmImport.fieldValueText}' is not defined in the destination.`
              );
              internalWarningCount++;
            }
          });

          if (valueMapNotAbleToSetCount > 0) {
            addInfoLine(
              `Could not set ${valueMapNotAbleToSetCount} value maps in total.`
            );
          }

          return;
        };

        let internalWarningCount = 0;
        configToImport.mappings.forEach((fieldMappingToImport) => {
          internalWarningCount = 0;
          let existingFieldMapping = existingConfig.mappings.find(
            (m) =>
              m.name === fieldMappingToImport.name &&
              m.planningObjectType ===
                fieldMappingToImport.planningObjectType &&
              m.dataType === fieldMappingToImport.dataType &&
              m.isConfigTemplateFieldMapping ===
                fieldMappingToImport.isConfigTemplateFieldMapping &&
              m.isCoreFieldMapping === fieldMappingToImport.isCoreFieldMapping
          );

          if (
            !existingFieldMapping &&
            fieldMappingToImport.isCoreFieldMapping
          ) {
            // if core field mapping is not present in the config, we will add that core field mapping
            // value maps for that core field will be added later
            let coreField = coreFields.find(
              (c) =>
                c.name === fieldMappingToImport.name &&
                c.planningObjectType ===
                  fieldMappingToImport.planningObjectType &&
                c.dataType === fieldMappingToImport.dataType
            );

            if (coreField) {
              // Creating a new core field to add
              existingFieldMapping =
                getCoreFieldMappingResponseFromCoreFieldResponse(coreField);
              existingConfig.mappings.push(existingFieldMapping);
            }
          }

          if (!existingFieldMapping) {
            if (fieldMappingToImport.isConfigTemplateFieldMapping) {
              addWarningLine(
                `Could not map ILAP term '${fieldMappingToImport.name}' - No template field found.`
              );
            } else {
              addWarningLine(
                `Could not map ILAP term '${fieldMappingToImport.name}' - No core field found.`
              );
            }
            internalWarningCount++;
            addSeparatorLine();
            return;
          }

          existingFieldMapping.hasDirectMapping =
            fieldMappingToImport.hasDirectMapping;
          existingFieldMapping.formula = fieldMappingToImport.formula;

          if (
            existingFieldMapping.isCoreFieldMapping &&
            existingFieldMapping.id === 0
          ) {
            addWarningLine(`Core field '${fieldMappingToImport.name}' added.`);
            internalWarningCount++;
          }

          setHostFieldForMapping(existingFieldMapping, fieldMappingToImport);

          setValueMaps(existingFieldMapping, fieldMappingToImport);

          if (internalWarningCount > 0) {
            let fieldType = fieldMappingToImport.isCoreFieldMapping
              ? "Core field"
              : "Ilap term";
            addInfoLine(`For '${fieldType}' - '${fieldMappingToImport.name}'`);
            addSeparatorLine();
          }
        });

        setHostSystemParametersForConfig(existingConfig, configToImport);

        // store the updated config in the redux store so that it is updated everywhere
        dispatch(setCurrentConfig(existingConfig));
      } catch (ex: any) {
        addWarningLine(ex.message);
      } finally {
        if (warningCount > 0) {
          setValidationErrors({
            validationMessages: messages,
            warningCount: warningCount,
          });
          showErrorModal();
        }
        setIsConfigDataLoaded(true);
      }
    };

    getConfigFromClipboard();
  }, [
    dispatch,
    destinationConfig,
    selectedConnectorDetail,
    showErrorModal,
    coreFields,
    isConnectorUserFieldsLoaded,
    isConnectorNullFieldsLoaded,
    isConfigDataLoaded,
    setIsConfigDataLoaded,
  ]);

  return {
    validationErrors,
  };
}
