import PromineoViewGrid, {
  PromineoGridToolbarConfig,
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import CopyIcon from "components/icons/CopyIcon";
import { Column, Scrolling } from "devextreme-react/data-grid";
import useLoadTenantStatusesHook from "hooks/LoadTenantStatusesHook";
import TenantResponse from "interfaces/response/tenant/TenantResponse";
import { useCallback, useMemo, useRef, useState } from "react";
import { copyToClipboard } from "shared/utilities/CommonUtility";
import { toastSuccess } from "shared/utilities/ToastUtility";
import OwnerStateCellComponent from "./OwnerStateCellComponent";
import StatusDisplayComponent from "./StatusDisplayComponent";
import TenantDetailDialog from "./tenant-detail-dialog/TenantDetailDialog";
import { TenantStatusCodes } from "shared/enums/TenantStatus";
import { getFormattedDate } from "shared/utilities/DateTimeUtility";

interface Props {
  tenants: TenantResponse[];
  height: number;
}

export default function TenantsGrid(props: Props) {
  const [currentSelectedTenant, setCurrentSelectedTenant] =
    useState<TenantResponse | null>(null);

  const [isTenantDialogEditable, setIsTenantDialogEditable] =
    useState<boolean>(false);

  const tenantStatusDropdownOptions = useLoadTenantStatusesHook();

  const handleCopyEmailClick = useCallback(
    async (data: { data: TenantResponse }) => {
      await copyToClipboard(data.data.mainContactEmail);
      toastSuccess("Email address copied to clipboard.");
    },
    []
  );

  const handleCopyAllEmailClick = useCallback(async () => {
    var concatenatedEmails = props.tenants
      .map((tn) => tn.mainContactEmail)
      .filter((ce) => ce)
      .join(";");
    await copyToClipboard(concatenatedEmails);
    toastSuccess("All email addresses are copied to clipboard.");
  }, [props.tenants]);

  const handleViewTenantClick = useCallback((evt: { data: TenantResponse }) => {
    setIsTenantDialogEditable(false);
    setCurrentSelectedTenant(evt.data);
  }, []);

  const handleEditTenantClick = useCallback((evt: { data: TenantResponse }) => {
    setIsTenantDialogEditable(true);
    setCurrentSelectedTenant(evt.data);
  }, []);

  const onTenantEditIconClick = useCallback(() => {
    setIsTenantDialogEditable(true);
  }, []);

  const toolbarConfig = useMemo<PromineoGridToolbarConfig>(() => {
    return {
      dislplaySearchPanel: true,
      addNewButtonOptions:{
        isVisible: true,
        text: "Copy email address",
        icon: <CopyIcon />,
        onClick: handleCopyAllEmailClick,
      }
    };
  }, [handleCopyAllEmailClick]);

  const setMainContactEmailAddress = useCallback((data: TenantResponse) => {
    return data.mainContactEmail ? data.mainContactEmail : "-";
  }, []);

  const resetCurrentSelectedTenant = useCallback(() => {
    setCurrentSelectedTenant(null);
  }, []);

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    displayDefault: false,
    items: [
      {
        text: "View tenant",
        visible: true,
        onClick: handleViewTenantClick,
      },
      {
        text: "Copy email address",
        visible: true,
        onClick: handleCopyEmailClick,
      },
      {
        text: "Edit tenant",
        visible: true,
        onClick: handleEditTenantClick,
      },
    ],
  });

  const StatusDisplay = useCallback(
    (data: any) => {
      let statuses = tenantStatusDropdownOptions.map((status) => {
        let updatedStatus = { ...status };
        if (status.value === TenantStatusCodes.Active) {
          updatedStatus.name = "Approve request";
        } else if (status.value === TenantStatusCodes.Rejected) {
          updatedStatus.name = "Reject request";
        } else if (status.value === TenantStatusCodes.Pending) {
          updatedStatus.name = "Pending tenant request";
        }

        return updatedStatus;
      });

      return (
        <StatusDisplayComponent
          data={data}
          tenantStatusDropdownOptions={statuses}
        />
      );
    },
    [tenantStatusDropdownOptions]
  );

  return (
    <>
      <PromineoViewGrid
        dataSource={props.tenants}
        className="promineo-ui-grid"
        toolbarConfig={toolbarConfig}
        rowOperationConfig={rowOperationConfig.current}
        height={props.height}
        gridIdentifier="tenantsGrid"
        noDataText="Tenants will appear here once they are added!"
      >
        <Scrolling rowRenderingMode={"virtual"} mode={"virtual"} />
        <Column caption={"Tenant name"} dataField="name" alignment="left" />
        <Column caption={"Domain"} dataField="domain" alignment="left" />
        <Column
          caption={"Status"}
          alignment="left"
          width={234}
          cssClass={"custom-control-padding items-center"}
          cellRender={StatusDisplay}
        />
        <Column
          caption={"Can be owner"}
          dataField="canBeOwner"
          alignment="left"
          cellComponent={OwnerStateCellComponent}
        />
        <Column
          caption={"Tenant since"}
          dataField="tenantSince"
          alignment="left"
        />
        <Column
          caption={"Main contact email address"}
          dataField="mainContactEmail"
          alignment="left"
          calculateCellValue={setMainContactEmailAddress}
        />
        <Column caption={"# of IEAs"} dataField="ieaCount" alignment="left" />
        <Column
          caption={"Last tenant activity"}
          dataField="lastActivity"
          alignment="left"
          customizeText={(data: any) =>
            data?.value ? getFormattedDate(data.value) : "-"
          }
        />
      </PromineoViewGrid>

      {currentSelectedTenant && (
        <TenantDetailDialog
          tenantId={currentSelectedTenant.id}
          isEdit={isTenantDialogEditable}
          onEditIconClick={onTenantEditIconClick}
          onClose={resetCurrentSelectedTenant}
        />
      )}
    </>
  );
}
