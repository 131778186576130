import WaitingForSenderTestIcon from "components/icons/WaitingForSenderTestIcon";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

export default function WaitingComparisonPlaceHolder() {
  return (
    <TestResultPlaceHolder
      icon={<WaitingForSenderTestIcon />}
      title={`Waiting for receiver to perform download test`}
      subtitle={`When the receiver has completed the download test, a comparison of the uploaded and downloaded test result will appear here. To complete the download test, the sender must first perform the upload test.`}
    />
  );
}
