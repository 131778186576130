import DataGrid, { IDataGridOptions } from "devextreme-react/data-grid";
import useGridIdentifierProvider from "hooks/GridIdentifierProviderHook";
import { ReactNode, forwardRef, useCallback, useRef } from "react";
import PromineoBaseGrid, { RowDeletionOption } from "./PromineoBaseGrid";
import "./styles/PromineoEditGrid.css";

interface RowHighlighting {
  highlightRow: boolean;
  rowKey?: string;
  highlightFn?: (e: any) => boolean;
}

interface Props {
  children?: ReactNode;
  rowHighlighting?: RowHighlighting;
  displayDeleteRowOption?: RowDeletionOption;
  gridIdentifier: string;
}

const PromineoEditGrid = forwardRef(
  (props: Props & IDataGridOptions, ref: React.Ref<DataGrid<any, any>>) => {
    const { rowHighlighting, ...rest } = props;
    const gridRef = useRef<DataGrid<any, any> | null>(null);

    const highlightRow = useCallback((e: any) => {
      const classToAdd = "bg-lighterBlue";
      e.rowElement.classList.remove(classToAdd);
      e.rowElement.classList.add(classToAdd);
    }, []);

    const handleRowPrepared = useCallback(
      (e: any) => {
        if (e.rowType === "data") {
          if (rowHighlighting && rowHighlighting.highlightRow) {
            let rowKey = rowHighlighting.rowKey ?? "id";
            if (rowHighlighting.highlightFn && rowHighlighting.highlightFn(e)) {
              highlightRow(e);
            } else if (!e.data[rowKey]) {
              highlightRow(e);
            }
          }
        }
      },
      [highlightRow]
    );

    const gridIdentifierWithUserIdentity = useGridIdentifierProvider(
      props.gridIdentifier
    );

    const gridRefCallback = useCallback(
      (instance: DataGrid<any, any> | null) => {
        gridRef.current = instance;
        if (typeof ref === "function") {
          ref(instance);
        } else if (ref) {
          (ref as React.MutableRefObject<DataGrid<any, any> | null>).current = instance;
        }
      },
      [ref]
    );

    return (
      <PromineoBaseGrid
        ref={gridRefCallback}
        {...rest}
        repaintChangesOnly={true}
        onRowPrepared={handleRowPrepared}
        children={rest.children}
        className={`promineo-edit-grid ${props.className ?? ""}`}
        rowDeletoinOption={props.displayDeleteRowOption}
        gridIdentifier={gridIdentifierWithUserIdentity}
      />
    );
  }
);

export default PromineoEditGrid;
