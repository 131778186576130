import WaitingForSenderTestIcon from "components/icons/WaitingForSenderTestIcon";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

interface Props {
  transferType: "upload" | "download";
}

export default function WaitingForOwnerOrPartnerPlaceHolder(props: Props) {
  return (
    <TestResultPlaceHolder
      icon={<WaitingForSenderTestIcon />}
      title={`Waiting for ${
        props.transferType === "upload" ? "sender" : "receiver"
      } to perform ${props.transferType}  test`}
      subtitle={`${
        props.transferType === "upload" ? "Sender" : "Receiver"
      } hasn’t completed the ${props.transferType} test yet.`}
    />
  );
}
