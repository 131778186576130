import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ValueMap from "interfaces/common/ValueMap";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";

interface Props {
  valueMaps: ValueMap[];
  height: number;
}

export default function BulkInsertValueMapPreviewGrid(props: Props) {
  return (
    <div>
      <PromineoViewGrid
        dataSource={props.valueMaps}
        height={props.height}
        toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
        gridIdentifier="bulkInsertValueMapPreviewGrid"
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column dataField={"templateValue"} caption="Template value" />
        <Column dataField={"hostValue"} caption="Host value" />
        <Column dataField={"hostValueDescription"} caption="Comment" />
      </PromineoViewGrid>
    </div>
  );
}
