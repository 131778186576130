import AddNewButton from "components/common/controls/buttons/AddNewButton";
import DataGrid, {
  Column,
  IDataGridOptions,
  Item,
  Toolbar,
} from "devextreme-react/data-grid";
import useGridIdentifierProvider from "hooks/GridIdentifierProviderHook";
import React, {
  forwardRef,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { getFavoritItemsForGrid } from "shared/utilities/PromineoGridFavoriteItemUtility";
import useGridOptionButtonRenderer from "./GridOptionButtonRendererHook";
import PromineoBaseGrid, { RowDeletionOption } from "./PromineoBaseGrid";
import PromineoGridFavoriteColumnTemplate from "./PromineoGridFavoriteColumnTemplate";
import PromineoGridRowOptionColumnTemplate, {
  PromineoGridRowOptionConfig,
} from "./PromineoGridRowOptionColumnTemplate";
import "./styles/PromineoViewGrid.css";

interface FavoriteRowConfig {
  enabled: boolean;
  gridNameWithUserIdentifer: string;
  dataIdentifier?: string;
}

interface PromineoGridRowOperationDefaultOption {
  onOpen?: (data: any) => void;
  onModify?: (data: any) => void;
  onCopy?: (data: any) => void;
  hideOpen?: boolean;
  hideModify?: boolean;
  hideCopy?: boolean;
}

export interface PromineoGridToolbarConfig {
  dislpayToolbar?: boolean;
  dislplaySearchPanel?: boolean;
  displayResetButton?: boolean;
  customToolbarRenderer?: () => ReactElement;
  addNewButtonOptions?: {
    isVisible?: boolean;
    onClick?: () => void;
    text?: string;
    icon?: ReactNode;
    buttonId?: string;
    disabled?: boolean;
  };
}

export type RowOperationConfig = Omit<
  PromineoGridRowOptionConfig,
  "defaultOptionConfig"
> & {
  defaultOptionConfig?: PromineoGridRowOperationDefaultOption;
  displayDeleteRowOption?: RowDeletionOption;
  displayValidationError?: {
    visible: boolean;
  };
  visible: boolean;
};

export interface PromineoGridOptions {
  toolbarConfig?: PromineoGridToolbarConfig;
  additionalWidget?: ReactElement;
  displayHeaderFilter?: boolean;
  children?: ReactNode;
  favoriteConfig?: FavoriteRowConfig;
  rowOperationConfig?: RowOperationConfig;
  gridIdentifier: string;
}

const PromineoViewGrid = forwardRef(
  (
    props: PromineoGridOptions & IDataGridOptions,
    ref: React.Ref<DataGrid<any, any> | undefined>
  ) => {
    const { children, toolbarConfig: toolBarOptions, ...rest } = props;
    const gridRef = useRef<any>();

    useImperativeHandle(ref, () => gridRef.current, []);

    const [favoriteRowIds, setFavoriteRowIds] = useState<any[]>([]);

    useEffect(() => {
      if (props.favoriteConfig?.enabled) {
        const favoriteRows = getFavoritItemsForGrid(
          props.favoriteConfig?.gridNameWithUserIdentifer
        );
        setFavoriteRowIds(favoriteRows);
      }
    }, [props.favoriteConfig]);

    const handleRowClick = useCallback((data: any) => {
      props.onRowClick?.(data);
    }, []);

    const handleCellClick = useCallback((data: any) => {
      // Prevent row click event on column having row option button. Devextreme by default do not support disable row click event on specific column.
      // As a wrokaround we are adding a specifc css class to the inteded column to ignore row click event by searcing that specific class .

      // Initially this was handled in handleRowClick method but it introduced some bugs. For this reason now we are handling this in cellClick event.
      // According to DevExtreme, OnCellClick event is fired before OnRowClick event.
      // https://js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellClick
      // So, we are checking if the cell contains that class or not here because there is no option to check this inside OnRowClick event.
      // If the cell contains that class then we will stop the propagation. Otherwise, we will let it continue its execusion.

      const shouldIgnoreClickEvent =
        data?.cellElement?.className?.includes("ignore-row-click");

      if (shouldIgnoreClickEvent) {
        data.event.stopImmediatePropagation();
      }
    }, []);

    const searchPanelConfigOptions = useMemo(() => {
      return {
        visible: toolBarOptions?.dislplaySearchPanel !== false,
        highlightSearchText: false,
      };
    }, [toolBarOptions]);

    const FavoriteColumnComponent = useCallback(
      (e: any) => {
        return (
          <PromineoGridFavoriteColumnTemplate
            data={e.data}
            favorites={favoriteRowIds}
            dataIdentifier={props.favoriteConfig?.dataIdentifier!}
            gridNameWithUserIdentifer={
              props.favoriteConfig?.gridNameWithUserIdentifer!
            }
          />
        );
      },
      [props.favoriteConfig]
    );

    const RowOptionComponent = useCallback(
      (e: any) => {
        return (
          <PromineoGridRowOptionColumnTemplate
            displayDefault={props.rowOperationConfig?.displayDefault}
            defaultOptionConfig={{
              onCopy: () =>
                props.rowOperationConfig?.defaultOptionConfig?.onCopy?.(e.data),
              onModify: () =>
                props.rowOperationConfig?.defaultOptionConfig?.onModify?.(
                  e.data
                ),
              onOpen: () =>
                props.rowOperationConfig?.defaultOptionConfig?.onOpen?.(e.data),
              hideModify:
                props.rowOperationConfig?.defaultOptionConfig?.hideModify,
              hideOpen: props.rowOperationConfig?.defaultOptionConfig?.hideOpen,
              hideCopy: props.rowOperationConfig?.defaultOptionConfig?.hideCopy,
            }}
            items={props.rowOperationConfig?.items?.map((item) => {
              return {
                onClick: () => item.onClick(e.data),
                text: item.textFn ? item.textFn(e.data) : item.text,
                visible: item.visibleFn ? item.visibleFn(e.data) : item.visible,
              };
            })}
          />
        );
      },
      [props.rowOperationConfig]
    );

    const AddNewButtonComponent = useCallback(() => {
      return (
        <AddNewButton
          onClick={toolBarOptions?.addNewButtonOptions?.onClick}
          text={toolBarOptions?.addNewButtonOptions?.text}
          id={toolBarOptions?.addNewButtonOptions?.buttonId}
          Icon={toolBarOptions?.addNewButtonOptions?.icon}
          disabled={toolBarOptions?.addNewButtonOptions?.disabled}
        />
      );
    }, [toolBarOptions?.addNewButtonOptions]);

    const AdditionalToolBarComponent = useCallback(() => {
      return props.additionalWidget ? <>{props.additionalWidget}</> : <></>;
    }, [props.additionalWidget]);

    const headerFilterConfig = useRef({ visible: true });

    const { gridOptionButtonRender } = useGridOptionButtonRenderer(
      gridRef.current
    );

    const gridIdentifierWithUserIdentity = useGridIdentifierProvider(
      props.gridIdentifier
    );

    return (
      <div className="promineo-grid-container">
        <PromineoBaseGrid
          ref={gridRef}
          searchPanel={searchPanelConfigOptions}
          headerFilter={headerFilterConfig.current}
          allowColumnResizing={true}
          repaintChangesOnly={true}
          {...rest}
          className={`promineo-grid ${props.className ?? ""}`}
          onRowClick={handleRowClick}
          onCellClick={handleCellClick}
          rowDeletoinOption={props.rowOperationConfig?.displayDeleteRowOption}
          gridIdentifier={gridIdentifierWithUserIdentity}
        >
          <Column
            cellComponent={FavoriteColumnComponent}
            cssClass={"promineo-grid-favorite-column"}
            width={50}
            minWidth={50}
            allowResizing={false}
            alignment={"center"}
            visible={props.favoriteConfig?.enabled === true}
          />
          {children}
          <Column
            cssClass={"promineo-grid-option-column ignore-row-click"}
            cellComponent={RowOptionComponent}
            alignment={"center"}
            visible={props.rowOperationConfig?.visible === true}
            width={40}
            minWidth={40}
            allowResizing={false}
            fixedPosition={true}
          ></Column>
          <Toolbar
            visible={
              toolBarOptions?.dislpayToolbar !== false &&
              (toolBarOptions?.dislplaySearchPanel === true ||
                toolBarOptions?.addNewButtonOptions?.isVisible === true ||
                toolBarOptions?.displayResetButton === true)
            }
          >
            <Item
              location={"before"}
              render={AdditionalToolBarComponent}
              visible={!!props.additionalWidget}
            />
            <Item
              location={"after"}
              visible={
                !toolBarOptions ||
                toolBarOptions?.dislplaySearchPanel !== false ||
                toolBarOptions?.displayResetButton !== false
              }
              render={gridOptionButtonRender}
            ></Item>
            <Item
              name={"searchPanel"}
              location={"after"}
              visible={
                !toolBarOptions || toolBarOptions?.dislplaySearchPanel !== false
              }
            ></Item>
            <Item
              render={AddNewButtonComponent}
              location={"after"}
              visible={toolBarOptions?.addNewButtonOptions?.isVisible === true}
            ></Item>
            {toolBarOptions?.customToolbarRenderer && (
              <Item render={toolBarOptions.customToolbarRenderer} />
            )}
          </Toolbar>
        </PromineoBaseGrid>
      </div>
    );
  }
);

export default PromineoViewGrid;
