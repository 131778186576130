import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoMultilineTextEdit from "components/common/controls/PromineoMultilineTextEdit";
import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import CrossIcon from "components/icons/CrossIcon";
import PromineoModal from "components/modal/PromineoModal";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/tab-panel";
import { ConfigFormulaTestResponse } from "interfaces/response/config-test/ConfigFormulaTestResponse";
import { DetailedFormulaTestResult } from "interfaces/response/config-test/DetailedFormulaTestResponse";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import { useCallback, useEffect, useState } from "react";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";
import { toastError } from "shared/utilities/ToastUtility";
import {
  loadConfigFormulaTestResultAsync,
  testConfigAsync,
} from "store/actions/ConfigActions";
import { useAppDispatch } from "store/hooks";

interface Props {
  config: ConfigDetailResponse;
  onClose: () => void;
}

enum TabIndex {
  TestData = 0,
  TestResult = 1,
}

export default function ConfigPreviewModal(props: Props) {
  const dispatch = useAppDispatch();

  const [testData, setTestData] = useState<string>();
  const [testResult, setTestResult] = useState<DetailedFormulaTestResult[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(TabIndex.TestResult);

  useEffect(() => {
    displayLoadingPanel();

    dispatch(loadConfigFormulaTestResultAsync(props.config.id))
      .unwrap()
      .then((response: ConfigFormulaTestResponse) => {
        const json = JSON.stringify(response.usedDto, null, 2);
        setTestData(json);
        setTestResult(response.formulaTestResults);
      })
      .finally(hideLoadingPanel);
  }, [dispatch, props.config.id]);

  const getTestDataAsJson = useCallback(() => {
    try {
      return JSON.parse(testData ?? "");
    } catch {
      toastError("Test data is not a valid JSON object.");
    }
  }, [testData]);

  const recomputeTestResultAndGoToResultTab = useCallback(() => {
    const testRequest = getTestDataAsJson();

    if (!testRequest) {
      return;
    }

    displayLoadingPanel();

    dispatch(
      testConfigAsync({ configId: props.config.id, testSchedule: testRequest })
    )
      .unwrap()
      .then((response: ConfigFormulaTestResponse) => {
        setTestResult(response.formulaTestResults);
      })
      .finally(hideLoadingPanel);

    setCurrentTab(TabIndex.TestResult);
  }, [dispatch, props.config.id, getTestDataAsJson]);

  const goToTestDataTab = () => setCurrentTab(TabIndex.TestData);

  const statusCellRender = useCallback((rowData: any) => {
    const data = rowData.data as DetailedFormulaTestResult;
    const text = data.isSuccess === true ? "Success" : "Failed";
    const css = `font-bold ${
      data.isSuccess === true ? "text-ilapGreen" : "text-red"
    }`;

    return <div className={css}>{text}</div>;
  }, []);

  const handleTestDataChange = (e: any) => {
    setTestData(e.event?.currentTarget?.value);
  };

  const getPlanningObjectTypeText = useCallback((cellInfo: any) => {
    return cellInfo?.value ? PlanningObjectTypes[cellInfo.value] : "-";
  }, []);

  return (
    <PromineoModal isVisible={true} width={1200} height={600}>
      <div className="flex justify-between mb-4">
        <div className="font-bold font-poppins">Formula result preview</div>
        <div className="cursor-pointer" onClick={props.onClose}>
          <CrossIcon />
        </div>
      </div>
      <div>
        <PromineoTabPanel
          defaultSelectedIndex={TabIndex.TestResult}
          selectedIndex={currentTab}
          onSelectedIndexChange={setCurrentTab}
        >
          <Item title={"Test schedule data"}>
            <div className="py-2">
              <PromineoMultilineTextEdit
                height={420}
                value={testData}
                onChange={handleTestDataChange}
              />
            </div>
            <div className="flex flex-row-reverse mt-2">
              <PromineoButton
                text="Test and view result"
                onClick={recomputeTestResultAndGoToResultTab}
              />
            </div>
          </Item>
          <Item title={"Result Preview"}>
            <div className="py-2">
              <PromineoViewGrid
                height={420}
                dataSource={testResult}
                gridIdentifier={"configFormulaTestResultGrid"}
              >
                <Scrolling mode="virtual" rowRenderingMode="virtual" />
                <Column dataField="formula" width={240} caption="Formula" />
                <Column
                  dataField="planningObjectType"
                  alignment={"left"}
                  width={140}
                  caption="Planning Object Type"
                  customizeText={getPlanningObjectTypeText}
                />
                <Column
                  dataField="sourceField"
                  width={140}
                  caption="Source Field"
                />
                <Column
                  dataField="destinationField"
                  width={140}
                  caption="Destination Field"
                />
                <Column dataField="result" caption="Result" />
                <Column
                  width={80}
                  dataField="isSuccess"
                  dataType="string"
                  caption="Status"
                  cellRender={statusCellRender}
                />
                <Column width={"auto"} dataField="message" caption="Message" />
              </PromineoViewGrid>
            </div>
            <div className="flex mt-2">
              <PromineoButton
                variant={PromineoButtonType.Secondary}
                text="Back to test data"
                onClick={goToTestDataTab}
              />
            </div>
          </Item>
        </PromineoTabPanel>
      </div>
    </PromineoModal>
  );
}
