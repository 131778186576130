import InformationBar from "components/common/InformationBar";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useEffect, useState } from "react";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";
import {
  CheckIfPartnerResponsibleFieldsAreFilled,
  IsSignedByOwner,
  IsSignedByPartner,
} from "shared/utilities/ExchangeAgreementUtility";
import { InactiveIeaEditorTabs } from "../inactive-ieas/InactiveIEAEditor";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserOwner: boolean;
  isCurrentUserPartner: boolean;
  selectedTab: InactiveIeaEditorTabs;
  isSender: boolean;
  isReceiver: boolean;
}

const getHeaderInformationText = (
  selectedExchangeAgreement: ExchangeAgreementDetailedResponse,
  isCurrentUserOwner: boolean,
  isCurrentUserPartner: boolean,
  selectedTab: InactiveIeaEditorTabs,
  isSender: boolean
) => {
  let headerText = "";

  if (isCurrentUserOwner && !isCurrentUserPartner) {
    if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.Complete
    ) {
      headerText = "Fill out owner configuration";
    } else if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForPartner
    ) {
      headerText = "Wait for partner to fill out partner configuration";
    } else if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForPartnerSign
    ) {
      headerText = "Wait for partner to sign IEA";
    } else if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.SignOff
    ) {
      headerText = "Sign IEA to complete IEA";
    } else {
      headerText = `IEA completed and will start at ${getFormattedDateTime(
        selectedExchangeAgreement.startDate
      )}.`;
    }
  } else if (isCurrentUserPartner && !isCurrentUserOwner) {
    if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.Complete
    ) {
      if (selectedTab === InactiveIeaEditorTabs.IeaInfo) {
        headerText =
          "Review IEA, fill out partner configuration and click next to continue.";
      } else if (selectedTab === InactiveIeaEditorTabs.HostSystemParameters) {
        headerText =
          "Adjust the host system parameters if required and submit.";
        if (isSender) {
          headerText += "Activity filter can now be applied.";
        }
      }
    } else if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForOwner
    ) {
      headerText = "Wait for owner to fill out owner configuration";
    } else if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.WaitingForOwnerSign
    ) {
      headerText = "Wait for owner to sign IEA";
    } else if (
      selectedExchangeAgreement.actionRequiredIfInactive ===
      NewExchangeAgreementActionRequired.SignOff
    ) {
      headerText = "Sign IEA to continue";
    } else {
      headerText = `IEA completed and will start at ${getFormattedDateTime(
        selectedExchangeAgreement.startDate
      )}.`;
    }
  } else if (isCurrentUserOwner && isCurrentUserPartner) {
    if (CheckIfPartnerResponsibleFieldsAreFilled(selectedExchangeAgreement)) {
      if (IsSignedByPartner(selectedExchangeAgreement)) {
        if (IsSignedByOwner(selectedExchangeAgreement)) {
          headerText = `IEA completed and will start at ${getFormattedDateTime(
            selectedExchangeAgreement.startDate
          )}.`;
        } else {
          headerText = "Wait for owner to sign IEA";
        }
      } else {
        headerText = "Wait for partner to sign IEA";
      }
    } else {
      headerText = "Fill out partner configuration";
    }
  }

  return headerText;
};

export default function IEAInformationBar(props: Props) {
  const {
    exchangeAgreement,
    isCurrentUserOwner,
    isCurrentUserPartner,
    selectedTab,
    isSender,
    isReceiver,
  } = props;

  const [headerText, setHeaderText] = useState("");

  useEffect(() => {
    if (exchangeAgreement) {
      var headerInformationText = getHeaderInformationText(
        exchangeAgreement,
        isCurrentUserOwner,
        isCurrentUserPartner,
        selectedTab,
        isSender
      );

      setHeaderText(headerInformationText);
    }
  }, [props]);

  return <InformationBar text={headerText} />;
}
