import SearchPlaceholderIcon from "components/icons/SearchPlaceholderIcon";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import TestResultPlaceHolder from "./TestResultPlaceHolder";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function WaitingForPartnerSignTestPlaceHolder(props: Props) {
  let title = "";
  if (
    (!props.exchangeAgreement.latestDataTransferTestEvent &&
      !props.exchangeAgreement.uploadTestRequired) ||
    (props.exchangeAgreement.latestDataTransferTestEvent !== undefined &&
      props.exchangeAgreement.latestDataTransferTestEvent.uploadStatus ===
        DataTransferStatus.Skipped)
  ) {
    title = "Upload test has been skipped";
  } else if (props.exchangeAgreement.latestDataTransferTestEvent) {
    if (
      props.exchangeAgreement.latestDataTransferTestEvent.uploadStatus ===
      DataTransferStatus.Success
    ) {
      title = "Upload successful";
    }
  }
  
  return (
    <TestResultPlaceHolder
      icon={<SearchPlaceholderIcon />}
      title={title}
      subtitle={"Waiting for partner sign."}
    />
  );
}
