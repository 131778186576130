import TestFailedIcon from "components/icons/TestFailedIcon";
import CreateTestButton from "features/ieas/inactive-ieas/CreateTestButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import ViewTestEventLogButton from "../ViewTestEventLogButton";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  transferType: "upload" | "download";
}

export default function TestFailedPlaceHolder(props: Props) {
  return (
    <>
      <div className="flex flex-col items-center">
        <TestResultPlaceHolder
          icon={<TestFailedIcon />}
          title="Test failed"
          subtitle={
            "Click on the button below to view the detail log and find out why the test failed. Retry the upload test or skip to sign IEA. "
          }
        />
        <div className="mt-2">
          <ViewTestEventLogButton
            exchangeAgreement={props.exchangeAgreement}
            isFromPlaceHolder={true}
            isUpload={props.transferType === "upload"}
          />
        </div>
      </div>
    </>
  );
}
