import { ITabPanelOptions } from "devextreme-react/tab-panel";
import PromineoTabPanel from "./PromineoTabPanel";
import "./styles/PromineoRoundedTabPanel.css";

interface Props {}

export default function PrmineoRoundedTabPanel(
  props: Props & ITabPanelOptions
) {
  return <PromineoTabPanel {...props} className="promineo-rounded-tabpanel" />;
}
