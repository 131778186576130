import SearchPlaceholderIcon from "components/icons/SearchPlaceholderIcon";
import CreateTestButton from "features/ieas/inactive-ieas/CreateTestButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  displayGenerateButton: boolean;
  isUpload: boolean | undefined; // If undefined, it means that the both upload and download tests are skipped
}

export default function SkippedTestPlaceHolder(props: Props) {
  return (
    <div className="flex flex-col items-center">
      <TestResultPlaceHolder
        icon={<SearchPlaceholderIcon />}
        title={
          props.isUpload === undefined
            ? "Upload and download test has been skipped"
            : `${props.isUpload ? "Upload" : "Download"} test has been skipped`
        }
        subtitle={"Sign IEA to complete IEA. "}
      />
      {props.displayGenerateButton && (
        <div className="mt-2">
          <CreateTestButton exchangeAgreementId={props.exchangeAgreement.id} />
        </div>
      )}
    </div>
  );
}
