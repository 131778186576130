import SearchPlaceholderIcon from "components/icons/SearchPlaceholderIcon";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

export default function WaitingForOwnerSignTestPlaceHolder() {
  return (
    <TestResultPlaceHolder
      icon={<SearchPlaceholderIcon />}
      title={"The IEA is signed"}
      subtitle={"Waiting for the owner to sign IEA. "}
    />
  );
}
