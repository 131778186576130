import PromineoModal from "components/modal/PromineoModal";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import PromineoEditGrid from "components/common/grid/PromineoEditGrid";
import { Column, Editing, Paging, Scrolling } from "devextreme-react/data-grid";
import { useEffect, useRef, useState } from "react";
import CopyIcon from "components/icons/CopyIcon";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import FieldValueMapResponse from "interfaces/response/FieldValueMapResponse";
import { GetNewId, deepCopyObject } from "shared/utilities/CommonUtility";
import { toastError } from "shared/utilities/ToastUtility";

interface Props {
  fieldMapping: FieldMappingResponse;
  onCancel: () => void;
  onSave: (data: FieldMappingResponse) => void;
  isPreviewChange?: boolean;
}

export default function AddValueMapsWithContentControlDialog(props: Props) {
  const { fieldMapping, onCancel, onSave } = props;
  const [currentFieldMappingResponse, setCurrentFieldMappingResponse] = useState<FieldMappingResponse>(fieldMapping);

  useEffect(() => {
    const newValueMaps: FieldValueMapResponse[] = fieldMapping.valueMaps && fieldMapping.valueMaps.length !== 0 ? deepCopyObject(fieldMapping.valueMaps) : [];
    let newValueMapAdded = false;
    if (fieldMapping.values && fieldMapping.values.length !== 0) {
      fieldMapping.values.forEach((v) => {
        let existsInValueMap = fieldMapping.valueMaps?.find((m) => m.fieldValueId === v.id);
        if(!existsInValueMap)
        {
          var valueMapToAdd: FieldValueMapResponse = {
            id: 0,
            _key_: GetNewId(),
            configFieldMappingId: fieldMapping.id,
            value: "",
            templateValue: "",
            fieldValueText: v.code,
            fieldValueDescription: v.description,
            description: "",
            configTemplateFieldValueId: v.configTemplateFieldValueId,
            fieldValueId: v.id
          };
          newValueMaps.push(valueMapToAdd);
          newValueMapAdded = true;
        }
      });
    }

    if(newValueMapAdded)
    {
      const newFieldMappingResponse: FieldMappingResponse = {
        ...fieldMapping,
        valueMaps: newValueMaps,
      };
  
      setCurrentFieldMappingResponse(newFieldMappingResponse);
    }
  }, [fieldMapping]);

  const onRowDelete = (evt: { data: FieldValueMapResponse }) => {
    const fieldValueMapWithSameTemplateValues =
      currentFieldMappingResponse.valueMaps.filter((vm) => {
        return vm.fieldValueText === evt.data.fieldValueText;
      });
    if (fieldValueMapWithSameTemplateValues.length > 1) {
      const newFieldMappingResponse: FieldMappingResponse = {
        ...currentFieldMappingResponse,
        valueMaps: [],
      };
      newFieldMappingResponse.valueMaps =
        currentFieldMappingResponse.valueMaps.filter((vm) => {
          return vm.id === 0
            ? vm._key_ !== evt.data._key_
            : vm.id !== evt.data.id;
        });
      setCurrentFieldMappingResponse(newFieldMappingResponse);
    }
  };

  const onCopyIconClick = (evt: {
    data: FieldValueMapResponse;
    rowIndex: number;
  }) => {
    // Previously, we were copying the object using JSON.stringfy and JSON.parse methods.
    // But now we have changed this to the spread operator because, if we use the previous approach with "repaintChangesOnly = true",
    // then when we add some values to the text box of the copied row and change the focus to another row
    // then for the first time only the value disappears.
    // We are already following the same approach for copying in "AddValueMapsWithNoContentControlDialog".
    const newFieldMappingResponse: FieldMappingResponse = {
      ...currentFieldMappingResponse,
      valueMaps: [...currentFieldMappingResponse.valueMaps],
    };
    const newFildValueMapResponse: FieldValueMapResponse = {
      id: 0,
      _key_: GetNewId(),
      configFieldMappingId: evt.data.configFieldMappingId,
      configTemplateFieldValueId: evt.data.configTemplateFieldValueId,
      value: "",
      templateValue: "",
      description: "",
      fieldValueText: evt.data.fieldValueText,
      fieldValueDescription: evt.data.fieldValueDescription,
      fieldValueId: evt.data.fieldValueId
    };

    newFieldMappingResponse.valueMaps.splice(
      evt.rowIndex + 1,
      0,
      newFildValueMapResponse
    );
    setCurrentFieldMappingResponse(newFieldMappingResponse);
  };

  const onSaveButtonClick = () => {
    const valueMapWithEmptyHostValueButDataIsDescription = currentFieldMappingResponse.valueMaps.filter((vm) => !vm.value && vm.description);
    if ( valueMapWithEmptyHostValueButDataIsDescription && valueMapWithEmptyHostValueButDataIsDescription.length !== 0) {
      toastError("Host value field is required with description");
    } else {
      onSave(currentFieldMappingResponse);
      onCancel();
    }
  };

  const rowHighlightingConfig = useRef({
    highlightRow: true,
  });

  return (
    currentFieldMappingResponse && (
      <PromineoModal
        isVisible={true}
        displayDefaultActions={true}
        coloredBg={true}
        width={720}
        height={640}
        editorActionButtonProps={{
          mode: PromineoModalMode.Create,
          cancelButtonCaption: "Cancel",
          createButtonCaption: "Save",
          onCancel: onCancel,
          onCreate: onSaveButtonClick,
        }}
      >
        {currentFieldMappingResponse && (
          <div>
            <div className="font-poppins font-bold leading-4 text-10px mb-20.5px">
              Value map - {currentFieldMappingResponse.name}
            </div>
            <PromineoEditGrid
              height={520}
              dataSource={currentFieldMappingResponse.valueMaps}
              rowHighlighting={rowHighlightingConfig.current}
              displayDeleteRowOption={{ visible: true, onDelete: onRowDelete }}
              gridIdentifier="addValueMapsWithContentControlGrid"
            >
              <Scrolling mode={"standard"} />
              <Paging enabled={false}/>
              <Editing mode={"cell"} allowUpdating={true} />
              <Column
                caption={`${
                  fieldMapping?.contentControlLevelText ?? ""
                } value`}
                dataField={"fieldValueText"}
                allowEditing={false}
              ></Column>
              <Column
                caption={`${
                  fieldMapping?.contentControlLevelText ?? ""
                } value  description`}
                dataField={"fieldValueDescription"}
                allowEditing={false}
              ></Column>
              <Column
                caption={"Host value"}
                dataField={"value"}
                showEditorAlways={true}
              ></Column>
              <Column
                caption={"Host value description"}
                showEditorAlways={true}
                dataField={"description"}
              ></Column>
              <Column
                width={30}
                cellRender={(data) => {
                  return (
                    <div
                      onClick={() => {
                        onCopyIconClick(data);
                      }}
                    >
                      <CopyIcon />
                    </div>
                  );
                }}
              ></Column>
            </PromineoEditGrid>
          </div>
        )}
      </PromineoModal>
    )
  );
}
