import UploadTestIcon from "components/icons/UploadTestIcon";
import CreateTestButton from "features/ieas/inactive-ieas/CreateTestButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserSender: boolean;
  isCurrentUserOwner: boolean;
}

export default function CreateTestEventPlaceHolder(props: Props) {
  const iea = props.exchangeAgreement;
  let executComponentText = "execution";
  let executComponent = ExecutionComponent.Desktop;

  if (props.isCurrentUserSender) {
    if (props.isCurrentUserOwner) {
      executComponent = iea.ownerConnector.executionComponent;
    } else {
      executComponent = iea.partnerConnector.executionComponent;
    }
  } else {
    if (props.isCurrentUserOwner) {
      executComponent = iea.partnerConnector.executionComponent;
    } else {
      executComponent = iea.ownerConnector.executionComponent;
    }
  }

  if (executComponent === ExecutionComponent.Desktop) {
    executComponentText = "desktop";
  } else if (executComponent === ExecutionComponent.Service) {
    executComponentText = "autonomous";
  }

  const title = props.isCurrentUserSender
    ? "Create upload test event"
    : "Waiting for sender";
  const subTitle = props.isCurrentUserSender
    ? `Create the Upload test event and perform the Upload test in the ${executComponentText} component. If you wish to continue without an Upload test, click “Skip test”.`
    : `Waiting for sender to create upload test event and perform the upload test in the ${executComponentText} component.`;

  return (
    <div className="flex flex-col items-center">
      <TestResultPlaceHolder
        icon={<UploadTestIcon />}
        title={title}
        subtitle={subTitle}
      />
      {props.isCurrentUserSender && (
        <div className="mt-4">
          <CreateTestButton exchangeAgreementId={props.exchangeAgreement.id} />
        </div>
      )}
    </div>
  );
}
