import PromineoModal from "components/modal/PromineoModal";
import { PromineoModalMode } from "shared/enums/PromineoModalModeEnum";
import PromineoEditGrid from "components/common/grid/PromineoEditGrid";
import { Column, Editing, Scrolling } from "devextreme-react/data-grid";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import { useState } from "react";
import FieldValueMapResponse from "interfaces/response/FieldValueMapResponse";
import PopoverMenuItem from "components/common/controls/popover-with-menu/PopoverMenuItem";
import PromineoButtonWithPopover from "components/common/controls/buttons/PromineoButtonWithPopOver";
import AddIcon from "components/icons/AddIcon";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ValueMap from "interfaces/common/ValueMap";
import { GetNewId } from "shared/utilities/CommonUtility";
import BulkValueMapInsertDialog from "features/common/bulk-value-insert-dialog/BulkValueMapInsertDialog";
import { toastError } from "shared/utilities/ToastUtility";

interface Props {
  fieldMapping: FieldMappingResponse;
  onCancel: () => void;
  onSave: (data: FieldMappingResponse) => void;
}

export default function AddValueMapsWithNoContentControlDialog(props: Props) {
  const { fieldMapping, onCancel, onSave } = props;
  const [displayBulkInsertValueMapDialog, setDisplayBulkInsertValueMapDialog] =
    useState(false);
  const [currentFieldMappingResponse, setCurrentFieldMappingResponse] =
    useState<FieldMappingResponse>(fieldMapping);

  const addValueButtonId = "add-value-map-buuton";

  const onSingleOptionClick = () => {
    const newFieldMappingResponse: FieldMappingResponse = {
      ...currentFieldMappingResponse,
      valueMaps: [...currentFieldMappingResponse.valueMaps],
    };
    const emptyValueMapResponse: FieldValueMapResponse = {
      id: 0,
      _key_: GetNewId(),
      configFieldMappingId: fieldMapping.id,
      configTemplateFieldValueId: null,
      value: "",
      templateValue: "",
      description: "",
      fieldValueText: "",
      fieldValueDescription: "",
    };
    newFieldMappingResponse.valueMaps.push(emptyValueMapResponse);
    setCurrentFieldMappingResponse(newFieldMappingResponse);
  };

  const onBulkOptionClick = () => setDisplayBulkInsertValueMapDialog(true);

  const onBulkInsertSubmit = (newValueMapsToInsert: ValueMap[]) => {
    const newValueMapList: FieldValueMapResponse[] = [
      ...currentFieldMappingResponse.valueMaps,
    ];
    newValueMapsToInsert.forEach((vm) => {
      const newValueMapResponse: FieldValueMapResponse = {
        id: vm.id ? vm.id : 0,
        _key_: vm.__KEY__,
        configFieldMappingId: fieldMapping.id,
        configTemplateFieldValueId: null,
        value: vm.hostValue,
        templateValue: vm.templateValue,
        description: vm.hostValueDescription,
        fieldValueText: "",
        fieldValueDescription: "",
      };
      newValueMapList.push(newValueMapResponse);
    });

    const newFieldMappingResponse: FieldMappingResponse = {
      ...currentFieldMappingResponse,
      valueMaps: newValueMapList,
    };
    setCurrentFieldMappingResponse(newFieldMappingResponse);
    setDisplayBulkInsertValueMapDialog(false);
  };

  const clearValueMaps = () => {
    const newFieldMappingResponse: FieldMappingResponse = {
      ...currentFieldMappingResponse,
      valueMaps: [],
    };
    setCurrentFieldMappingResponse(newFieldMappingResponse);
  };

  const onRowDelete = (evt: { data: FieldValueMapResponse }) => {
    const newFieldMappingResponse: FieldMappingResponse = {
      ...currentFieldMappingResponse,
      valueMaps: [],
    };
    newFieldMappingResponse.valueMaps =
      currentFieldMappingResponse.valueMaps.filter((vm) => {
        return vm.id === 0
          ? vm._key_ !== evt.data._key_
          : vm.id !== evt.data.id;
      });
    setCurrentFieldMappingResponse(newFieldMappingResponse);
  };

  const selectionItems: PopoverMenuItem[] = [
    {
      itemId: "1",
      text: "Single Value",
      onClick: () => onSingleOptionClick(),
    },
    {
      itemId: "2",
      text: "Update in Bulk",
      onClick: () => onBulkOptionClick(),
    },
  ];

  const onSaveClick = () => {
    let allValueMapHasTemplateValue = true;
    let allValueMapHasHostValue = true;
    currentFieldMappingResponse.valueMaps.forEach((m) => {
      allValueMapHasTemplateValue = m.templateValue
        ? allValueMapHasTemplateValue
        : false;
      allValueMapHasHostValue = m.value ? allValueMapHasHostValue : false;
    });
    let error = "";
    error = !allValueMapHasTemplateValue
      ? "Template value field is required."
      : error;
    error = !allValueMapHasHostValue
      ? `Host value field is required. ${error}`
      : error;
    if (error) {
      toastError(error);
    } else {
      onSave(currentFieldMappingResponse);
      onCancel();
    }
  };

  return (
    fieldMapping && (
      <PromineoModal
        isVisible={true}
        displayDefaultActions={true}
        coloredBg={true}
        width={720}
        height={"auto"}
        editorActionButtonProps={{
          mode: PromineoModalMode.Create,
          cancelButtonCaption: "Cancel",
          createButtonCaption: "Save",
          onCancel: onCancel,
          onCreate: onSaveClick,
        }}
      >
        {fieldMapping && (
          <div>
            <div className="font-poppins font-bold leading-4 text-10px mb-20.5px">
              Value map - {currentFieldMappingResponse.name}
            </div>
            <div className="flex justify-end mb-20.5px gap-2">
              {currentFieldMappingResponse.valueMaps.length !== 0 && (
                <PromineoButton
                  variant={PromineoButtonType.Danger}
                  onClick={clearValueMaps}
                  text="Clear"
                />
              )}
              <PromineoButtonWithPopover
                id={addValueButtonId}
                text={"Add Value"}
                Icon={<AddIcon />}
                variant={PromineoButtonType.Primary}
                menuItems={selectionItems}
              ></PromineoButtonWithPopover>
            </div>
            <PromineoEditGrid
              height={447}
              dataSource={currentFieldMappingResponse.valueMaps}
              rowHighlighting={{
                highlightRow: true,
              }}
              displayDeleteRowOption={{ visible: true, onDelete: onRowDelete }}
              gridIdentifier="addValueMapsWithNoContentControlGrid"
              noDataText="Value maps will appear here once they are added!"
            >
              <Editing mode={"cell"} allowUpdating={true} />
              <Scrolling mode="virtual" rowRenderingMode="virtual" />
              <Column
                caption={"Template value"}
                dataField={"templateValue"}
                showEditorAlways={true}
              ></Column>
              <Column
                caption={"Host value"}
                showEditorAlways={true}
                dataField={"value"}
              ></Column>
              <Column
                caption={"Comment"}
                showEditorAlways={true}
                dataField={"description"}
              ></Column>
            </PromineoEditGrid>
            {displayBulkInsertValueMapDialog && (
              <BulkValueMapInsertDialog
                onCancel={() => {
                  setDisplayBulkInsertValueMapDialog(false);
                }}
                onSubmit={onBulkInsertSubmit}
              />
            )}
          </div>
        )}
      </PromineoModal>
    )
  );
}
