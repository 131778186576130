
export default function UpArrowIcon() {
  return (
    <svg
      width="11"
      height="22"
      viewBox="0 0 11 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3 0.71875L0.113249 5.71875H5.88675L3 0.71875ZM3 20.6016H2.5V21.1016H3V20.6016ZM2.5 5.21875V20.6016H3.5V5.21875H2.5ZM3 21.1016H11V20.1016H3V21.1016Z"
        fill="#434343"
        style={{
          fill: "#434343",
          fillOpacity: 1,
        }}
      />
    </svg>
  );
}
