import { FormulaTestRequest } from "interfaces/request/FormulaTestRequest";
import { FormulaTestResponse } from "interfaces/response/config-test/FormulaTestResponse";
import { postDataAsync } from "./API";

export const sendFormulaTestRequestAsync = async (
  request: FormulaTestRequest
) => {
  let apiUrl = "/Formula/Test";
  let response = await postDataAsync<FormulaTestRequest, FormulaTestResponse>(
    apiUrl, request
  );
  return response;
};