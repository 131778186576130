import useIEATestEventDataProviderHook from "hooks/IEATestEventDataProviderHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useState } from "react";
import { IEATestFilterButtonsId } from "shared/constants/DivIdConstants";
import DownloadTestPlaceholderRenderer from "./DownloadTestPlaceholderRenderer";
import FilterButtons, { FilterSelectionType } from "./FilterButtons";
import TestComparisonPlaceHolder from "./TestComparisonPlaceHolder";
import UploadTestPlaceholderRenderer from "./UploadTestPlaceholderRenderer";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserSender: boolean;
  isCurrentUserOwner: boolean;
  gridHeight: number;
}

export default function EmptyTestResultPlaceholder(props: Props) {
  const [selectedFilter, setSelectedFilter] = useState<FilterSelectionType>(
    FilterSelectionType.UploadTest
  );

  const handleFilterSelectionChange = (filter: FilterSelectionType) => {
    setSelectedFilter(filter);
  };

  const testResult = useIEATestEventDataProviderHook(props.exchangeAgreement);

  const classesForResult = "h-full w-full rounded bg-transparent";
  const classesForNoResult =
    "h-full w-full rounded bg-white flex items-center justify-center";

  return (
    <div>
      <div className="mb-4" id={IEATestFilterButtonsId}>
        <FilterButtons onChange={handleFilterSelectionChange} />
      </div>
      <div
        style={{ height: `${props.gridHeight}px` }}
        className="h-full w-full"
      >
        {selectedFilter === FilterSelectionType.UploadTest && (
          <div
            className={
              testResult.uploadResult ? classesForResult : classesForNoResult
            }
          >
            <UploadTestPlaceholderRenderer {...props} testResult={testResult} />
          </div>
        )}
        {selectedFilter === FilterSelectionType.TestComparison && (
          <div
            className={
              testResult.comparisonResult
                ? classesForResult
                : classesForNoResult
            }
          >
            <TestComparisonPlaceHolder {...props} testResult={testResult} />
          </div>
        )}
        {selectedFilter === FilterSelectionType.DownloadTest && (
          <div
            className={
              testResult.downloadResult ? classesForResult : classesForNoResult
            }
          >
            <DownloadTestPlaceholderRenderer
              {...props}
              testResult={testResult}
            />
          </div>
        )}
      </div>
    </div>
  );
}
