import FilterTile from "components/common/FilterTile";
import EditIcon from "components/icons/EditIcon";
import RefreshIcon from "components/icons/RefreshIcon";
import SuccessIcon from "components/icons/SuccessIcon";
import WrenchIcon from "components/icons/WrenchIcon";
import { ConfigGridSummaryFilterOptions } from "shared/enums/feature/ConfigGridSummaryFilterOptions";

export interface ConfigGridSummaryProps {
  total: number;
  updateAvailable: number;
  beingEdited: number;
  beingCreated: number;
  published: number;
  selectedFilter: ConfigGridSummaryFilterOptions;
  onFilterChange: (selectedFilter: ConfigGridSummaryFilterOptions) => void;
}

export default function ConfigGridSummary(props: ConfigGridSummaryProps) {
  const handleFilterChange = (filter: ConfigGridSummaryFilterOptions) => {
    props.onFilterChange(filter);
  };

  return (
    <div className="flex space-x-2">
      <FilterTile
        value={props.total}
        label="All configs"
        isSelected={props.selectedFilter === ConfigGridSummaryFilterOptions.All}
        onClick={() =>
          handleFilterChange(ConfigGridSummaryFilterOptions.All)
        }
      />
      <FilterTile
        value={props.updateAvailable}
        label="Update available"
        isSelected={
          props.selectedFilter ===
          ConfigGridSummaryFilterOptions.UpdateAvailable
        }
        iconComponent={<RefreshIcon />}
        onClick={() =>
          handleFilterChange(ConfigGridSummaryFilterOptions.UpdateAvailable)
        }
      />
      <FilterTile
        value={props.beingEdited}
        label="Being edited"
        isSelected={
          props.selectedFilter === ConfigGridSummaryFilterOptions.BeingEdited
        }
        iconComponent={<EditIcon />}
        onClick={() =>
          handleFilterChange(ConfigGridSummaryFilterOptions.BeingEdited)
        }
      />
      <FilterTile
        value={props.beingCreated}
        label="Being created"
        isSelected={
          props.selectedFilter === ConfigGridSummaryFilterOptions.BeingCreated
        }
        iconComponent={<WrenchIcon />}
        onClick={() =>
          handleFilterChange(ConfigGridSummaryFilterOptions.BeingCreated)
        }
      />
      <FilterTile
        value={props.published}
        label="Published"
        isSelected={
          props.selectedFilter === ConfigGridSummaryFilterOptions.Published
        }
        iconComponent={<SuccessIcon />}
        onClick={() =>
          handleFilterChange(ConfigGridSummaryFilterOptions.Published)
        }
      />
    </div>
  );
}
