import PromineoButtonWithIcon from "components/common/controls/buttons/PromineoButtonWithIcon";
import ReloadIcon from "components/icons/ReloadIcon";
import UploadRunningIcon from "components/icons/UploadRunningIcon";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useNavigate } from "react-router-dom";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserSender: boolean;
  isCurrentUserOwner: boolean;
  transferType: "Upload" | "Download";
}

export default function RunningTestEventPlaceHolder(props: Props) {
  const navigate = useNavigate();
  const iea = props.exchangeAgreement;

  let executComponentText = "execution";
  let executComponent = ExecutionComponent.Desktop;

  if (props.isCurrentUserSender) {
    if (props.isCurrentUserOwner) {
      executComponent = iea.ownerConnector.executionComponent;
    } else {
      executComponent = iea.partnerConnector.executionComponent;
    }
  } else {
    if (props.isCurrentUserOwner) {
      executComponent = iea.partnerConnector.executionComponent;
    } else {
      executComponent = iea.ownerConnector.executionComponent;
    }
  }

  if (executComponent === ExecutionComponent.Desktop) {
    executComponentText = "desktop";
  } else if (executComponent === ExecutionComponent.Service) {
    executComponentText = "autonomous";
  }

  const handleReload = () => {
    navigate(0);
  };

  return (
    <div>
      <TestResultPlaceHolder
        icon={<UploadRunningIcon />}
        title={`Test ${props.transferType} is ongoing`}
        subtitle={`${props.transferType} is running in ${
          props.transferType === "Download" ? "receiver's" : "sender's"
        } ${executComponentText} component.`}
      />
      <div className="mt-3 flex justify-center">
        <PromineoButtonWithIcon
          Icon={<ReloadIcon />}
          text="Reload page"
          onClick={handleReload}
        />
      </div>
    </div>
  );
}
