import AddIcon from "components/icons/AddIcon";
import { Column, Paging, Scrolling } from "devextreme-react/data-grid";
import { useCallback, useRef } from "react";
import PromineoDropdownBox, {
  DropdownOptions,
} from "components/common/dropdown-box/PromineoDropdownBox";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import FieldResponse from "interfaces/response/FieldResponse";
import { IDropDownBoxOptions } from "devextreme-react/drop-down-box";

interface Props {
  dataSource: FieldResponse[];
  dropdownOptions: DropdownOptions & IDropDownBoxOptions;
  onAdd: (data: any) => void;
  alreadyAddedFieldIds: number[];
}

export default function FieldSelectionDropdown(props: Props) {
  const { dropdownOptions } = props;

  const AddComponent = useCallback(
    (e: any) => {
      const isAlreadyAdded = () => {
        return props.alreadyAddedFieldIds.includes(e.data.id);
      };

      return isAlreadyAdded() ? (
        <></>
      ) : (
        <div
          onClick={() => props.onAdd(e.data)}
          className="flex justify-center cursor-pointer"
        >
          <AddIcon fillColor="black" />
        </div>
      );
    },
    [props.dataSource, props.alreadyAddedFieldIds]
  );

  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const getGridView = useCallback(() => {
    return (
      <div className="py-2 px-4">
        <PromineoViewGrid
          dataSource={props.dataSource}
          height={350}
          toolbarConfig={toolbarConfig.current}
          gridIdentifier="fieldSelectionGrid"
        >
          <Scrolling mode={"standard"} />
          <Paging enabled={false}/>
          <Column width={30} alignment={"center"} cellRender={AddComponent} />
          <Column
            dataField={"planningObjectTypesText"}
            caption="Planning Object Type"
            alignment={"left"}
            width={180}
          />
          <Column dataField={"planningObjectTypes"} visible={false} />
          <Column dataField={"name"} caption="Field Name" />
          <Column dataField={"dataTypeText"} caption="Data Type" width={120} />
          <Column dataField={"dataType"} visible={false}/>
          <Column
            dataField={"contentControlText"}
            caption="Content Control"
            width={140}
          />
          <Column dataField={"contentControl"} visible={false} />
        </PromineoViewGrid>
      </div>
    );
  }, [props.dataSource, AddComponent]);

  const dropdownOption = useRef({
    width: 750,
    height: 380,
  });

  return (
    <>
      <PromineoDropdownBox
        placeholder="Select or Search..."
        {...dropdownOptions}
        width={240}
        dropDownOptions={dropdownOption.current}
        dataSource={props.dataSource}
        className={`promineo-tree-view-dropdown w-full`}
        contentRender={getGridView}
      />
    </>
  );
}
