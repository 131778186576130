import { createSlice } from "@reduxjs/toolkit";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import ArchiveExchangeAgreementListingResponse from "interfaces/response/ArchiveExchangeAgreementListingResponse";
import { ExchangeAgreementConfigUpdatePreviewResponse } from "interfaces/response/ExchangeAgreementConfigUpdatePreviewResponse";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import ExchangeAgreementFieldMappingDetailResponse from "interfaces/response/ExchangeAgreementFieldMappingDetailResponse";
import ExchangeAgreementSimplifiedResponse from "interfaces/response/ExchangeAgreementSimplifiedResponse";
import InactiveExchangeAgreementListingResponse from "interfaces/response/NewExchangeAgreementListingResponse";
import DataTransferTestResultResponse from "interfaces/response/planning-objects/DataTransferTestResultResponse";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import {
  addTestEventAsync,
  duplicateExchangeAgreementAsync,
  loadActiveExchangeAgreementsAsync,
  loadActiveSimplifiedExchangeAgreementsAsync,
  loadArchiveExchangeAgreementsAsync,
  loadConfigChangesPreviewAsync,
  loadExchangeAgreementDetailedMappingsAsync,
  loadExchangeAgreementDetailsAsync,
  loadNewExchangeAgreementsAsync,
  loadTestResultAsync,
  ModifyExchangeAgreementConfigUpdateAcceptanceStatusAsync,
  pauseActiveExchangeAgreementAsync,
  removeInactiveExchangeAgreementAsync,
  republishExchangeAgreementForConfigChangeAsync,
  resumeActiveExchangeAgreementAsync,
  signExchangeAgreementOnBehalfOfOwnerAsync,
  signExchangeAgreementOnBehalfOfPartnerAsync,
  updateDownloadTestEventStatusAsSkippedAsync,
  updateExistingExchangeAgreementAsync,
  updateExistingExchangeAgreementForPartnerAsync,
  updateUploadTestEventStatusAsSkippedAsync,
} from "store/actions/ExchangeAgreementActions";

export interface IEAState {
  activeExchangeAgreements: ActiveExchangeAgreementListingResponse[];
  newExchangeAgreements: InactiveExchangeAgreementListingResponse[];
  archiveExchangeAgreements: ArchiveExchangeAgreementListingResponse[];
  selectedExchangeAgreement: ExchangeAgreementDetailedResponse | null;
  activeExchangeAgreementsSimplified: ExchangeAgreementSimplifiedResponse[];
  configChangesPreview: ExchangeAgreementConfigUpdatePreviewResponse | null;
  testResult: DataTransferTestResultResponse | null;
  exchangeAgreementMappingDetail: ExchangeAgreementFieldMappingDetailResponse | null;
}

const initialState: IEAState = {
  activeExchangeAgreements: [],
  newExchangeAgreements: [],
  archiveExchangeAgreements: [],
  selectedExchangeAgreement: null,
  activeExchangeAgreementsSimplified: [],
  configChangesPreview: null,
  testResult: null,
  exchangeAgreementMappingDetail: null,
};

export const exchangeAgreementSlice = createSlice({
  name: "ExchangeAgreements",
  initialState,
  reducers: {
    resetSelectedExchangeAgreement: (state) => {
      state.selectedExchangeAgreement = null;
    },
    resetDataTransferConflictStatus: (state, action) => {
      state.activeExchangeAgreements = state.activeExchangeAgreements.map(
        (ea) => {
          if (ea.id === action.payload) {
            ea.isLastDownloadFailedDueToConflicts = false;
          }
          return ea;
        }
      );
    },
    resetConfigChangesPreview: (state) => {
      state.configChangesPreview = null;
    },
    resetTestResults: (state) => {
      state.testResult = null;
    },
    resetExchangeAgreementMappingDetail: (state) => {
      state.exchangeAgreementMappingDetail = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      loadActiveExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.activeExchangeAgreements = action.payload;
      }
    );
    builder.addCase(
      loadNewExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.newExchangeAgreements = action.payload;
      }
    );
    builder.addCase(
      loadArchiveExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.archiveExchangeAgreements = action.payload;
      }
    );
    builder.addCase(loadExchangeAgreementDetailsAsync.pending, (state) => {
      state.selectedExchangeAgreement = null;
    });
    builder.addCase(
      loadExchangeAgreementDetailsAsync.fulfilled,
      (state, action) => {
        state.selectedExchangeAgreement = action.payload;
      }
    );
    builder.addCase(
      loadActiveSimplifiedExchangeAgreementsAsync.fulfilled,
      (state, action) => {
        state.activeExchangeAgreementsSimplified = action.payload;
      }
    );

    builder.addCase(
      updateExistingExchangeAgreementForPartnerAsync.fulfilled,
      (state, action) => {
        state.selectedExchangeAgreement = action.payload;
      }
    );

    builder.addCase(
      duplicateExchangeAgreementAsync.fulfilled,
      (state, action) => {
        state.selectedExchangeAgreement = action.payload;
      }
    );

    builder.addCase(
      updateExistingExchangeAgreementAsync.fulfilled,
      (state, action) => {
        state.selectedExchangeAgreement = action.payload;
      }
    );

    builder.addCase(
      signExchangeAgreementOnBehalfOfPartnerAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          state.selectedExchangeAgreement.partnerSigner =
            action.payload.partnerSigner;
          state.selectedExchangeAgreement.partnerSignatureTime =
            action.payload.partnerSignatureTime;
        }
      }
    );

    builder.addCase(
      loadExchangeAgreementDetailedMappingsAsync.fulfilled,
      (state, action) => {
        state.exchangeAgreementMappingDetail = action.payload;
      }
    );

    builder.addCase(
      signExchangeAgreementOnBehalfOfOwnerAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          state.selectedExchangeAgreement.ownerSigner =
            action.payload.ownerSigner;
          state.selectedExchangeAgreement.ownerSignatureTime =
            action.payload.ownerSignatureTime;
        }
      }
    );

    builder.addCase(pauseActiveExchangeAgreementAsync.fulfilled, (state) => {
      if (state.selectedExchangeAgreement) {
        state.selectedExchangeAgreement.isPaused = true;
      }
    });

    builder.addCase(resumeActiveExchangeAgreementAsync.fulfilled, (state) => {
      if (state.selectedExchangeAgreement) {
        state.selectedExchangeAgreement.isPaused = false;
      }
    });

    builder.addCase(removeInactiveExchangeAgreementAsync.fulfilled, (state) => {
      if (state.selectedExchangeAgreement) {
        state.selectedExchangeAgreement = null;
      }
    });

    builder.addCase(
      ModifyExchangeAgreementConfigUpdateAcceptanceStatusAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          state.selectedExchangeAgreement.isUpdatePerformedByOwner =
            action.meta.arg.updateRequest.isUpdatePerformedByOwner;
        }
      }
    );

    builder.addCase(
      loadConfigChangesPreviewAsync.fulfilled,
      (state, action) => {
        state.configChangesPreview = action.payload;
      }
    );

    builder.addCase(
      republishExchangeAgreementForConfigChangeAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          state.selectedExchangeAgreement = {
            ...state.selectedExchangeAgreement,
            ...action.payload,
            updateRequired: false,
          };
        }
      }
    );

    builder.addCase(addTestEventAsync.fulfilled, (state, action) => {
      if (state.selectedExchangeAgreement) {
        const exchangeAgreementId = action.meta.arg;
        if (state.selectedExchangeAgreement.id === exchangeAgreementId) {
          state.selectedExchangeAgreement.latestDataTransferTestEvent =
            action.payload;
          state.selectedExchangeAgreement.uploadTestRequired = true;
          state.selectedExchangeAgreement.actionRequiredIfInactive =
            NewExchangeAgreementActionRequired.PerformUploadTest;
          state.selectedExchangeAgreement.actionRequiredIfInactiveText =
            "Perform Upload Test";
        }
      }
    });

    builder.addCase(loadTestResultAsync.fulfilled, (state, action) => {
      state.testResult = action.payload;
    });

    builder.addCase(
      updateUploadTestEventStatusAsSkippedAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          const exchangeAgreementId = action.meta.arg.exchangeAgreementId;
          const isCurrentUserPartner = action.meta.arg.isCurrentUserPartner;

          if (state.selectedExchangeAgreement.id === exchangeAgreementId) {
            state.selectedExchangeAgreement.uploadTestRequired = false;
            state.selectedExchangeAgreement.downloadTestRequired = false;
            if (isCurrentUserPartner) {
              state.selectedExchangeAgreement.actionRequiredIfInactive =
                NewExchangeAgreementActionRequired.SignOff;
              state.selectedExchangeAgreement.actionRequiredIfInactiveText =
                "SignOff";
            } else {
              state.selectedExchangeAgreement.actionRequiredIfInactive =
                NewExchangeAgreementActionRequired.WaitingForPartnerSign;
              state.selectedExchangeAgreement.actionRequiredIfInactiveText =
                "Waiting For Partner Sign";
            }
            if (state.selectedExchangeAgreement.latestDataTransferTestEvent) {
              state.selectedExchangeAgreement.latestDataTransferTestEvent.uploadStatus =
                DataTransferStatus.Skipped;
              state.selectedExchangeAgreement.latestDataTransferTestEvent.downloadStatus =
                DataTransferStatus.Skipped;
            }
          }
        }
      }
    );

    builder.addCase(
      updateDownloadTestEventStatusAsSkippedAsync.fulfilled,
      (state, action) => {
        if (state.selectedExchangeAgreement) {
          const exchangeAgreementId = action.meta.arg.exchangeAgreementId;
          const isCurrentUserPartner = action.meta.arg.isCurrentUserPartner;
          const isCurrentUserOwner = action.meta.arg.isCurrentUserOwner;

          if (state.selectedExchangeAgreement.id === exchangeAgreementId) {
            state.selectedExchangeAgreement.downloadTestRequired = false;
            if (isCurrentUserPartner) {
              state.selectedExchangeAgreement.actionRequiredIfInactive =
                NewExchangeAgreementActionRequired.SignOff;
              state.selectedExchangeAgreement.actionRequiredIfInactiveText =
                "SignOff";
            } else {
              if (
                state.selectedExchangeAgreement.partnerSigner ||
                isCurrentUserOwner
              ) {
                state.selectedExchangeAgreement.actionRequiredIfInactive =
                  NewExchangeAgreementActionRequired.SignOff;
                state.selectedExchangeAgreement.actionRequiredIfInactiveText =
                  "SignOff";
              } else {
                state.selectedExchangeAgreement.actionRequiredIfInactive =
                  NewExchangeAgreementActionRequired.WaitingForPartnerSign;
                state.selectedExchangeAgreement.actionRequiredIfInactiveText =
                  "Waiting For Partner Sign";
              }
            }
            if (state.selectedExchangeAgreement.latestDataTransferTestEvent) {
              state.selectedExchangeAgreement.latestDataTransferTestEvent.downloadStatus =
                DataTransferStatus.Skipped;
            }
          }
        }
      }
    );
  },
});

export const {
  resetSelectedExchangeAgreement,
  resetDataTransferConflictStatus,
  resetConfigChangesPreview,
  resetTestResults,
} = exchangeAgreementSlice.actions;

export default exchangeAgreementSlice.reducer;
