import SearchPlaceholderIcon from "components/icons/SearchPlaceholderIcon";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export default function SignedIeaPlaceholder(props: Props) {
  let subTitle = "Waiting for the owner to sign IEA.";
  if (props.exchangeAgreement.ownerSigner) {
    subTitle = "Signed by both owner and partner";
  }

  return (
    <TestResultPlaceHolder
      icon={<SearchPlaceholderIcon />}
      title="The IEA is signed"
      subtitle={subTitle}
    />
  );
}
