import { PromineoButtonType } from "components/common/controls/buttons/PromineoButton";
import PromineoButtonWithIcon from "components/common/controls/buttons/PromineoButtonWithIcon";
interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export default function ExportToXlsxButton(props: Props) {
  const Icon = () => {
    return <i className="icon dx-icon-exportxlsx text-lg"></i>;
  };
  return (
    <PromineoButtonWithIcon
      variant={PromineoButtonType.Secondary}
      text="Export to Excel"
      onClick={props.onClick}
      disabled={props.disabled}
    />
  );
}
