import DirectMappingEditor from "features/setup/configs/config-edit/direct-mapping/DirectMappingEditor";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import { DirectionEnum } from "shared/enums/feature/DirectionEnum";

interface Props {
  ownerConfigMapping: FieldMappingResponse[];
  partnerConfigMapping: FieldMappingResponse[];
  exchangeAgreementDirection: DirectionEnum;
}

export default function ConfigUpdateMappingPreview(props: Props) {
  return (
    <div>
      <div>
        <div className="mb-1 font-bold font-poppins">Sender</div>
        <DirectMappingEditor
          isEdit={false}
          fieldMappings={
            props.exchangeAgreementDirection === DirectionEnum.OwnerToPartner
              ? props.ownerConfigMapping
              : props.partnerConfigMapping
          }
          height={190}
          displaySearch={false}
          gridIdentifier="senderConfigUpdateDirectMappingPreview"
        />
      </div>
      <div className="mt-3">
        <div className="mb-1 font-bold font-poppins">Receiver</div>
        <DirectMappingEditor
          isEdit={false}
          fieldMappings={
            props.exchangeAgreementDirection === DirectionEnum.OwnerToPartner
              ? props.partnerConfigMapping
              : props.ownerConfigMapping
          }
          height={190}
          displaySearch={false}
          gridIdentifier="receiverConfigUpdateDirectMappingPreview"
        />
      </div>
    </div>
  );
}
