import BorderComponent from "components/common/BorderComponent";
import { PropertyDefinition } from "interfaces/response/config-test/PropertyDefinition";
import PropertyDefinitionViewer from "./PropertyDefinitionViewer";

interface Props {
  name: string;
  propertyDefinitions: PropertyDefinition[];
}

export default function PropertyDefinitionsViewer(props: Props) {
  const { name, propertyDefinitions } = props;

  return (
    <div>
      <div className="font-bold">{name}</div>
      <BorderComponent customClass="my-1" />
      {propertyDefinitions.map((propertyDefinition, index) => (
        <div key={index}>
          <PropertyDefinitionViewer propertyDefinition={propertyDefinition} />
        </div>
      ))}
    </div>
  );
}
