import PromineoDropdownBox from "components/common/dropdown-box/PromineoDropdownBox";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import { ActivityFilter } from "interfaces/common/ActivityFilter";
import { useCallback, useRef, useState } from "react";

interface Props {
  dataSource: ActivityFilter[];
  onActivityFilterSelection: (activityFilter: string) => void;
  placeHolder?: string;
}

export default function FilterExpressionPickerDropdown(props: Props) {
  const { dataSource, onActivityFilterSelection, placeHolder } = props;
  const [isOpened, setIsOpened] = useState<boolean>(false);

  const handleRowClick = (evt: any) => {
    onActivityFilterSelection(evt.data.example);
    setIsOpened(false);
  };

  const handleOpenedChanged = (value: boolean) => {
    setIsOpened(value);
  };

  const getGridView = useCallback(() => {
    return (
      <PromineoViewGrid
        dataSource={dataSource}
        height={288}
        width={700}
        showColumnHeaders={false}
        onRowClick={handleRowClick}
        gridIdentifier="filterExpressionPickerGrid"
      >
        <Scrolling mode="virtual" rowRenderingMode="virtual" />
        <Column dataField={"title"} caption="Title" width={250} />
        <Column dataField={"example"} caption="Example" />
      </PromineoViewGrid>
    );
  }, [dataSource]);

  const dropdownOptions = useRef({
    width: 700,
    maxHeight: 380,
    height: "auto",
  });

  return (
    <>
      <PromineoDropdownBox
        placeholder={placeHolder ? placeHolder : "Select an example filter"}
        {...dropdownOptions}
        width={240}
        dropDownOptions={dropdownOptions.current}
        dataSource={dataSource}
        className={`w-full`}
        contentComponent={getGridView}
        onOpenedChange={handleOpenedChanged}
        opened={isOpened}
      />
    </>
  );
}
