import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import ExchangeAgreementIdTitleResponseDto from "interfaces/response/ExchangeAgreementIdTitleResponseDto";
import { useEffect, useState } from "react";
import { LoadingStatus } from "shared/enums/LoadingStatus";
import { toastError } from "shared/utilities/ToastUtility";
import { loadLinkedExchangeAgreementsWithConfigAndSchedulesAsync } from "store/actions/ConfigActions";
import { useAppDispatch } from "store/hooks";

interface UseLinkedExchangeAgreementsResult {
  linkedExchangeAgreementsWithSchedules: Record<
    number,
    ExchangeAgreementIdTitleResponseDto[]
  >;
  loadingStatus: LoadingStatus;
}

interface Props {
  configId: number;
  scheduleIdsToLookup?: number[];
}

export default function useLinkedExchangeAgreementsToConfigAndSchedules(
  props: Props
): UseLinkedExchangeAgreementsResult {
  const { configId, scheduleIdsToLookup } = props;
  const dispatch = useAppDispatch();
  const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>(LoadingStatus.Pending);

  const [
    linkedExchangeAgreementsWithSchedules,
    setLinkedExchangeAgreementsWithSchedules,
  ] = useState<Record<number, ExchangeAgreementIdTitleResponseDto[]>>([]);

  useEffect(() => {
    if (configId) {
      displayLoadingPanel();
      setLoadingStatus(LoadingStatus.Loading);
      dispatch(
        loadLinkedExchangeAgreementsWithConfigAndSchedulesAsync({
          configId,
          scheduleIdsToLookup: scheduleIdsToLookup ?? [],
        })
      )
        .unwrap()
        .then((response) => {
          setLinkedExchangeAgreementsWithSchedules(response);
          setLoadingStatus(LoadingStatus.Success);
        })
        .catch((error) => {
          toastError(error.message);
          setLoadingStatus(LoadingStatus.Failed);
        })
        .finally(() => {
          hideLoadingPanel();
        });
    }
  }, [dispatch, configId, scheduleIdsToLookup]);

  return {
    linkedExchangeAgreementsWithSchedules:
      linkedExchangeAgreementsWithSchedules,
    loadingStatus,
  };
}
