import PromineoSwitchButton from "components/common/controls/buttons/PromineoSwitchButton";
import LabelWithContent from "components/common/LabelWithContent";
import TextViewerComponent from "features/setup/configs/host-system/safran/TextViewerComponent";
import { useState } from "react";
import { EditorMode } from "shared/enums/EditorMode";
import { SwitchButtonType } from "shared/enums/SwitchButtonType";
import BaseHostSystemComponentProps from "../BaseHostSystemComponentProps";

interface Props extends BaseHostSystemComponentProps {
  useSNETWhenNoPredecessorsValue: boolean;
}

export default function UseSNETWhenNoPredecessorsField(props: Props) {
  const displayText = props.useSNETWhenNoPredecessorsValue ? "On" : "Off";
  const {
    mode,
    useSNETWhenNoPredecessorsValue,
    fieldName,
    handleConfigurationValueChanged,
  } = props;

  const [value, setValue] = useState<boolean>(useSNETWhenNoPredecessorsValue);

  const handleChange = () => {
    setValue(!value);
    handleConfigurationValueChanged?.(fieldName, !value);
  };

  return (
    <LabelWithContent
      label="Use SNET When 'No Predecessors'"
      tooltipText={`SNET = "Start No Earlier Than constraint"`}
      content={
        <div>
          {mode === EditorMode.Edit && (
            <PromineoSwitchButton
              checked={useSNETWhenNoPredecessorsValue}
              switchType={SwitchButtonType.Default}
              onChange={handleChange}
            />
          )}

          {mode === EditorMode.View && (
            <TextViewerComponent text={displayText} />
          )}
        </div>
      }
    />
  );
}
