import DataGrid, {
  Column,
  IDataGridOptions,
  LoadPanel,
  StateStoring,
} from "devextreme-react/data-grid";
import React, { useCallback, useMemo } from "react";
import CrossButton from "../controls/buttons/CrossButton";
import useGridStateStoreHook, { GridStateStoreHookProps } from "./GridStateStoreHook";
import "./styles/PromineoBaseGrid.css";

export interface RowDeletionOption {
  visible?: boolean;
  canDeleteRowFn?: (data: any) => boolean;
  onDelete: (data: any) => void;
}

interface Props {
  refference?: React.LegacyRef<DataGrid<any, any>>;
  rowDeletoinOption?: RowDeletionOption;
  storeState?: {
    enabled: boolean;
    storeFilters?: boolean;
  };
  gridIdentifier: string;
}

function PromineoBaseGrid(gridOptions: Props & IDataGridOptions) {
  const { className, refference, storeState, ...rest } = gridOptions;

  const DeleteButtonComponent = useCallback(
    (data: any) => {
      var shouldDisplayDeleteIcon =
        gridOptions.rowDeletoinOption?.canDeleteRowFn?.(data) ?? true;

      return shouldDisplayDeleteIcon ? (
        <>
          <div>
            <CrossButton
              classNames={`delete-row-button w-18px`}
              onDeleteClick={() =>
                gridOptions.rowDeletoinOption?.onDelete(data)
              }
            />
          </div>
        </>
      ) : (
        <></>
      );
    },
    [gridOptions.rowDeletoinOption]
  );

  const gridStateHookProps = useMemo<GridStateStoreHookProps>(() => {
    return {
      gridIdentifier: gridOptions.gridIdentifier,
      storeFilters: storeState?.storeFilters ?? true,
    };
  }, [gridOptions.gridIdentifier, storeState]);

  const { loadState, saveState } = useGridStateStoreHook(gridStateHookProps);

  return (
    <DataGrid
      ref={refference}
      {...rest}
      showBorders={false}
      showColumnLines={false}
      showRowLines={false}
      className={`promineo-base-grid ${className}`}
      noDataText={gridOptions.noDataText ?? ""}
    >
      <LoadPanel enabled={false} />
      {gridOptions.children}
      <Column
        cssClass={"grid-row-delete-option ignore-row-click"}
        visible={gridOptions.rowDeletoinOption?.visible === true}
        width={35}
        minWidth={35}
        alignment="left"
        cellRender={DeleteButtonComponent}
      ></Column>
      <StateStoring
        enabled={storeState?.enabled ?? true}
        type={"custom"}
        customLoad={loadState}
        customSave={saveState}
        savingTimeout={500}
      />
    </DataGrid>
  );
}

export default React.forwardRef(
  (
    props: IDataGridOptions & Props,
    ref: React.LegacyRef<DataGrid<any, any>>
  ) => {
    return <PromineoBaseGrid {...props} refference={ref} />;
  }
);
