import PromineoDatepicker from "components/common/controls/PromineoDatePicker";
import PromineoSelectBox from "components/common/controls/PromineoSelectBox";
import PromineoTextBox from "components/common/controls/PromineoTextBox";
import { useCallback, useMemo } from "react";
import { PromineoDataType } from "shared/enums/PromineoDataType";
import "./styles/FormulaTester.css";

export interface FormulaVariable {
  name: string;
  value: any;
  type: PromineoDataType;
}

interface Props {
  variable: FormulaVariable;
  onValueChange: (value: any) => void;
  onTypeChange: (type: PromineoDataType) => void;
}

const dataTypes = [
  {
    name: "String",
    value: PromineoDataType.String,
  },
  {
    name: "Double",
    value: PromineoDataType.Double,
  },
  {
    name: "Boolean",
    value: PromineoDataType.Boolean,
  },
  {
    name: "DateTime",
    value: PromineoDataType.DateTime,
  },
];

const booleanDataSource = [
  { name: "True", value: true },
  { name: "False", value: false },
];

const inputHeight: number = 30;
const dropdownWrapperClass: string = "formula-tester";

export default function FormulaVariableEditor(props: Props) {
  const { variable } = props;

  const handleValueChange = useCallback(
    (e: any) => {
      const value = e.event?.currentTarget?.value;
      props.onValueChange(value);
    },
    [props]
  );

  const handleNumberChange = useCallback(
    (e: any) => {
      const value = e.event?.currentTarget?.value;

      if (isNaN(Number(value))) {
        return;
      }

      props.onValueChange(value);
    },
    [props]
  );

  const variableEditor = useMemo(() => {
    if (variable.type === PromineoDataType.String) {
      return (
        <PromineoTextBox
          height={inputHeight}
          width={"auto"}
          value={variable.value}
          onChange={handleValueChange}
        />
      );
    }

    if (variable.type === PromineoDataType.Double) {
      return (
        <PromineoTextBox
          height={inputHeight}
          width={"auto"}
          value={variable.value}
          onInput={handleNumberChange}
        />
      );
    }

    if (variable.type === PromineoDataType.Boolean) {
      return (
        <PromineoSelectBox
          dataSource={booleanDataSource}
          width={"auto"}
          height={inputHeight}
          value={variable.value}
          displayExpr={"name"}
          valueExpr={"value"}
          onValueChange={props.onValueChange}
          dropdownWrapperClass={dropdownWrapperClass}
        />
      );
    }

    if (variable.type === PromineoDataType.DateTime) {
      return (
        <PromineoDatepicker
          width={"auto"}
          height={inputHeight}
          type="datetime"
          value={variable.value}
          acceptCustomValue={true}
          onValueChange={props.onValueChange}
        />
      );
    }
  }, [
    variable.type,
    variable.value,
    props.onValueChange,
    handleValueChange,
    handleNumberChange,
  ]);

  return (
    <div className="flex items-center gap-2 w-full">
      <div className="font-inter">{variable.name}</div>
      <div>=</div>
      <div className="w-full">{variableEditor}</div>
      <div>
        <PromineoSelectBox
          width={120}
          height={inputHeight}
          dataSource={dataTypes}
          value={variable.type}
          displayExpr={"name"}
          valueExpr={"value"}
          onValueChange={props.onTypeChange}
          dropdownWrapperClass={dropdownWrapperClass}
        />
      </div>
    </div>
  );
}
