import { IEATestEventDataProviderHookResponseType } from "hooks/IEATestEventDataProviderHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import IEATestResultViewer from "../IEATestResultViewer";
import PendingDownloadTestEventPlaceHolder from "./PendingDownloadTestEventPlaceHolder";
import RunningTestEventPlaceHolder from "./RunningTestEventPlaceHolder";
import SkippedTestPlaceHolder from "./SkippedTestPlaceHolder";
import TestFailedPlaceHolder from "./TestFailedPlaceHolder";
import WaitingForOwnerOrPartnerPlaceHolder from "./WaitingForSenderPlaceHolder";

export interface DownloadTestPlaceHolderProps {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserSender: boolean;
  isCurrentUserOwner: boolean;
  gridHeight: number;
  testResult: IEATestEventDataProviderHookResponseType;
}

const DownloadTestPlaceholderRenderer = ({
  testResult,
  isCurrentUserOwner,
  isCurrentUserSender,
  exchangeAgreement,
  gridHeight,
}: DownloadTestPlaceHolderProps) => {
  const iea = exchangeAgreement;

  if (
    iea.latestDataTransferTestEvent &&
    iea.latestDataTransferTestEvent.downloadStatus ===
      DataTransferStatus.Success
  ) {
    return (
      <IEATestResultViewer
        exchangeAgreement={iea}
        isViewMode={true}
        height={gridHeight}
        testResult={testResult}
        resultType="Download"
      />
    );
  }

  if (
    iea.latestDataTransferTestEvent &&
    iea.latestDataTransferTestEvent.uploadStatus === DataTransferStatus.Success
  ) {
    if (
      iea.downloadTestRequired &&
      iea.latestDataTransferTestEvent.downloadStatus ===
        DataTransferStatus.Pending
    ) {
      return (
        <PendingDownloadTestEventPlaceHolder
          exchangeAgreement={iea}
          isCurrentUserOwner={isCurrentUserOwner}
          isCurrentUserSender={isCurrentUserSender}
        />
      );
    } else if (
      iea.latestDataTransferTestEvent.downloadStatus === DataTransferStatus.Fail
    ) {
      return (
        <TestFailedPlaceHolder
          exchangeAgreement={iea}
          transferType="download"
        />
      );
    } else if (
      iea.latestDataTransferTestEvent.downloadStatus ===
      DataTransferStatus.Running
    ) {
      return (
        <RunningTestEventPlaceHolder
          exchangeAgreement={iea}
          isCurrentUserOwner={isCurrentUserOwner}
          isCurrentUserSender={isCurrentUserSender}
          transferType="Download"
        />
      );
    } else if (
      iea.latestDataTransferTestEvent &&
      iea.latestDataTransferTestEvent.downloadStatus ===
        DataTransferStatus.Skipped
    ) {
      return (
        <SkippedTestPlaceHolder
          exchangeAgreement={iea}
          displayGenerateButton={false}
          isUpload={false}
        />
      );
    }
  }

  if (!iea.uploadTestRequired && !iea.downloadTestRequired) {
    return (
      <SkippedTestPlaceHolder
        exchangeAgreement={iea}
        isUpload={false}
        displayGenerateButton={false}
      />
    );
  }

  return <WaitingForOwnerOrPartnerPlaceHolder transferType="download" />;
};

export default DownloadTestPlaceholderRenderer;
