import TickCircleIcon from "components/icons/TickCircleIcon";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";

interface Props {
  userName: string;
  tenantName: string;
  dateTime: string;
  participents: "Owner" | "Partner";
}

export default function SignatureDetails(props: Props) {
  const { userName, dateTime } = props;

  return (
    <div className="flex items-center space-x-4">
      <div>
        <TickCircleIcon />
      </div>
      <div className="space-y-1 font-inter">
        <div className="space-x-2">
          <span className="font-semibold font-sm text-textGray">
            Signed off by {props.tenantName} ({props.participents})
          </span>
          <span className="font-normal font-sm text-lightTextGray">
            {getFormattedDateTime(dateTime)}
          </span>
        </div>
        <div>
          <span className="font-normal text-textGray font-sm">{userName}</span>
        </div>
      </div>
    </div>
  );
}
