import React from "react";

export default function UploadTestIcon() {
  return (
    <svg
      width="225"
      height="139"
      viewBox="0 0 225 139"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.420391"
        y="32.0122"
        width="179.159"
        height="91.9749"
        rx="6.30587"
        fill="white"
      />
      <rect
        x="0.420391"
        y="32.0122"
        width="179.159"
        height="91.9749"
        rx="6.30587"
        stroke="#EEEEEE"
        strokeWidth="0.840782"
      />
      <rect
        x="6.72656"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="6.72656"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="6.72656"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="50.4648"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="50.4648"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="94.2031"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="94.2031"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.941"
        y="40"
        width="35.331"
        height="6"
        rx="3"
        fill="#EEEEEE"
      />
      <rect
        x="137.941"
        y="54"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.941"
        y="68"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.941"
        y="82"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.941"
        y="96"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="137.941"
        y="110"
        width="35.331"
        height="6"
        rx="3"
        fill="#EAEAEA"
      />
      <rect
        x="112.42"
        y="1.01219"
        width="105.159"
        height="100.014"
        rx="6.30587"
        fill="white"
      />
      <rect
        x="112.42"
        y="1.01219"
        width="105.159"
        height="100.014"
        rx="6.30587"
        stroke="#EEEEEE"
        strokeWidth="0.840782"
      />
      <rect
        x="118.727"
        y="9"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EEEEEE"
      />
      <rect
        x="118.727"
        y="24.4082"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="39.8145"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="55.2227"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="70.6309"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <rect
        x="118.727"
        y="86.0391"
        width="92.5475"
        height="7"
        rx="3.36313"
        fill="#EAEAEA"
      />
      <path
        d="M189 132.775C205.53 132.775 219 119.305 219 102.775C219 86.2454 205.53 72.7754 189 72.7754C172.47 72.7754 159 86.2454 159 102.775C159 119.305 172.47 132.775 189 132.775ZM189 78.7754C202.23 78.7754 213 89.5454 213 102.775C213 116.005 202.23 126.775 189 126.775C175.77 126.775 165 116.005 165 102.775C165 89.5454 175.77 78.7754 189 78.7754ZM181.23 113.005L177 108.775L189 96.7754L201 108.775L196.77 113.005L192 108.265V120.775H186V108.265L181.23 113.005ZM174 87.7754H204V93.7754H174V87.7754Z"
        fill="#727272"
      />
    </svg>
  );
}
