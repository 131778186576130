import { useCallback, useEffect, useState } from "react";
import { useAppSelector } from "store/hooks";

export default function useGridIdentifierProvider(gridName: string): string {
  const [identifier, setIdentifier] = useState("");
  const currentUser = useAppSelector((store) => store.userData.mySelfResponse);

  const getUserIdentifierHash = useCallback((userIdentifier: string) => {
    // This is a simple hashing to avoid storing raw userIdentifer directly in the localStorage.
    let hash = 0;
    if (userIdentifier.length == 0) return hash;
    for (let i = 0; i < userIdentifier.length; i++) {
      let char = userIdentifier.charCodeAt(i);
      hash = (hash << 5) - hash + char;
      hash = hash & hash;
    }

    return hash;
  }, []);

  useEffect(() => {
    if (currentUser) {
      const userIdentityHash = getUserIdentifierHash(currentUser.email);
      setIdentifier(`${gridName}_${userIdentityHash}`);
    }
  }, [currentUser, gridName]);

  return identifier;
}
