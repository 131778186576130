import { ScrollView } from "devextreme-react";
import PromineoAccordion from "components/common/controls/PromineoAccordion";

interface LinkedExchangeAgreementAccordionDataSource {
  title: string;
  item: string;
}

interface Props {
  dataSource: LinkedExchangeAgreementAccordionDataSource[];
}

export default function LinkedExchangeAgreementsAccordion({
  dataSource,
}: Props) {
  const itemComponent = (prop: LinkedExchangeAgreementAccordionDataSource) => (
    <ScrollView>
      <div className="text-left pl-[10px] max-h-[90px]">{prop.item}</div>
    </ScrollView>
  );

  return (
    <PromineoAccordion
      className="promineo-confirmation-dialog-accordion promineo-accordion"
      dataSource={dataSource}
      itemRender={itemComponent}
      defaultSelectedIndex={undefined}
      collapsible
    />
  );
}
