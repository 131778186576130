import LabelWithContent from "components/common/LabelWithContent";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import TenantPublicKeyResponse from "interfaces/response/tenant/TenantPublicKeyResponse";
import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import {
  generateKeyPairForOwnTenantRequestAsync,
  getOwnTenantPublicKeysRequestAsync,
} from "store/actions/TenantAdminActions";
import { AppDispatch } from "store/store";
import TenantInfoSectionTitle from "./TenantInfoSectionTitle";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";

interface Props {
  tenantPublicKeys: TenantPublicKeyResponse[];
  isEdit: boolean;
}

export default function SecurityViewOrEdit(props: Props) {
  const dispatch = useDispatch<AppDispatch>();

  const [tenantPublicKeys, setTenantPublicKeys] = useState(
    props.tenantPublicKeys
  );

  const handleGenerateNewKeyPairClick = useCallback(() => {
    displayLoadingPanel();
    dispatch(generateKeyPairForOwnTenantRequestAsync())
      .unwrap()
      .then(() => {
        dispatch(getOwnTenantPublicKeysRequestAsync())
          .unwrap()
          .then((publicKeys: TenantPublicKeyResponse[]) => {
            setTenantPublicKeys(publicKeys);

            toastSuccess("Generated keys successfully.");
          });
      })
      .catch(() => {
        toastError("Failed to generate key pair.");
      })
      .finally(hideLoadingPanel);
  }, []);


  return (
    <>
      <div className="flex">
        <div>
          <TenantInfoSectionTitle title="Security" />
        </div>
        <div>
          <LabelWithContent
            label="Public Keys"
            content={
              <div className="space-y-4">
                <div>
                  <PromineoViewGrid
                    dataSource={tenantPublicKeys}
                    className="promineo-ui-grid"
                    height={300}
                    gridIdentifier="securityViewOrEditGrid"
                    noDataText="Your public keys will appear here once they are added!"
                    toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
                  >
                    <Scrolling rowRenderingMode={"virtual"} mode={"virtual"} />
                    <Column
                      caption={"Id"}
                      dataField="id"
                      alignment="left"
                      cssClass={"custom-control-padding"}
                    />
                    <Column
                      caption={"Is Enabled"}
                      dataField="isEnabled"
                      alignment="left"
                      cssClass={"custom-control-padding"}
                    />
                  </PromineoViewGrid>
                </div>

                {!props.isEdit && (
                  <div>
                    <PromineoButton
                      text={"Generate new key pair"}
                      variant={PromineoButtonType.Secondary}
                      onClick={handleGenerateNewKeyPairClick}
                    />
                  </div>
                )}
              </div>
            }
          />
        </div>
      </div>
    </>
  );
}
