import NavItemOption from "interfaces/NavItemOption";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getNavItemId } from "./NavigationItems";
import SubNavMenu from "./SubNavMenu";
import ExpandNavIcon from "components/icons/ExpandNavIcon";

interface Props {
  onHidingSubmenu?: () => void;
  isSubNavItem?: boolean;
}

export default function NavigationItem(props: Props & NavItemOption) {
  let navigate = useNavigate();
  const { isActive, text, route, itemId, children, isSubNavItem } = props;
  const [isSubMenuVisible, setIsSubMenuVisible] = useState(false);

  const handleNavItemClick = (e: any) => {
    if (children && children.length) {
      setIsSubMenuVisible(true);
    } else {
      navigateToRoute(route);
    }
    e.stopPropagation();
  };

  const navigateToRoute = (routeToNavigate: string | undefined) => {
    if (routeToNavigate) {
      setIsSubMenuVisible(false);
      navigate(routeToNavigate);
      props.onHidingSubmenu?.();
    }
  };

  const getActiveStateClass = () => {
    return isActive ? "text-lighterBlue border-white" : "border-transparent";
  };

  const mainCss = `font-poppins w-40 border-b-4 font-normal hover:text-lighterBlue ${
    isSubMenuVisible ? "text-lighterBlue" : ""
  } text-15px cursor-pointer leading-22px text-center ${getActiveStateClass()} h-full flex items-center justify-center`;

  const subNavMenuCss = `font-poppins font-15px leading-22px py-2.5 px-4 hover:bg-lighterBlue cursor-pointer font-medium`;

  const subMenuDepth = isSubNavItem ? 1 : 0;

  return (
    <>
      <div
        id={getNavItemId(itemId)}
        className={!props.isSubNavItem ? mainCss : subNavMenuCss}
        onClick={handleNavItemClick}
      >
        <div
          className={`h-full flex items-center ${
            !isSubNavItem ? "text-base" : ""
          }`}
        >
          {!isSubNavItem && (
            <>
              {text}{" "}
              {children?.length ? (
                <div className="ml-1">
                  <ExpandNavIcon />
                </div>
              ) : null}
            </>
          )}
          {isSubNavItem && (
            <div className="flex w-full justify-between">
              <div>{text}</div>
              <div>
                <i className="dx-icon-chevronright"></i>
              </div>
            </div>
          )}
        </div>
        {/* <div className="bg-red rounded-sm w-2 h-2 flex items-center p-2 justify-center ml-1 font-medium ">4</div> */}
      </div>
      {isSubMenuVisible && (
        <SubNavMenu
          navItems={props.children || []}
          visible={isSubMenuVisible}
          target={`#${getNavItemId(itemId)}`}
          depth={subMenuDepth}
          onHiding={() => setIsSubMenuVisible(false)}
          onNavigation={navigateToRoute}
        />
      )}
    </>
  );
}
