import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import DataTransferConflictResponse from "interfaces/response/DataTransferConflictResponse";
import { useCallback, useRef } from "react";
import CopyConflictsHeaderComponent from "./CopyConflictsHeaderComponent";

interface Props {
  downloadConflicts: DataTransferConflictResponse[];
}

export default function ConflictsGrid(props: Props) {
  const toolbarConfig = useRef({
    dislplaySearchPanel: true,
  });

  const CopyConflictHeader = useCallback(() => {
    return <CopyConflictsHeaderComponent conflicts={props.downloadConflicts} />;
  }, [props.downloadConflicts]);

  return (
    <div>
      <PromineoViewGrid
        height={430}
        toolbarConfig={toolbarConfig.current}
        dataSource={props.downloadConflicts}
        gridIdentifier="downloadConflictsGrid"
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column caption={"Code"} width={200} dataField={"planningObjectCode"} />
        <Column caption={"Planning Object Type"} width={200} dataField={"planningObjectTypeText"} />
        <Column caption={"Conflict"} dataField={"conflictDescription"} />
        <Column
          dataType="boolean"
          showEditorAlways={false}
          trueText={"Yes"}
          falseText={"No"}
          caption={"Can be resolved automatically"} 
          dataField={"canBeResolvedAutomatically"} 
        />
        <Column headerCellComponent={CopyConflictHeader} width={35} />
      </PromineoViewGrid>
    </div>
  );
}
