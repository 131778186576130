
export default function ComparisonIcon() {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.74625 6.61312L9.15625 5.20312L13.1562 9.20312L9.15625 13.2031L7.74625 11.7931L9.32625 10.2031H5.15625V8.20312H9.32625L7.74625 6.61312Z"
        fill="#8E8E8E"
        style={{
          fill: "#8E8E8E",
          fillOpacity: 1,
        }}
      />
      <path
        d="M17.5662 18.7931L16.1562 20.2031L12.1562 16.2031L16.1562 12.2031L17.5662 13.6131L15.9862 15.2031H20.1562V17.2031H15.9862L17.5662 18.7931Z"
        fill="#8E8E8E"
        style={{
          fill: "#8E8E8E",
          fillOpacity: 1,
        }}
      />
    </svg>
  );
}
