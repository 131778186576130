import BorderComponent from "components/common/BorderComponent";
import CrossIcon from "components/icons/CrossIcon";
import PromineoModal from "components/modal/PromineoModal";
import { ScrollView } from "devextreme-react";
import { FormulaTestResponse } from "interfaces/response/config-test/FormulaTestResponse";
import { useState } from "react";
import FormulaTester from "./FormulaTester";
import TypeSpecificationViewer from "./TypeSpecificationViewer";

interface Props {
  onClose: () => void;
  onAppendAndClose: (formula: string) => void;
}

export default function FormulaTesterModal(props: Props) {
  const [testResponse, setTestResponse] = useState<FormulaTestResponse>();

  const handleTestPerformed = (response: FormulaTestResponse) => {
    setTestResponse(response);
  };

  return (
    <PromineoModal isVisible={true} width={1200} height={640}>
      <div className="flex justify-between mb-4">
        <div className="font-bold font-poppins">Formula Tester</div>
        <div className="cursor-pointer" onClick={props.onClose}>
          <CrossIcon />
        </div>
      </div>
      <BorderComponent customClass="mb-4" />
      <div className="flex gap-5">
        <div className="w-1/2">
          <FormulaTester
            onTestPerformed={handleTestPerformed}
            onAppendAndClose={props.onAppendAndClose}
          />
        </div>
        <div className="w-1/2">
          <div className="font-bold">Details on Result Object</div>
          <BorderComponent customClass="my-2" />
          {testResponse?.isSuccess && testResponse?.typeSpecification && (
            <ScrollView height={500} showScrollbar="always">
              <TypeSpecificationViewer
                typeSpecification={testResponse.typeSpecification}
                showNonStatic={testResponse.isValueType}
                showStatic={!testResponse.isValueType}
              />
            </ScrollView>
          )}
        </div>
      </div>
    </PromineoModal>
  );
}
