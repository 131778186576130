import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/hooks";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import FieldValueWriteRequest from "interfaces/request/FieldValueWriteRequest";
import { TENANT_ADMIN_FIELDS } from "shared/constants/RoutePathConstants";
import Breadcrumb, {
  BreadcrumbItem,
} from "components/common/breadcrumb/Breadcrumb";
import EditFieldValueGrid from "features/common/edit-field-value-grid/EditFieldValueGrid";
import FieldValue from "interfaces/common/FieldValue";
import ContentControlFieldHeader from "./ContentControlFieldHeader";
import {
  getFieldForTenantAdminAsync,
  updateTenantFieldWithValuesAsync,
  updateFieldsContentControlForTenantAdminAsync,
} from "store/actions/TenantAdminActions";
import FieldManageRequest from "interfaces/request/FieldManageRequest";
import { ContentControl } from "shared/enums/feature/ContentControl";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { loadContentControlValuesAsync } from "store/actions/DropdownValueActions";
import useRemainingContentLayoutHeight from "hooks/RemainingContentLayoutHeightHook";
import PromineoCancelEditingConfirmationDialog from "components/common/controls/PromineoCancelEditingConfirmationDialog";
import { validateFieldValues } from "shared/utilities/FieldValueUtility";
import ErrorDisplayModal from "features/common/error-display-modal/ErrorDisplayModal";

export default function ContentControlFieldEditor() {
  const param = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [isModified, setIsModified] = useState<boolean>(false);
  const [
    isUnsavedChangeConfirmationVisible,
    setIsUnsavedChangeConfirmationVisible,
  ] = useState(false);

  const [allowContentControl, setAllowContentControl] = useState(false);
  const [allowBlanks, setAllowBlanks] = useState(false);
  const [contentControl, setContentControl] = useState<ContentControl>();
  const [fieldValueDataSource, setFieldValueDataSource] = useState<
    FieldValue[]
  >([]);
  const [breadCrumbItems, setBreadCrumbItems] = useState<BreadcrumbItem[]>([]);

  const [validationErrors, setValidationErrors] = useState<string[]>([]);
  const [isErrorModalVisible, setIsErrorModalVisible] =
    useState<boolean>(false);

  const fieldToEdit = useAppSelector((state) => state.tenantAdminData.field);

  useEffect(() => {
    if (fieldToEdit) {
      const items: BreadcrumbItem[] = [
        {
          key: "Content-control",
          text: "Content control",
          data: { url: TENANT_ADMIN_FIELDS },
        },
        {
          key: "field-name",
          text: fieldToEdit.name,
        },
      ];

      setBreadCrumbItems(items);
    }
  }, [fieldToEdit]);

  useEffect(() => {
    if (param.fieldId) {
      var fieldId = Number(param.fieldId);
      displayLoadingPanel();
      dispatch(getFieldForTenantAdminAsync(fieldId)).finally(hideLoadingPanel);
    }
  }, []);

  useEffect(() => {
    // content control field options required to display dropdowns inside header.
    displayLoadingPanel();
    dispatch(loadContentControlValuesAsync()).finally(hideLoadingPanel);
  }, []);

  useEffect(() => {
    if (fieldToEdit) {
      const fetchedTemplateFieldValue: FieldValue[] = fieldToEdit.values.map(
        (fd) => {
          return {
            valueCode: fd.code,
            description: fd.description,
            id: fd.id,
            __KEY__: fd._key_,
          } as FieldValue;
        }
      );

      setFieldValueDataSource(fetchedTemplateFieldValue);
      setAllowContentControl(fieldToEdit.allowContentControl ?? false);
      setAllowBlanks(fieldToEdit.allowBlank ?? false);

      if (fieldToEdit.contentControlLevel) {
        setContentControl(fieldToEdit.contentControlLevel);
      }
    }
  }, [fieldToEdit]);

  const handleContentControlValueChange = (value: number) => {
    if (fieldToEdit) {
      displayLoadingPanel();
      dispatch(
        updateFieldsContentControlForTenantAdminAsync({
          fieldId: fieldToEdit.id,
          request: { contentControl: value },
        })
      ).finally(hideLoadingPanel);
    }
  };

  const handleBreadCrumbSelectionChange = (
    ind: number,
    item: BreadcrumbItem
  ) => {
    if (item.data?.url) {
      navigate(item.data.url);
    }
  };

  const handleSaveChangesClick = () => {
    if (fieldToEdit) {
      const result = validateFieldValues(fieldValueDataSource);

      if (!result.isValidationSuccessful) {
        setValidationErrors(result.errors);
        showErrorModal();
        return;
      }

      const fieldValues: FieldValueWriteRequest[] = fieldValueDataSource.map(
        (fd) => {
          return {
            id: fd.id,
            code: fd.valueCode,
            description: fd.description,
          } as FieldValueWriteRequest;
        }
      );

      const fieldManageRequest: FieldManageRequest = {
        allowContentControl: allowContentControl,
        allowBlank: allowBlanks,
        values: allowContentControl ? fieldValues : [],
      };

      // Allowed to update field if content control is Tenant.
      if (
        fieldToEdit.contentControlLevel === ContentControl.Tenant &&
        fieldToEdit.contentControlFieldId
      ) {
        displayLoadingPanel();
        dispatch(
          updateTenantFieldWithValuesAsync({
            tenantFieldId: fieldToEdit.contentControlFieldId,
            request: fieldManageRequest,
          })
        )
          .unwrap()
          .then(() => {
            toastSuccess("Updated successfully");
            setIsModified(false);
          })
          .finally(hideLoadingPanel);
      }
    }
  };

  const headerDivId: string = "content-control-field-header";
  const footerDivId: string = "content-control-field-footer";
  const excludedContainerIds: string[] = [headerDivId, footerDivId];

  const gridHeight = useRemainingContentLayoutHeight({
    excludedContainerIds,
    marginHeight: 140,
  });

  const handleFieldValueDatasourceChange = (value: any) => {
    setFieldValueDataSource(value);
    setIsModified(true);
  };

  const handleAllowContentControlChange = (value: boolean) => {
    setAllowContentControl(value);
    setIsModified(true);
  };

  const handleAllowBlankChange = (value: boolean) => {
    setAllowBlanks(value);
    setIsModified(true);
  };

  const handleRowUpdated = () => {
    setIsModified(true);
  };

  const navigateForCancellation = () => {
    navigate(-1);
  };

  const handleCancelClick = () => {
    if (isModified) {
      setIsUnsavedChangeConfirmationVisible(true);
    } else {
      navigateForCancellation();
    }
  };

  const handleConfirmCancel = () => {
    setIsUnsavedChangeConfirmationVisible(false);
    navigateForCancellation();
  };

  const handleCancelConfirmationDialog = () => {
    setIsUnsavedChangeConfirmationVisible(false);
  };

  const showErrorModal = () => setIsErrorModalVisible(true);
  const hideErrorModal = () => setIsErrorModalVisible(false);

  if (!fieldToEdit) {
    return <></>;
  }

  return (
    <div>
      <div id={headerDivId}>
        <div className="mb-1">
          <Breadcrumb
            items={breadCrumbItems}
            onSelectionChange={handleBreadCrumbSelectionChange}
          />
        </div>
        <ContentControlFieldHeader
          field={fieldToEdit}
          contentControl={contentControl}
          isEdit={true}
          onContentControlValueChange={handleContentControlValueChange}
        />
      </div>
      <div className="w-560px h-fit ml-auto mr-auto">
        <EditFieldValueGrid
          fieldValueDataSource={fieldValueDataSource}
          setFieldValueDataSource={handleFieldValueDatasourceChange}
          allowContentControl={allowContentControl}
          setAllowContentControl={handleAllowContentControlChange}
          allowBlanks={allowBlanks}
          setAllowBlanks={handleAllowBlankChange}
          disableContentControl={contentControl !== ContentControl.Tenant}
          height={gridHeight}
          onRowUpdated={handleRowUpdated}
        />
      </div>
      <div className="flex justify-between mt-6" id={footerDivId}>
        <PromineoButton
          variant={PromineoButtonType.Secondary}
          text="Cancel"
          onClick={handleCancelClick}
        />
        <PromineoButton
          variant={PromineoButtonType.Success}
          text="Save Changes"
          onClick={handleSaveChangesClick}
          disabled={contentControl !== ContentControl.Tenant}
        />
      </div>
      {isUnsavedChangeConfirmationVisible ? (
        <PromineoCancelEditingConfirmationDialog
          onConfirm={handleConfirmCancel}
          onCancel={handleCancelConfirmationDialog}
        ></PromineoCancelEditingConfirmationDialog>
      ) : null}
      {isErrorModalVisible && (
        <ErrorDisplayModal
          count={validationErrors.length}
          errors={validationErrors}
          onHideDialog={hideErrorModal}
        />
      )}
    </div>
  );
}
