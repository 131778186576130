import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { NewExchangeAgreementActionRequired } from "shared/enums/feature/NewExchangeAgreementActionRequired";
import { IsSignedByPartner } from "shared/utilities/ExchangeAgreementUtility";
import ContinueWithoutTestButton from "./ContinueWithoutTestButton";
import { InactiveIeaEditorTabs } from "./InactiveIEAEditor";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  currentTab: InactiveIeaEditorTabs;
  isPartnerSubmitButtonDisabled: boolean;
  isActiveIeaEdit?: boolean;
  isCurrentUserPartner: boolean;
  isCurrentUserReceiver: boolean;
  onOwnerSign: () => void;
  onPartnerSign: () => void;
  onGoToTab: (tabIndex: number) => void;
  onPartnerInfoSubmit: () => void;
}

export default function InactiveIEAFooterForSameOwnerAndPartner(props: Props) {
  const {
    exchangeAgreement,
    currentTab,
    isPartnerSubmitButtonDisabled,
    isActiveIeaEdit,
  } = props;

  const actionRequiredIfInactive = exchangeAgreement.actionRequiredIfInactive;

  if (actionRequiredIfInactive === NewExchangeAgreementActionRequired.SignOff) {
    if (IsSignedByPartner(exchangeAgreement)) {
      return (
        <PromineoButton
          variant={PromineoButtonType.Primary}
          text="Sign IEA on behalf of Owner"
          onClick={props.onOwnerSign}
        />
      );
    } else {
      return (
        <PromineoButton
          variant={PromineoButtonType.Primary}
          text="Sign IEA on behalf of partner"
          onClick={props.onPartnerSign}
        />
      );
    }
  }

  if (
    !isActiveIeaEdit &&
    props.exchangeAgreement.actionRequiredIfInactive !== undefined &&
    currentTab !== InactiveIeaEditorTabs.IeaTests
  ) {
    return (
      <PromineoButton
        variant={PromineoButtonType.Primary}
        text="Next"
        disabled={isPartnerSubmitButtonDisabled}
        onClick={() => props.onGoToTab(currentTab + 1)}
      />
    );
  }

  if (
    actionRequiredIfInactive ===
    NewExchangeAgreementActionRequired.PerformUploadTest
  ) {
    if (exchangeAgreement.uploadTestRequired) {
      if (
        exchangeAgreement.latestDataTransferTestEvent === undefined ||
        (exchangeAgreement.latestDataTransferTestEvent.uploadStatus !==
          DataTransferStatus.Success &&
          exchangeAgreement.latestDataTransferTestEvent.uploadStatus !==
            DataTransferStatus.Skipped)
      ) {
        return (
          <ContinueWithoutTestButton
            isUpload={true}
            exchangeAgreement={exchangeAgreement}
            isCurrentUserPartner={true}
            isCurrentUserOwner={true}
          />
        );
      }
    }
  }

  if (props.isCurrentUserReceiver) {
    if (
      exchangeAgreement.latestDataTransferTestEvent !== undefined &&
      exchangeAgreement.latestDataTransferTestEvent.downloadStatus !==
        DataTransferStatus.Success &&
      exchangeAgreement.latestDataTransferTestEvent.downloadStatus !==
        DataTransferStatus.Skipped &&
      exchangeAgreement.latestDataTransferTestEvent.uploadStatus ===
        DataTransferStatus.Success
    ) {
      return (
        <ContinueWithoutTestButton
          exchangeAgreement={exchangeAgreement}
          isUpload={false}
          isCurrentUserPartner={true}
          isCurrentUserOwner={true}
        />
      );
    }
  }

  return null;
}
