import PrmineoRoundedTabPanel from "components/common/controls/PrmineoRoundedTabPanel";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import DownloadIcon from "components/icons/DownloadIcon";
import UploadIcon from "components/icons/UploadIcon";
import { Scrolling } from "devextreme-react/data-grid";
import { Item } from "devextreme-react/tab-panel";
import { IEATestEventDataProviderHookResponseType } from "hooks/IEATestEventDataProviderHook";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { useCallback, useMemo, useState } from "react";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { getFormattedDateTime } from "shared/utilities/DateTimeUtility";
import { getDateFormattedColumnsForIEATestResultDynamicColumns } from "shared/utilities/IEAUtility";
import "./style/IEATestResultViewer.css";
import TestResultViewerToolbarItems from "./TestResultViewerToolbarItems";
import TransferTestTabTitle from "./TransferTestTabTitle";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isViewMode: boolean;
  height: number;
  resultType: "Upload" | "Download";
  testResult: IEATestEventDataProviderHookResponseType;
}

export enum IEATesterTabs {
  Activities,
  Schedules,
  ResourceAssignments,
}

export default function IEATestResultViewer(props: Props) {
  const [selectedTabIndex, setSelectedTabIndex] = useState<IEATesterTabs>(
    IEATesterTabs.Activities
  );

  const handleSelectedItemChange = useCallback((index: number) => {
    setSelectedTabIndex(index);
  }, []);

  const resultData =
    props.resultType === "Upload"
      ? props.testResult.uploadResult
      : props.testResult.downloadResult;

  const titleRenderer = useCallback(
    (data: any) => {
      return (
        <TransferTestTabTitle
          tabHeaderData={data}
          activityCount={resultData?.activity.data.length}
          resourceAssignmentCount={resultData?.resourceAssignment.data.length}
          scheduleCount={resultData?.schedule.data.length}
          selectedTabIndex={selectedTabIndex}
        />
      );
    },
    [selectedTabIndex, props.testResult]
  );

  const toolBarConfigs = useMemo(() => {
    return {
      dislplaySearchPanel: true,
      customToolbarRenderer: () => (
        <TestResultViewerToolbarItems
          exchangeAgreement={props.exchangeAgreement}
          resultType={props.resultType}
          testResult={props.testResult}
        />
      ),
    };
  }, [props.exchangeAgreement, props.resultType, props.testResult]);

  const gridHeight =
    props.height - (props.exchangeAgreement.partnerSigner ? 40 : 20);

  const transferTimeDisplay = useCallback(() => {
    if (props.resultType === "Upload" && props.testResult.uploadedAt) {
      return (
        <div className="flex gap-x-2 ml-4 mt-4">
          <UploadIcon></UploadIcon>
          {`Upload test completed: ${getFormattedDateTime(
            props.testResult.uploadedAt
          )}`}
        </div>
      );
    }

    if (props.resultType === "Download" && props.testResult.downloadedAt) {
      return (
        <div className="flex gap-x-2 ml-4 mt-4">
          <DownloadIcon></DownloadIcon>
          {`Download test completed: ${getFormattedDateTime(
            props.testResult.downloadedAt
          )}`}
        </div>
      );
    }
    return null;
  }, [props.testResult, props.resultType]);

  return (
    <div>
      <PrmineoRoundedTabPanel
        onSelectedIndexChange={handleSelectedItemChange}
        defaultSelectedIndex={selectedTabIndex}
        repaintChangesOnly={true}
      >
        <Item text={"Activity fields"} tabRender={titleRenderer}>
          <PromineoViewGrid
            gridIdentifier="activity-fields-grid"
            className="test-result-viewer-grid"
            height={gridHeight}
            width={"100%"}
            dataSource={resultData?.activity.data}
            columns={resultData?.activity.columns}
            toolbarConfig={toolBarConfigs}
            additionalWidget={<>{transferTimeDisplay()}</>}
            customizeColumns={
              getDateFormattedColumnsForIEATestResultDynamicColumns
            }
            noDataText={
              props.exchangeAgreement.uploadTestRequired &&
              (props.exchangeAgreement.latestDataTransferTestEvent
                ?.uploadStatus === DataTransferStatus.Pending ||
                props.exchangeAgreement.latestDataTransferTestEvent
                  ?.uploadStatus === DataTransferStatus.Running)
                ? "Test results will appear here"
                : ""
            }
          >
            <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
          </PromineoViewGrid>
        </Item>
        <Item text={"Schedule fields"} tabRender={titleRenderer}>
          <PromineoViewGrid
            width={"100%"}
            gridIdentifier="schedule-fields-grid"
            height={gridHeight}
            dataSource={resultData?.schedule.data}
            columns={resultData?.schedule.columns}
            additionalWidget={<>{transferTimeDisplay()}</>}
            toolbarConfig={toolBarConfigs}
            customizeColumns={
              getDateFormattedColumnsForIEATestResultDynamicColumns
            }
            className="test-result-viewer-grid"
          >
            <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
          </PromineoViewGrid>
        </Item>
        <Item text={"Resource assignments"} tabRender={titleRenderer}>
          <PromineoViewGrid
            width={"100%"}
            gridIdentifier="resource-assignments-grid"
            height={gridHeight}
            dataSource={resultData?.resourceAssignment.data}
            columns={resultData?.resourceAssignment.columns}
            additionalWidget={<>{transferTimeDisplay()}</>}
            toolbarConfig={toolBarConfigs}
            customizeColumns={
              getDateFormattedColumnsForIEATestResultDynamicColumns
            }
            className="test-result-viewer-grid"
          >
            <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
          </PromineoViewGrid>
        </Item>
      </PrmineoRoundedTabPanel>
    </div>
  );
}
