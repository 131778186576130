import { MethodDefinition } from "interfaces/response/config-test/MethodDefinition";

interface Props {
  methodDefinition: MethodDefinition;
}

export default function MethodDefinitionViewer(props: Props) {
  const { methodDefinition } = props;
  return (
    <div className="font-mono">
      <span className="text-lightTextGray">{methodDefinition.dataType}</span>{" "}
      <span className="text-ilapBlue font-bold">{methodDefinition.name}</span>{" "}
      <span>{"("}{methodDefinition.parameters.map((param, index) => {
        return (
          <span key={index}>
            <span className="text-lightTextGray">{param.dataType}{" "}</span><span>{param.name}</span>
            {index < methodDefinition.parameters.length - 1 ? ", " : ""}
          </span>
        );
      })}{")"}</span>
    </div>
  );
}
