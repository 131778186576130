import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { Item } from "devextreme-react/tab-panel";
import usePartnerPrimaryInformationProps, {
  PartnerPrimaryInformationDataHookProps,
} from "hooks/iea-partner-information/PartnerPrimaryInformationDataHook";
import useActivityFilterHostParameterDataHook, {
  ActivityFilterHostParameterDataHookProps,
} from "hooks/new-iea/ActivityFilterHostParameterDataHook";
import useHostParametersOverrideIeaDataHook, {
  HostParameterOverrideDataHookProps,
} from "hooks/new-iea/HostParameterOverrideIeaDataHook";
import ExchangeAgreementUpdateRequest from "interfaces/request/ExchangeAgreementUpdateRequest";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { toastSuccess } from "shared/utilities/ToastUtility";
import { updateExistingExchangeAgreementForPartnerAsync } from "store/actions/ExchangeAgreementActions";
import { useAppDispatch } from "store/hooks";
import ActivityFilterHostParameterOverride from "../common/ActivityFilterHostParameterOverride";
import IEAHostParameterOverride from "../common/IEAHostParameterOverride";
import PartnerConfigurationStepOne from "./PartnerConfigurationStepOne";
import { HostSystem } from "shared/enums/feature/HostSystem";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";

interface Props {
  currentStep: number;
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isPartnerRoleSender: boolean;
  onDataValidated: (isValid: boolean) => void;
  onSuccess: () => void;
  onModify: () => void;
}

export interface PartnerConfigurationSettingStepsRef {
  submitPartnerInformation: () => void;
}

const PartnerConfigurationSettingSteps = forwardRef<
  PartnerConfigurationSettingStepsRef,
  Props
>((props: Props, ref) => {
  const { currentStep } = props;
  const dispatch = useAppDispatch();

  const hookProps = useMemo<PartnerPrimaryInformationDataHookProps>(() => {
    return {
      exchangeAgreement: props.exchangeAgreement,
    };
  }, [props.exchangeAgreement]);

  const partnerConfigurationData = usePartnerPrimaryInformationProps(hookProps);

  const hostParameterOverrideHookProps =
    useMemo<HostParameterOverrideDataHookProps>(() => {
      return {
        direction:
          partnerConfigurationData.primaryInformationProps.configDirection,
        codeSet: partnerConfigurationData.dependentPropsForOtherHook.codeSet,
        connectorId:
          partnerConfigurationData.primaryInformationRequest.connectorId,
        hostSystemParameters:
          partnerConfigurationData.dependentPropsForOtherHook.hostParameters,
        hostSystem:
          partnerConfigurationData.dependentPropsForOtherHook.hostSystem,
        hostSystemName:
          partnerConfigurationData.primaryInformationProps
            .connectorHostSystemName,
      };
    }, [partnerConfigurationData]);

  const {
    hostParameterOverrideProps,
    hostParameterRequest,
    isHostParameterRequestValid,
    getUpdatedHostParametersForSaving,
  } = useHostParametersOverrideIeaDataHook(hostParameterOverrideHookProps);

  const [isActivityFilterValid, setIsActivityFilterValid] =
    useState<boolean>(true);
  const [isActivityFilterOn, setIsActivityFilterOn] = useState<boolean>(true);

  const onActivityFilterToggle = (value: boolean) => {
    setIsActivityFilterOn(value);
  };

  const activityFilterHostParameterHookProps =
    useMemo<ActivityFilterHostParameterDataHookProps>(() => {
      return {
        hostSystem:
          partnerConfigurationData.dependentPropsForOtherHook.hostSystem,
        hostSystemParameters: hostParameterRequest,
        direction:
          partnerConfigurationData.primaryInformationProps.configDirection,
        sapActivityFilterProps: {
          frequencyType: props.exchangeAgreement.frequencyType,
        },
      };
    }, [
      partnerConfigurationData,
      props.exchangeAgreement,
      hostParameterRequest,
    ]);

  const {
    activityFilterHostParameterOverrideProps,
    hostParametersWithActivityFilterRequest,
    isHostParametersWithActivityFilterRequestValid,
  } = useActivityFilterHostParameterDataHook(
    activityFilterHostParameterHookProps
  );

  useImperativeHandle(ref, () => ({
    submitPartnerInformation: () => {
      const hostSystem =
        partnerConfigurationData.dependentPropsForOtherHook.hostSystem;

      if (
        isActivityFilterOn &&
        partnerConfigurationData.primaryInformationProps.configDirection ==
          ConfigDirection.Sending &&
        hostSystem !== HostSystem.SAP
      ) {
        var isActivityFilterValid = true;

        const activityFilter =
          activityFilterHostParameterOverrideProps.generalActivityFilter
            ?.filterValue;

        if (!activityFilter) {
          isActivityFilterValid = false;
        }

        setIsActivityFilterValid(isActivityFilterValid);

        if (!isActivityFilterValid) {
          return;
        }
      }

      if (
        partnerConfigurationData.isPrimaryInfoValid &&
        isHostParameterRequestValid &&
        (!props.isPartnerRoleSender ||
          isHostParametersWithActivityFilterRequestValid)
      ) {
        const partnerHostParameters = props.isPartnerRoleSender
          ? getUpdatedHostParametersForSaving(
              hostParametersWithActivityFilterRequest
            )
          : getUpdatedHostParametersForSaving(hostParameterRequest);

        const updateRequest: ExchangeAgreementUpdateRequest = {
          title: props.exchangeAgreement.title,
          comments: props.exchangeAgreement.comments,
          startDate: props.exchangeAgreement.startDate,
          frequencyType: props.exchangeAgreement.frequencyType,
          expirationDate: props.exchangeAgreement.expirationDate,
          executionCronExpression:
            props.exchangeAgreement.executionCronExpression,
          lagInMinutes: props.exchangeAgreement.lagInMinutes,
          predecessorAgreementId:
            props.exchangeAgreement.predecessorAgreement?.id,
          labelIdentifiers: (props.exchangeAgreement.labels || []).map(
            (l) => l.id
          ),
          ownerRepresentativeIdentifiers:
            props.exchangeAgreement.ownerRepresentatives.map((r) => r.id),
          ownerConfigSettings: props.exchangeAgreement.ownerConfigSettings,
          partnerConnectorId:
            partnerConfigurationData.primaryInformationRequest.connectorId,
          partnerScheduleId:
            partnerConfigurationData.primaryInformationRequest
              .selectedScheduleId,
          partnerConfigSettings: partnerHostParameters
            ? JSON.stringify(partnerHostParameters)
            : null,
          partnerRepresentativeIdentifiers:
            partnerConfigurationData.primaryInformationRequest
              .partnerRepresentativeIds,
        };

        if (updateRequest) {
          displayLoadingPanel();
          dispatch(
            updateExistingExchangeAgreementForPartnerAsync({
              exchangeAgreementId: props.exchangeAgreement.id,
              updateRequest: updateRequest,
            })
          )
            .unwrap()
            .then(() => {
              toastSuccess("IEA updated successfully.");
              props.onSuccess();
            })
            .finally(hideLoadingPanel);
        }
      }
    },
  }));

  useEffect(() => {
    let isValid = true;
    if (currentStep === 1) {
      isValid &&= partnerConfigurationData.isPrimaryInfoValid;
    } else if (currentStep === 2) {
      isValid &&= isHostParameterRequestValid;
    }
    props.onDataValidated(isValid);
    if (partnerConfigurationData.isModified) {
      props.onModify();
    }
  }, [partnerConfigurationData, currentStep]);

  return (
    <PromineoTabPanel
      selectedIndex={currentStep - 1}
      repaintChangesOnly={true}
      hideTabs={true}
    >
      <Item>
        <PartnerConfigurationStepOne
          {...partnerConfigurationData.primaryInformationProps}
        />
      </Item>
      <Item>
        <IEAHostParameterOverride {...hostParameterOverrideProps} />
      </Item>
      <Item>
        <ActivityFilterHostParameterOverride
          {...activityFilterHostParameterOverrideProps}
          onActivityFilterToggle={onActivityFilterToggle}
          isActivityFilterInValid={!isActivityFilterValid}
        />
      </Item>
    </PromineoTabPanel>
  );
});

export default PartnerConfigurationSettingSteps;
