import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoModal from "components/modal/PromineoModal";
import { Item } from "devextreme-react/tab-panel";
import TemplateChangePreviewGrid from "./TemplateChangePreviewGrid";
import { TemplateConfigUpdatePreviewGridHeight } from "shared/constants/GridConstants";
import {
  TemplateConfigUpdatePreviewModalHeight,
  TemplateConfigUpdatePreviewModalWidth,
  TemplateConfigUpdatePreviewTabPanelHeight,
} from "shared/constants/CommonConstants";
import TemplateConfigurationUpdatePreviewModalHeader from "features/common/template-configuration-update/TemplateConfigurationUpdatePreviewModalHeader";
import ConfigTemplateUpdatePreviewInfoResponse from "interfaces/response/ConfigTemplateUpdatePreviewInfoResponse";

interface Props {
  onCancel: () => void;
  onProceed: () => void;
  configId: number;
  templateChanges: ConfigTemplateUpdatePreviewInfoResponse | null;
}

export default function TemplateUpdatePreviewModal(props: Props) {
  return (
    <PromineoModal
      isVisible={true}
      width={TemplateConfigUpdatePreviewModalWidth}
      height={TemplateConfigUpdatePreviewModalHeight}
    >
      <TemplateConfigurationUpdatePreviewModalHeader
        title="Template Updates"
        description="
        Below you can view the updates in the template that is used in the
        config. This update requires input for mapping and value
        transformations. Clicking the update button will therefore put the
        config under construction until the required input is provided and the
        config is published."
      />

      <div className="mt-4">
        <PromineoTabPanel height={TemplateConfigUpdatePreviewTabPanelHeight}>
          <Item title={"Added fields"}>
            <div className="pt-4">
              <TemplateChangePreviewGrid
                fields={props.templateChanges?.addedFields || []}
                height={TemplateConfigUpdatePreviewGridHeight}
                noDataText="No fields are added"
                gridIdentifier="templateUpdatePreviewAddedFields"
              />
            </div>
          </Item>
          <Item title={"Removed fields"}>
            <div className="pt-4">
              <TemplateChangePreviewGrid
                fields={props.templateChanges?.removedFields || []}
                height={TemplateConfigUpdatePreviewGridHeight}
                noDataText="No fields are removed"
                gridIdentifier="templateUpdatePreviewRemovedFields"
              />
            </div>
          </Item>
          <Item title={"Updated fields"}>
            <div className="pt-4">
              <TemplateChangePreviewGrid
                fields={props.templateChanges?.updatedFields || []}
                height={TemplateConfigUpdatePreviewGridHeight}
                displayUpdateColumn={true}
                noDataText="No fields are updated"
                gridIdentifier="templateUpdatePreviewUpdatedFields"
              />
            </div>
          </Item>
        </PromineoTabPanel>
      </div>
      <div className="flex justify-between mt-4">
        <PromineoButton
          text="Cancel"
          variant={PromineoButtonType.Secondary}
          onClick={props.onCancel}
        />
        <PromineoButton
          text="Provide required input to update config"
          disabled={false}
          onClick={props.onProceed}
        />
      </div>
    </PromineoModal>
  );
}
