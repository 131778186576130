import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import IEADetailDialogHeader from "./IEADetailDialogHeader";
import { Item } from "devextreme-react/accordion";
import PromineoModal from "components/modal/PromineoModal";
import {
  IEADetailGridlHeight,
  IEADetailModalHeight,
  IEADetailModalWidth,
  IEADetailTabPanelHeight,
} from "shared/constants/CommonConstants";
import DirectMappingEditor from "features/setup/configs/config-edit/direct-mapping/DirectMappingEditor";
import ConfigValueTransformationViewer from "features/setup/configs/config-view/config-value-transformation/ConfigValueTransformationViewer";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { useEffect, useState } from "react";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { resetCurrentConfig } from "store/slices/ConfigSlice";
import { loadExchangeAgreementDetailedMappingsAsync } from "store/actions/ExchangeAgreementActions";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import ExchangeAgreementFieldMappingResponse from "interfaces/response/ExchangeAgreementFieldMappingResponse";
import FieldValueMapResponse from "interfaces/response/FieldValueMapResponse";
import FieldDetailValueResponse from "interfaces/response/FieldDetailValueResponse";
import ExchangeAgreementFieldValueMapResponse from "interfaces/response/ExchangeAgreementFieldValueMapResponse";
import ExchangeAgreementFieldValueResponse from "interfaces/response/ExchangeAgreementFieldValueResponse";

interface Props {
  onClose: () => void;
  exchangeAgreementId: number;
}

export default function IEADetailDialog(props: Props) {
  const dispatch = useAppDispatch();
  const { onClose, exchangeAgreementId } = props;

  const [senderMappingDetail, setSenderMappingDetail] = useState<
    FieldMappingResponse[]
  >([]);
  const [receiverMappingDetail, setReceiverMappingDetail] = useState<
    FieldMappingResponse[]
  >([]);
  const [activytFilter, setActivityFilter] = useState<string>("");

  const exchangeAgreementMappingDetail = useAppSelector(
    (store) => store.exchangeAgreementData.exchangeAgreementMappingDetail
  );

  useEffect(() => {
    displayLoadingPanel();
    dispatch(
      loadExchangeAgreementDetailedMappingsAsync(exchangeAgreementId)
    ).finally(hideLoadingPanel);

    return () => {
      dispatch(resetCurrentConfig());
    };
  }, []);

  const getFieldValueMapResponseFromIeaValueMapResponse = (
    ieaValueMap: ExchangeAgreementFieldValueMapResponse
  ) => {
    let valueMap: FieldValueMapResponse = {
      id: 0,
      configFieldMappingId: 0,
      templateValue: ieaValueMap.templateValue,
      value: ieaValueMap.value,
      description: ieaValueMap.description,
      fieldValueText: ieaValueMap.fieldValueText,
      fieldValueDescription: ieaValueMap.fieldValueDescription,
    };
    return valueMap;
  };

  const getFieldValueResponseFromIeaValueResponse = (
    ieaValue: ExchangeAgreementFieldValueResponse
  ) => {
    let value: FieldDetailValueResponse = {
      id: 0,
      code: ieaValue.code,
      description: ieaValue.description,
      templateName: "",
      templateId: 0,
    };
    return value;
  };

  const getFieldMappingResponseFromIeaMappingResposne = (
    ieaMapping: ExchangeAgreementFieldMappingResponse
  ) => {
    let valueMapsForFieldMapping: FieldValueMapResponse[] = [];
    let valuesForFieldMapping: FieldDetailValueResponse[] = [];
    if (ieaMapping.valueMaps) {
      valueMapsForFieldMapping = ieaMapping.valueMaps.map((v) =>
        getFieldValueMapResponseFromIeaValueMapResponse(v)
      );
    }

    if (ieaMapping.values) {
      valuesForFieldMapping = ieaMapping.values.map((v) =>
        getFieldValueResponseFromIeaValueResponse(v)
      );
    }

    let fieldMapping: FieldMappingResponse = {
      id: 0,
      name: ieaMapping.name,
      description: ieaMapping.description,
      dataType: ieaMapping.dataType,
      planningObjectType: ieaMapping.planningObjectType,
      dataTypeText: ieaMapping.dataTypeText,
      configId: 0,
      fieldId: ieaMapping.isCoreFieldMapping ? null : ieaMapping.fieldId,
      coreFieldId: ieaMapping.isCoreFieldMapping ? ieaMapping.fieldId : null,
      hasDirectMapping: ieaMapping.hasDirectMapping,
      allowContentControl: ieaMapping.allowContentControl,
      allowBlank: ieaMapping.allowBlank,
      planningObjectTypeText: ieaMapping.planningObjectTypeText,
      mappedConnectorHostFieldId: 0,
      mappedConnectorHostFieldName: ieaMapping.mappedConnectorHostFieldName,
      formula: ieaMapping.formula,
      contentControlLevel: ieaMapping.controlLevel,
      isConfigTemplateFieldMapping: ieaMapping.isTemplateFieldMapping,
      isCoreFieldMapping: ieaMapping.isCoreFieldMapping,
      contentControlLevelText: ieaMapping.controlLevelText,
      valueCount: ieaMapping.values.length,
      valueMaps: valueMapsForFieldMapping,
      values: valuesForFieldMapping,
    };

    return fieldMapping;
  };

  useEffect(() => {
    if (exchangeAgreementMappingDetail) {
      let mappingDetailsForSender: FieldMappingResponse[] = [];
      let mappingDetailsForReceiver: FieldMappingResponse[] = [];

      if (exchangeAgreementMappingDetail.senderFieldMappings) {
        mappingDetailsForSender =
          exchangeAgreementMappingDetail.senderFieldMappings.map((m) =>
            getFieldMappingResponseFromIeaMappingResposne(m)
          );
      }

      if (exchangeAgreementMappingDetail.receiverFieldMappings) {
        mappingDetailsForReceiver =
          exchangeAgreementMappingDetail.receiverFieldMappings.map((m) =>
            getFieldMappingResponseFromIeaMappingResposne(m)
          );
      }

      setSenderMappingDetail(mappingDetailsForSender);
      setReceiverMappingDetail(mappingDetailsForReceiver);
      setActivityFilter(exchangeAgreementMappingDetail.senderActivityFilter);
    }
  }, [exchangeAgreementMappingDetail]);

  return (
    <PromineoModal
      isVisible={true}
      width={IEADetailModalWidth}
      height={IEADetailModalHeight}
    >
      <IEADetailDialogHeader onClose={onClose} />
      <div className="mt-4">
        <PromineoTabPanel height={IEADetailTabPanelHeight}>
          <Item title={"Activity filter"}>
            <div className="p-4">{activytFilter}</div>
          </Item>
          <Item title={"Sender direct mapping"}>
            <div className="p-4">
              <DirectMappingEditor
                fieldMappings={senderMappingDetail.filter((m) => m.fieldId)}
                height={IEADetailGridlHeight}
                isEdit={false}
                displaySearch={false}
                gridIdentifier="ieaDetailDialogSenderDirectMapping"
              />
            </div>
          </Item>
          <Item title={"Sender value transformation"}>
            <div className="p-4">
              <ConfigValueTransformationViewer
                configMappings={senderMappingDetail}
                height={IEADetailGridlHeight}
                displaySearchbar={false}
                gridIdentifier="ieaDetailDialogSenderValueTransformation"
              />
            </div>
          </Item>
          <Item title={"Receiver direct mapping"}>
            <div className="p-4">
              <DirectMappingEditor
                fieldMappings={receiverMappingDetail.filter((m) => m.fieldId)}
                height={IEADetailGridlHeight}
                isEdit={false}
                displaySearch={false}
                gridIdentifier="ieaDetailDialogReceiverDirectMapping"
              />
            </div>
          </Item>
          <Item title={"Receiver value transformation"}>
            <div className="p-4">
              <ConfigValueTransformationViewer
                configMappings={receiverMappingDetail}
                height={IEADetailGridlHeight}
                displaySearchbar={false}
                gridIdentifier="ieaDetailDialogReceiverValueTransformation"
              />
            </div>
          </Item>
        </PromineoTabPanel>
      </div>
    </PromineoModal>
  );
}
