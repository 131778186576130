import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { IColumnProps } from "devextreme-react/data-grid";
import { saveAs } from "file-saver";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import PlanningObjectTestDataResponse from "interfaces/response/planning-objects/PlanningObjectTestDataResponse";
import { useCallback, useEffect, useState } from "react";
import { DataTransferStatus } from "shared/enums/feature/DataTransferStatus";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";
import { loadTestResultAsync } from "store/actions/ExchangeAgreementActions";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { resetTestResults } from "store/slices/ExchangeAgreementSlice";
import * as XLSX from "xlsx";

type DuplicationCodeCheckerType = {
  isFoundPreviously: boolean;
};

export interface IEATestEventDataProviderHookType {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
}

export interface IEATestEventDataProviderHookResponseType {
  uploadResult: IEATestEventDataResult | null;
  downloadResult: IEATestEventDataResult | null;
  comparisonResult: IEATestEventDataResult | null;
  uploadedAt: string | null;
  downloadedAt: string | null;
  handleComparisonExport: () => void;
  handleUploadExport: () => void;
  handleDownloadExport: () => void;
  reloadTestResult: () => void;
}

export interface IEATestEventDataResult {
  activity: {
    data: any[];
    columns: IColumnProps[];
  };
  schedule: {
    data: any[];
    columns: IColumnProps[];
  };
  resourceAssignment: {
    data: any[];
    columns: IColumnProps[];
  };
}

function getColumn(
  fieldName: string,
  isAutoSize: boolean,
  duplicationCodeChecker: DuplicationCodeCheckerType
) {
  const isCodeField = fieldName === "Code";

  const column = {
    caption: fieldName,
    dataField: fieldName,
    width: isAutoSize ? "auto" : 150,
    showEditorAlways: false,
    visibleIndex: isCodeField ? 0 : undefined,
    fixed: isCodeField && !duplicationCodeChecker.isFoundPreviously,
    alignment: "center",
    allowEditing: false,
  } as IColumnProps;

  // Is Code field is already marked as fixed, we will not make it fixed again if found duplicate
  if (isCodeField) {
    duplicationCodeChecker.isFoundPreviously = true;
  }

  return column;
}

const exportHandler = (
  exchangeAgreement: ExchangeAgreementDetailedResponse,
  activities: any[],
  schedules: any[],
  resourceAssignments: any[]
) => {
  return () => {
    const workbook = XLSX.utils.book_new();

    const worksheet = XLSX.utils.json_to_sheet(activities);
    XLSX.utils.book_append_sheet(workbook, worksheet, "Activities");

    const worksheet2 = XLSX.utils.json_to_sheet(schedules);
    XLSX.utils.book_append_sheet(workbook, worksheet2, "Schedule");

    const worksheet3 = XLSX.utils.json_to_sheet(resourceAssignments);

    XLSX.utils.book_append_sheet(workbook, worksheet3, "Resource Assignments");

    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const blob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });
    saveAs(
      blob,
      `${exchangeAgreement.id}_${exchangeAgreement.title} - Test Result.xlsx`
    );
  };
};

export default function useIEATestEventDataProviderHook(
  exchangeAgreement: ExchangeAgreementDetailedResponse
): IEATestEventDataProviderHookResponseType {
  const dispatch = useAppDispatch();
  const [response, setResponse] =
    useState<IEATestEventDataProviderHookResponseType>({
      uploadResult: null,
      downloadResult: null,
      comparisonResult: null,
      uploadedAt: null,
      downloadedAt: null,
      reloadTestResult: () => {},
      handleUploadExport: () => {},
      handleDownloadExport: () => {},
      handleComparisonExport: () => {},
    });

  const loadTestResult = useCallback(() => {
    if (
      exchangeAgreement &&
      exchangeAgreement.latestDataTransferTestEvent &&
      exchangeAgreement.latestDataTransferTestEvent.uploadStatus ===
        DataTransferStatus.Success
    ) {
      displayLoadingPanel();
      dispatch(
        loadTestResultAsync(exchangeAgreement.latestDataTransferTestEvent.id)
      ).finally(hideLoadingPanel);
    }
  }, [exchangeAgreement, dispatch]);

  useEffect(() => {
    loadTestResult();
    return () => {
      dispatch(resetTestResults());
    };
  }, [loadTestResult]);

  const testResult = useAppSelector(
    (store) => store.exchangeAgreementData.testResult
  );

  useEffect(() => {
    if (testResult?.uploadResult) {
      const isReadyToShowUploadResult =
        testResult.uploadResult && testResult.uploadResult.length > 0;
      const isReadyToShowDownloadResult =
        testResult.downloadResult && testResult.downloadResult.length > 0;

      let schedulesData: PlanningObjectTestDataResponse[][] = [];
      let activityData: PlanningObjectTestDataResponse[][] = [];
      let resourceAssignments: PlanningObjectTestDataResponse[][] = [];
      let scheduleIndex = 0;
      let activityIndex = 0;
      let resourceAssignmentIndex = 0;

      if (isReadyToShowUploadResult) {
        (testResult.uploadResult || []).forEach((result) => {
          let isSchedule = false;
          let isActivity = false;
          let isResourceAssignment = false;

          result.forEach((data) => {
            if (data.planningObjectType === PlanningObjectTypes.Activity) {
              isActivity = true;
              if (!activityData[activityIndex]) {
                activityData[activityIndex] = [];
              }
              activityData[activityIndex].push(data);
            } else if (
              data.planningObjectType === PlanningObjectTypes.Schedule
            ) {
              isSchedule = true;
              if (!schedulesData[scheduleIndex]) {
                schedulesData[scheduleIndex] = [];
              }
              schedulesData[scheduleIndex].push(data);
            } else if (
              data.planningObjectType === PlanningObjectTypes.ResourceUsage
            ) {
              isResourceAssignment = true;
              if (!resourceAssignments[resourceAssignmentIndex]) {
                resourceAssignments[resourceAssignmentIndex] = [];
              }
              resourceAssignments[resourceAssignmentIndex].push(data);
            }
          });

          if (isActivity) activityIndex++;
          if (isSchedule) scheduleIndex++;
          if (isResourceAssignment) resourceAssignmentIndex++;
        });
      }

      const uploadResults: PlanningObjectTestDataResponse[][][] = [];
      uploadResults.push(activityData);
      uploadResults.push(schedulesData);
      uploadResults.push(resourceAssignments);

      activityData = [];
      schedulesData = [];
      resourceAssignments = [];

      scheduleIndex = 0;
      activityIndex = 0;
      resourceAssignmentIndex = 0;

      if (isReadyToShowDownloadResult) {
        (testResult.downloadResult || []).forEach((result) => {
          let isSchedule = false;
          let isActivity = false;
          let isResourceAssignment = false;

          result.forEach((data) => {
            if (data.planningObjectType === PlanningObjectTypes.Activity) {
              isActivity = true;
              if (!activityData[activityIndex]) {
                activityData[activityIndex] = [];
              }
              activityData[activityIndex].push(data);
            } else if (
              data.planningObjectType === PlanningObjectTypes.Schedule
            ) {
              isSchedule = true;
              if (!schedulesData[scheduleIndex]) {
                schedulesData[scheduleIndex] = [];
              }
              schedulesData[scheduleIndex].push(data);
            } else if (
              data.planningObjectType === PlanningObjectTypes.ResourceUsage
            ) {
              isResourceAssignment = true;
              if (!resourceAssignments[resourceAssignmentIndex]) {
                resourceAssignments[resourceAssignmentIndex] = [];
              }
              resourceAssignments[resourceAssignmentIndex].push(data);
            }
          });
          if (isActivity) activityIndex++;
          if (isSchedule) scheduleIndex++;
          if (isResourceAssignment) resourceAssignmentIndex++;
        });
      }

      const downloadResults: PlanningObjectTestDataResponse[][][] = [];
      downloadResults.push(activityData);
      downloadResults.push(schedulesData);
      downloadResults.push(resourceAssignments);

      const createPlanningObjectFromFields = (
        data: PlanningObjectTestDataResponse[]
      ) => {
        let obj: any = {};
        data.forEach((d) => {
          obj[d.fieldName] = d.value;
        });
        return obj;
      };

      const getColumnsForComparison = (
        uploadColumns: IColumnProps[],
        downloadColumns: IColumnProps[]
      ) => {
        // Merge columns from both upload and download based on dataField
        const allColumns = Array.from(
          new Map(
            [...uploadColumns, ...downloadColumns].map((item) => [
              item.dataField,
              item,
            ])
          ).values()
        );

        return allColumns;
      };

      const codeColumnDuplicationChecker: DuplicationCodeCheckerType = {
        isFoundPreviously: false,
      };

      const activityColumnsForUpload = uploadResults[0][0]
        ? uploadResults[0][0].map((d) =>
            getColumn(d.fieldName, false, codeColumnDuplicationChecker)
          )
        : [];

      codeColumnDuplicationChecker.isFoundPreviously = false;
      const scheduleColumnsForUpload = uploadResults[1][0]
        ? uploadResults[1][0].map((d) =>
            getColumn(d.fieldName, false, codeColumnDuplicationChecker)
          )
        : [];

      codeColumnDuplicationChecker.isFoundPreviously = false;
      const resourceAssignmentColumnsForUpload = uploadResults[2][0]
        ? uploadResults[2][0].map((d) =>
            getColumn(d.fieldName, false, codeColumnDuplicationChecker)
          )
        : [];

      codeColumnDuplicationChecker.isFoundPreviously = false;
      const activityColumnsForDownload = downloadResults[0][0]
        ? downloadResults[0][0].map((d) =>
            getColumn(d.fieldName, false, codeColumnDuplicationChecker)
          )
        : [];

      codeColumnDuplicationChecker.isFoundPreviously = false;
      const scheduleColumnsForDownload = downloadResults[1][0]
        ? downloadResults[1][0].map((d) =>
            getColumn(d.fieldName, false, codeColumnDuplicationChecker)
          )
        : [];

      codeColumnDuplicationChecker.isFoundPreviously = false;
      const resourceAssignmentColumnsForDownload = downloadResults[2][0]
        ? downloadResults[2][0].map((d) =>
            getColumn(d.fieldName, false, codeColumnDuplicationChecker)
          )
        : [];

      const activityColumnsComparison = getColumnsForComparison(
        activityColumnsForUpload ?? [],
        activityColumnsForDownload ?? []
      );
      const scheduleColumnsComparison = getColumnsForComparison(
        scheduleColumnsForUpload ?? [],
        scheduleColumnsForDownload ?? []
      );
      const resourceAssignmentColumnsComparison = getColumnsForComparison(
        resourceAssignmentColumnsForUpload ?? [],
        resourceAssignmentColumnsForDownload ?? []
      );

      const uploadedActvities = uploadResults[0]
        ? uploadResults[0].map((r) => createPlanningObjectFromFields(r))
        : [];

      const uploadedSchedules = uploadResults[1]
        ? uploadResults[1].map((r) => createPlanningObjectFromFields(r))
        : [];

      const uploadedResourceAssignments = uploadResults[2]
        ? uploadResults[2].map((r) => createPlanningObjectFromFields(r))
        : [];

      const downloadedActvities = downloadResults[0]
        ? downloadResults[0].map((r) => createPlanningObjectFromFields(r))
        : [];

      const downloadedSchedules = downloadResults[1]
        ? downloadResults[1].map((r) => createPlanningObjectFromFields(r))
        : [];

      const downloadedResourceAssignments = downloadResults[2]
        ? downloadResults[2].map((r) => createPlanningObjectFromFields(r))
        : [];

      const mergeToCompare = (
        columns: IColumnProps[],
        upload: Record<string, any>,
        download: Record<string, any>
      ) => {
        if (upload.length !== download.length) {
          return [];
        }

        const comparisonData = [];

        for (var i = 0; i < upload.length; i++) {
          const mergedObject: Record<string, any> = {};
          columns.forEach((column) => {
            const uploadValue = upload[i][column.dataField] ?? " ";
            const downloadValue = download[i][column.dataField] ?? " ";
            mergedObject[
              column.dataField
            ] = `${uploadValue} | ${downloadValue}`;
          });
          comparisonData.push(mergedObject);
        }
        return comparisonData;
      };

      let isReadyToCompare =
        isReadyToShowUploadResult && isReadyToShowDownloadResult;

      const scheduleComparison = isReadyToCompare
        ? mergeToCompare(
            scheduleColumnsComparison,
            uploadedSchedules,
            downloadedSchedules
          )
        : [];
      const activityComparison = isReadyToCompare
        ? mergeToCompare(
            activityColumnsComparison,
            uploadedActvities,
            downloadedActvities
          )
        : [];
      const resourceAssignmentComparison = isReadyToCompare
        ? mergeToCompare(
            resourceAssignmentColumnsComparison,
            uploadedResourceAssignments,
            downloadedResourceAssignments
          )
        : [];

      const responseObj: IEATestEventDataProviderHookResponseType = {
        uploadedAt: testResult.uploadedAt,
        downloadedAt: testResult.downloadedAt,
        uploadResult: !isReadyToShowUploadResult
          ? null
          : {
              activity: {
                columns: activityColumnsForUpload,
                data: uploadedActvities,
              },
              schedule: {
                columns: scheduleColumnsForUpload,
                data: uploadedSchedules,
              },
              resourceAssignment: {
                columns: resourceAssignmentColumnsForUpload,
                data: uploadedResourceAssignments,
              },
            },
        downloadResult: !isReadyToShowDownloadResult
          ? null
          : {
              activity: {
                columns: activityColumnsForDownload,
                data: downloadedActvities,
              },
              schedule: {
                columns: scheduleColumnsForDownload,
                data: downloadedSchedules,
              },
              resourceAssignment: {
                columns: resourceAssignmentColumnsForDownload,
                data: downloadedResourceAssignments,
              },
            },
        comparisonResult: !isReadyToCompare
          ? null
          : {
              activity: {
                columns: activityColumnsComparison,
                data: activityComparison,
              },
              schedule: {
                columns: scheduleColumnsComparison,
                data: scheduleComparison,
              },
              resourceAssignment: {
                columns: resourceAssignmentColumnsComparison,
                data: resourceAssignmentComparison,
              },
            },
        handleComparisonExport: exportHandler(
          exchangeAgreement,
          activityComparison,
          scheduleComparison,
          resourceAssignmentComparison
        ),
        handleUploadExport: exportHandler(
          exchangeAgreement,
          uploadedActvities,
          uploadedSchedules,
          uploadedResourceAssignments
        ),
        handleDownloadExport: exportHandler(
          exchangeAgreement,
          downloadedActvities,
          downloadedSchedules,
          downloadedResourceAssignments
        ),

        reloadTestResult: loadTestResult,
      };

      setResponse(responseObj);
    }
  }, [testResult, loadTestResult]);

  return response;
}
