import PromineoTabPanel from "components/common/controls/PromineoTabPanel";
import PromineoButton, {
  PromineoButtonType,
} from "components/common/controls/buttons/PromineoButton";
import PromineoModal from "components/modal/PromineoModal";
import { Item } from "devextreme-react/tab-panel";
import useConfigMapapingAndValueTransformation from "hooks/DirectMappingAndValueTransformationHook";
import { ConfigDirection } from "shared/enums/feature/ConfigDirection";
import DirectMappingEditor from "../config-edit/direct-mapping/DirectMappingEditor";
import ConfigValueTransformationEditor from "../config-edit/config-value-transformation/ConfigValueTransformationEditor";
import {
  TemplateConfigUpdatePreviewModalHeight,
  TemplateConfigUpdatePreviewModalWidth,
  TemplateConfigUpdatePreviewTabPanelHeight,
} from "shared/constants/CommonConstants";
import { TemplateConfigUpdatePreviewGridHeight } from "shared/constants/GridConstants";
import TemplateConfigurationUpdatePreviewModalHeader from "features/common/template-configuration-update/TemplateConfigurationUpdatePreviewModalHeader";
import { useMemo, useState } from "react";
import { useAppDispatch } from "store/hooks";
import {
  getMappingForConfigUpdateBasedOnTemplateWriteRequest,
  getProcessedMappingForFieldMappingForConfigUpdateBasedOnTemplateChangeRequest,
} from "shared/utilities/ConfigUtility";
import ConfigDetailResponse from "interfaces/response/ConfigDetailResponse";
import { modifyConfigByAcceptingTemplateChangesAsync } from "store/actions/ConfigActions";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { toastError, toastSuccess } from "shared/utilities/ToastUtility";
import FieldMappingResponse from "interfaces/response/FieldMappingResponse";
import { checkIfResponseIsEqual } from "shared/utilities/CommonUtility";
import cloneDeep from "lodash/cloneDeep";
import ConfigUpdateBasedOnTemplateWriteRequest from "interfaces/request/ConfigUpdateBasedOnTemplateWriteRequest";

interface Props {
  configDirection: ConfigDirection;
  selectedCodeSet: number | null;
  onClose: (isUpdateConfigSuccess?: boolean) => void;
  config: ConfigDetailResponse;
  addedFieldMappings: FieldMappingResponse[];
  updatedFieldMappings: FieldMappingResponse[];
}

export default function ConfigUpdatePreviewDueToFieldUpdateModal(props: Props) {
  const { config, addedFieldMappings, updatedFieldMappings } = props;
  const dispatch = useAppDispatch();
  const [contentControlFieldMapping, setContentControlFieldMapping] = useState(
    updatedFieldMappings.filter((f) => !f.hasDirectMapping)
  );

  const initialDirectMappings = useMemo(() => {
    return [...addedFieldMappings];
  }, [addedFieldMappings]);

  const configMappingAndValueTransformationHookProps = useMemo(() => {
    return {
      isEdit: false,
      configDirection: props.configDirection,
      height: TemplateConfigUpdatePreviewGridHeight,
      initialDirectMappings: initialDirectMappings,
      initialValueTranformations: initialDirectMappings.filter(
        (m) => !m.hasDirectMapping
      ),
      selectedCodeSet: props.selectedCodeSet,
      gridIdentifier: "configUpdatePreviewMappingAndValueTransformation",
    };
  }, [
    props.configDirection,
    TemplateConfigUpdatePreviewGridHeight,
    initialDirectMappings,
    props.selectedCodeSet,
  ]);

  const {
    configDirectMappings,
    configValueTransformations,
    directMappingEditorProps,
    valueTransformationGridProps,
  } = useConfigMapapingAndValueTransformation(
    configMappingAndValueTransformationHookProps
  );

  const handleUpdateConfigClick = () => {
    let mappings =
      getProcessedMappingForFieldMappingForConfigUpdateBasedOnTemplateChangeRequest(
        configDirectMappings,
        configValueTransformations
      );

    if (contentControlFieldMapping && contentControlFieldMapping.length) {
      contentControlFieldMapping.forEach((fieldMapping) =>
        mappings.push(
          getMappingForConfigUpdateBasedOnTemplateWriteRequest(fieldMapping)
        )
      );
    }

    mappings.forEach((m) => {
      m.valueMaps = m.valueMaps.filter((v) => v.value && v.value.trim());
    });

    const configUpdateRequest: ConfigUpdateBasedOnTemplateWriteRequest = {
      mappingsForConfigUpdateBasedOnTemplate: mappings,
    };

    displayLoadingPanel();
    dispatch(
      modifyConfigByAcceptingTemplateChangesAsync({
        configId: config.id,
        configUpdateRequest: configUpdateRequest,
      })
    )
      .unwrap()
      .then(() => {
        toastSuccess("Configuration successfully updated.");
        props.onClose(true);
      })
      .finally(hideLoadingPanel);
  };

  const handleContentControlFieldMappingChange = (
    data: FieldMappingResponse
  ) => {
    setContentControlFieldMapping((prev) => {
      return prev.map((p) =>
        checkIfResponseIsEqual(p, data) ? cloneDeep(data) : p
      );
    });
  };

  return (
    <PromineoModal
      isVisible={true}
      width={TemplateConfigUpdatePreviewModalWidth}
      height={TemplateConfigUpdatePreviewModalHeight}
    >
      <TemplateConfigurationUpdatePreviewModalHeader
        title="Template Updates"
        description="
        Provide the direct mapping and/or value transformations for the added fields to update the config."
      />
      <div className="mt-4">
        <PromineoTabPanel height={TemplateConfigUpdatePreviewTabPanelHeight}>
          <Item title={"Direct Mapping"}>
            <div className="pt-4">
              <DirectMappingEditor
                {...directMappingEditorProps}
                isEdit={true}
                isPreviewChange={true}
              />
            </div>
          </Item>
          <Item title={"Value Transformation"}>
            <div className="pt-4">
              <ConfigValueTransformationEditor
                {...valueTransformationGridProps}
                hideAddCoreFieldButton={true}
                isPreviewChange={true}
              />
            </div>
          </Item>
          <Item title={"Content Control"}>
            <div className="pt-4">
              <ConfigValueTransformationEditor
                configDirection={props.configDirection}
                configFieldMappings={contentControlFieldMapping}
                height={TemplateConfigUpdatePreviewGridHeight}
                onIlapCoreFieldDelete={() => {}}
                onMappingFormulaUpdate={() => {}}
                onNewIlapCoreFieldAdd={() => {}}
                selectedCodeSet={props.selectedCodeSet}
                onValueMapsUpdate={handleContentControlFieldMappingChange}
                viewOnlyMappingFormula={true}
                displayActionColumn={false}
                hideAddCoreFieldButton={true}
                isPreviewChange={true}
              />
            </div>
          </Item>
        </PromineoTabPanel>
      </div>
      <div className="flex justify-between mt-4">
        <PromineoButton
          text="Cancel"
          variant={PromineoButtonType.Secondary}
          onClick={() => props.onClose()}
        />
        <PromineoButton
          text="Update Config"
          onClick={handleUpdateConfigClick}
        />
      </div>
    </PromineoModal>
  );
}
