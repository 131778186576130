import PromineoViewGrid, {
  PromineoGridToolbarConfig,
  RowOperationConfig,
} from "components/common/grid/PromineoViewGrid";
import "components/common/grid/styles/PromineoUIGrid.css";
import { Column, Scrolling } from "devextreme-react/data-grid";
import ActiveExchangeAgreementListingResponse from "interfaces/response/ActiveExchangeAgreementListingResponse";
import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { ACTIVE_IEA } from "shared/constants/RoutePathConstants";
import { booleanSortComparator } from "shared/utilities/CommonUtility";
import DownloadStatusCellComponent from "./DownloadStatusCellComponent";
import DownloadWarningCountComponent from "./DownloadWarningCountComponent";
import LastSucessfulTransferCellComponent from "./LastSucessfulTransferCellComponent";
import UploadActivityCountComponent from "./UploadActivityCountComponent";
import UploadStatusCellComponent from "./UploadStatusCellComponent";
import UploadWarningCountComponent from "./UploadWarningCountComponent";
import TitleCellComponent from "./active-iea-grid-cell-component/TitleCellComponent";
import { useAppDispatch, useAppSelector } from "store/hooks";
import PromineoConfirmationDialog from "components/common/controls/PromineoConfirmationDialog";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { loadExchangeAgreementDetailsAsync } from "store/actions/ExchangeAgreementActions";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { resetSelectedExchangeAgreement } from "store/slices/ExchangeAgreementSlice";
import DuplicateIEAWizard from "./DuplicateIEAWizard";

interface Props {
  activeIEAs: ActiveExchangeAgreementListingResponse[];
  height: number;
  onCreateNewClick: () => void;
  gridWidget?: ReactElement;
}

const ieaInfoTabIndex = 0;
const eventLogTabIndex = 1;

export default function ActiveIEAsGrid(props: Props) {
  const { activeIEAs, height } = props;
  let navigate = useNavigate();
  const dispatch = useAppDispatch();

  const mySelfData = useAppSelector((store) => store.userData.mySelfResponse);
  const exchangeAgreement = useAppSelector(
    (store) => store.exchangeAgreementData.selectedExchangeAgreement
  );

  const [isIEADuplicateWizardVisible, setIsIEADuplicateWizardVisible] =
    useState<boolean>(false);

  const handleNewIEAClick = useCallback(() => {
    props.onCreateNewClick();
  }, []);

  const handleViewIEAClick = useCallback(
    (activeIEA: ActiveExchangeAgreementListingResponse) => {
      navigate(`${ACTIVE_IEA}/${activeIEA.id}`, {
        state: { selectedTabIndex: ieaInfoTabIndex },
      });
    },
    []
  );

  const handleEventLogClick = useCallback(
    (activeIEA: ActiveExchangeAgreementListingResponse) => {
      navigate(`${ACTIVE_IEA}/${activeIEA.id}`, {
        state: { selectedTabIndex: eventLogTabIndex },
      });
    },
    []
  );

  const handleRowClick = useCallback((data: any) => {
    handleViewIEAClick(data.data);
  }, []);

  const toolbarConfig = useMemo<PromineoGridToolbarConfig>(() => {
    return {
      dislplaySearchPanel: true,
      addNewButtonOptions: {
        isVisible: true,
        text: "New IEA",
        onClick: handleNewIEAClick,
        disabled: !mySelfData?.tenant?.canBeOwner,
      },
    };
  }, [mySelfData]);

  const rowOperationConfig = useRef<RowOperationConfig>({
    visible: true,
    items: [
      {
        onClick: (data: { data: ActiveExchangeAgreementListingResponse }) =>
          handleViewIEAClick(data.data),
        text: "View",
        visible: true,
      },
      {
        onClick: (data: { data: ActiveExchangeAgreementListingResponse }) =>
          handleEventLogClick(data.data),
        text: "Event log",
        visible: true,
      },
      {
        onClick: (data: { data: ActiveExchangeAgreementListingResponse }) =>
          handleDuplicateIEAClick(data.data),
        text: "Duplicate IEA",
        visibleFn: (data: {data: ActiveExchangeAgreementListingResponse}) => {
          return data.data.isCurrentUserOwner;
        },
      },
    ],
  });


  const hideDuplicateIEAWizard = () => {
    setIsIEADuplicateWizardVisible(false);
  };


  const handleDuplicateIEAClick = (
    agreementData: ActiveExchangeAgreementListingResponse
  ) => {
    if (agreementData.id) {
      loadExchangeAgreementToDuplicate(agreementData.id);
    }
  };

  const loadExchangeAgreementToDuplicate = (agreementId: number) => {
    if (agreementId) {
      displayLoadingPanel();
      dispatch(loadExchangeAgreementDetailsAsync(agreementId))
        .unwrap()
        .then((response: ExchangeAgreementDetailedResponse) => {
          setIsIEADuplicateWizardVisible(true);
        })
        .finally(hideLoadingPanel);
    }
  };

  useEffect(() => {
    return () => {
      // Need to reset while unmounting because IEA may have been loaded.
      dispatch(resetSelectedExchangeAgreement());
    };
  }, []);

  return (
    <>
      <PromineoViewGrid
        dataSource={activeIEAs}
        height={height}
        className="active-ieas-grid promineo-ui-grid"
        onRowClick={handleRowClick}
        toolbarConfig={toolbarConfig}
        rowOperationConfig={rowOperationConfig.current}
        additionalWidget={props.gridWidget}
        gridIdentifier="activeIeasGrid"
        noDataText="Your ILAP exchange agreements (IEAs) will appear here once they start!"
      >
        <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
        <Column
          caption={"Upload status"}
          cellComponent={UploadStatusCellComponent}
          width={60}
        />
        <Column
          cssClass={"custom-control-padding ignore-row-click"}
          caption={"Download status"}
          cellComponent={DownloadStatusCellComponent}
          width={80}
        />
        <Column caption={"IEA Id"} dataField="id" alignment="left" width={70} />
        <Column
          caption={"IEA Title"}
          dataField="title"
          alignment="left"
          cellComponent={TitleCellComponent}
          calculateSortValue={"updateRequired"}
          sortingMethod={booleanSortComparator}
          sortOrder={"desc"}
          sortIndex={0}
          minWidth={180}
        />
        <Column
          caption={"Template"}
          dataField="templateName"
          alignment="left"
          minWidth={90}
        />
        <Column
          caption={"Sending connector (host system)"}
          dataField="sendingConnectorText"
          alignment="left"
          width={150}
        />
        <Column
          caption={"Receiving connector (host system)"}
          dataField="receivingConnectorText"
          alignment="left"
          width={160}
        />
        <Column
          caption={"Sender"}
          dataField="sender"
          alignment="left"
          width={75}
        />
        <Column
          caption={"Receiver"}
          dataField="receiver"
          alignment="left"
          width={85}
        />
        <Column
          caption={"#Of Activities"}
          dataField="uploadActivityCount"
          alignment="left"
          cellComponent={UploadActivityCountComponent}
          width={90}
        />
        <Column
          caption={"Last successful transfer"}
          dataField="lastSuccessfulTransfer"
          alignment="left"
          cellComponent={LastSucessfulTransferCellComponent}
          width={150}
          sortIndex={1}
          sortOrder={"asc"}
        />
        <Column
          caption={"#Upload warnings"}
          dataField="uploadWarningCount"
          alignment="left"
          cellComponent={UploadWarningCountComponent}
          width={90}
        />
        <Column
          caption={"#Download warnings"}
          dataField="downloadWarningCount"
          alignment="left"
          cellComponent={DownloadWarningCountComponent}
          width={105}
        />
        <Column
          caption={"Role"}
          dataField="roleText"
          alignment="left"
          width={60}
        />
      </PromineoViewGrid>

      {isIEADuplicateWizardVisible && (
        <DuplicateIEAWizard
          onClose={hideDuplicateIEAWizard}
          exchangeAgreementToDuplicate={exchangeAgreement}
        />
      )}
    </>
  );
}
