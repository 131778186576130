import UploadTestIcon from "components/icons/UploadTestIcon";
import ExchangeAgreementDetailedResponse from "interfaces/response/ExchangeAgreementDetailedResponse";
import { ExecutionComponent } from "shared/enums/feature/ExecutionComponent";
import TestResultPlaceHolder from "./TestResultPlaceHolder";

interface Props {
  exchangeAgreement: ExchangeAgreementDetailedResponse;
  isCurrentUserSender: boolean;
  isCurrentUserOwner: boolean;
}

export default function PendingDownloadTestEventPlaceHolder(props: Props) {
  const iea = props.exchangeAgreement;

  let executComponentText = "execution";
  let executComponent = ExecutionComponent.Desktop;

  if (props.isCurrentUserSender) {
    if (props.isCurrentUserOwner) {
      executComponent = iea.partnerConnector.executionComponent;
    } else {
      executComponent = iea.ownerConnector.executionComponent;
    }
  } else {
    if (props.isCurrentUserOwner) {
      executComponent = iea.ownerConnector.executionComponent;
    } else {
      executComponent = iea.partnerConnector.executionComponent;
    }
  }

  if (executComponent === ExecutionComponent.Desktop) {
    executComponentText = "desktop";
  } else if (executComponent === ExecutionComponent.Service) {
    executComponentText = "autonomous";
  }

  return (
    <TestResultPlaceHolder
      icon={<UploadTestIcon />}
      title={`Download is pending`}
      subtitle={`Download is pending in receiver's ${executComponentText} component.`}
    />
  );
}
