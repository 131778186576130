import PromineoPopover from "components/common/controls/PromineoPopover";
import { Offset, Position } from "devextreme-react/popover";
import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { Column, Scrolling } from "devextreme-react/data-grid";
import CoreFieldResponse from "interfaces/response/CoreFieldResponse";
import { useCallback } from "react";
import { DefaultToolbarConfigWithNoSearchbar } from "shared/utilities/PromineoGridUtility";

interface Props {
  dataSource: CoreFieldResponse[];
  target: string;
  visible: boolean;
  onHiding: () => void;
  setCoreFieldToAdd: (data: CoreFieldResponse) => void;
  offsetX?: number;
  offsetY?: number;
  minWidth?: number;
}

export default function AddIlapCoreFieldPopOverGrid(props: Props) {
  const handleRowClick = useCallback((evt: { data: CoreFieldResponse }) => {
    props.setCoreFieldToAdd(evt.data);
    props.onHiding();
  }, []);

  return (
    <div>
      <PromineoPopover
        width={"auto"}
        minWidth={props.minWidth ? props.minWidth : 109}
        height={"auto"}
        visible={props.visible}
        target={props.target}
        onHiding={props.onHiding}
      >
        <Position my={{ x: "right", y: "top" }}>
          <Offset
            x={props.offsetX ? props.offsetX : 45}
            y={props.offsetY ? props.offsetY : -5}
          />
        </Position>
        <div className="pt-2 pb-4 px-4">
          <PromineoViewGrid
            dataSource={props.dataSource}
            height={320}
            width={594}
            onRowClick={handleRowClick}
            gridIdentifier="addIlapCoreFieldPopOverGrid"
            toolbarConfig={DefaultToolbarConfigWithNoSearchbar}
          >
            <Scrolling mode="virtual" rowRenderingMode="virtual" />
            <Column
              dataField={"planningObjectTypeText"}
              caption="Planning object type"
              width={150}
              sortOrder={"asc"}
              sortIndex={0}
            />
            <Column
              dataField={"name"}
              caption="Name"
              sortOrder={"asc"}
              sortIndex={1}
            />
            <Column
              dataField={"dataTypeText"}
              caption="Data type"
              width={100}
            />
          </PromineoViewGrid>
        </div>
      </PromineoPopover>
    </div>
  );
}
