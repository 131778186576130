import React from "react";

interface Props {
  icon: React.ReactNode;
  title: string;
  subtitle: string;
}

export default function TestResultPlaceHolder({
  icon,
  title,
  subtitle,
}: Props) {
  return (
    <div className="text-center font-poppins items-center justify-center flex flex-col">
      {icon}
      <div className="mt-2">
        <span className="font-semibold text-sm leading-[21px] text-textGray">
          {title}
        </span>
      </div>
      <div className="w-[240px] text-wrap mt-2">
        <span className="text-xs font-normal leading-[18px] text-lightTextGray">
          {subtitle}
        </span>
      </div>
    </div>
  );
}
