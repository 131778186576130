import PromineoViewGrid from "components/common/grid/PromineoViewGrid";
import { FormulaPickerAccordionDataSource } from "./FormulaPickerAccordion";
import FieldMappingFormulaResponse from "interfaces/response/FieldMappingFormulaResponse";
import FavoriteIcon from "components/icons/FavoriteIcon";
import { useCallback, useEffect, useRef, useState } from "react";
import FieldMappingFormulaFavoriteStatusChangeRequest from "interfaces/request/FieldMappingFormulaFavoriteStatusChangeRequest";
import {
  displayLoadingPanel,
  hideLoadingPanel,
} from "components/common/LoadingPanel";
import { useAppDispatch, useAppSelector } from "store/hooks";
import {
  modifyFieldMappingFormulaFavoriteStatusAsync,
  removeFieldMappingFormulaAsync,
} from "store/actions/FieldMappingFormulaActions";
import { FieldMappingFormulaType } from "shared/enums/FieldMappingFormulaType";
import TrashIcon from "components/icons/TrashIcon";
import { Column, Scrolling } from "devextreme-react/data-grid";
import {
  addOrUpdateCustomFormulaInFormulaPickerDataSource,
  removeCustomFormulaFromFormulaPickerDataSource,
  updateSystemFormulaInFormulaPickerDataSource,
} from "store/actions/FieldMappingFormulaPickerUIAction";
import FilledFavoriteIcon from "components/icons/FilledFavoriteIcon";
import { PlanningObjectTypes } from "shared/enums/feature/PlanningObjectTypes";

interface Props {
  dataSource: FormulaPickerAccordionDataSource;
  onFormulaSelection: (formula: FieldMappingFormulaResponse) => void;
  mappingPlanningObjectType: PlanningObjectTypes;
}

export default function FormulaPickerAccordionItem(props: Props) {
  const { dataSource, onFormulaSelection, mappingPlanningObjectType } = props;

  const dispatch = useAppDispatch();
  const [gridDataSource, setGridDataSource] = useState<
    FieldMappingFormulaResponse[]
  >([]);

  const showFavoriteCustomFormulaOnly = useAppSelector(
    (store) =>
      store.fieldMappingFormulaPickerUIData.showFavoriteCustomFormulaOnly
  );
  const showFavoriteSystemFormulaOnly = useAppSelector(
    (store) =>
      store.fieldMappingFormulaPickerUIData.showFavoriteSystemFormulaOnly
  );
  const systemFormulas = useAppSelector(
    (store) => store.fieldMappingFormulaPickerUIData.systemFormulas
  );
  const customFormulas = useAppSelector(
    (store) => store.fieldMappingFormulaPickerUIData.customFormulas
  );
  const coreFieldFormulas = useAppSelector(
    (store) => store.fieldMappingFormulaPickerUIData.coreFieldFormulas
  );

  const formulaSearchText = useAppSelector(
    (store) => store.fieldMappingFormulaPickerUIData.formulaSearchText
  );

  const [coreFieldFormulaDataSource, setCoreFieldFormulaDataSource] = useState<FieldMappingFormulaResponse[]>([]);
  const [systemFormulaDataSource, setSystemFormulaDataSource] = useState<FieldMappingFormulaResponse[]>([]);
  const [customFormulaDataSource, setCustomFormulaDataSource] = useState<FieldMappingFormulaResponse[]>([]);

  useEffect(()=>{
    let formulaSearchTextLowercase = formulaSearchText?.toLowerCase() ?? "";
    if (dataSource.formulaType === FieldMappingFormulaType.CoreField) {
      let newFormulaList = coreFieldFormulas.filter(
        (f) =>
          f.planningObjectType && f.planningObjectType === mappingPlanningObjectType && 
          (!formulaSearchTextLowercase ||
            f.description.toLowerCase().includes(formulaSearchTextLowercase) ||
            f.formula.toLowerCase().includes(formulaSearchTextLowercase))
      );
      setCoreFieldFormulaDataSource(newFormulaList);
    } else if (dataSource.formulaType === FieldMappingFormulaType.System) {
      let newFormulaList = systemFormulas.filter(
        (f) =>
          !formulaSearchTextLowercase ||
          f.description.toLowerCase().includes(formulaSearchTextLowercase) ||
          f.formula.toLowerCase().includes(formulaSearchTextLowercase)
      );
      setSystemFormulaDataSource(newFormulaList);
    } else if (dataSource.formulaType === FieldMappingFormulaType.Custom) {
      let newFormulaList = customFormulas.filter(
        (f) =>
          !formulaSearchTextLowercase ||
          f.description.toLowerCase().includes(formulaSearchTextLowercase) ||
          f.formula.toLowerCase().includes(formulaSearchTextLowercase)
      );
      setCustomFormulaDataSource(newFormulaList);
    }
  },[formulaSearchText, systemFormulas, customFormulas, coreFieldFormulas]);

  useEffect(() => {
    if (dataSource.formulaType === FieldMappingFormulaType.CoreField) {
      setGridDataSource(coreFieldFormulaDataSource);
    }
  }, [coreFieldFormulaDataSource]);

  useEffect(() => {
    if (dataSource.formulaType === FieldMappingFormulaType.System) {
      let formulaList = systemFormulaDataSource;
      if (showFavoriteSystemFormulaOnly) {
        formulaList = formulaList.filter((f) => f.isFavoriteOfCurrentUser);
      }
      setGridDataSource(formulaList);
    }
  }, [systemFormulaDataSource, showFavoriteSystemFormulaOnly]);

  useEffect(() => {
    if (dataSource.formulaType === FieldMappingFormulaType.Custom) {
      let formulaList = customFormulaDataSource;
      if (showFavoriteCustomFormulaOnly) {
        formulaList = formulaList.filter((f) => f.isFavoriteOfCurrentUser);
      }
      setGridDataSource(formulaList);
    }
  }, [customFormulaDataSource, showFavoriteCustomFormulaOnly]);

  const onFormulaFavouriteChange = useCallback(
    (event: any, formulaId: number, newFavoriteState: boolean) => {
      event.stopPropagation();
      event.preventDefault();
      let requestDto: FieldMappingFormulaFavoriteStatusChangeRequest = {
        isFavorite: newFavoriteState,
      };
      displayLoadingPanel();
      dispatch(
        modifyFieldMappingFormulaFavoriteStatusAsync({
          fieldMappingFormulaId: formulaId,
          requestDto: requestDto,
        })
      )
        .unwrap()
        .then((updatedFormula: FieldMappingFormulaResponse) => {
          if(updatedFormula.formulaType === FieldMappingFormulaType.System){
            dispatch(
              updateSystemFormulaInFormulaPickerDataSource(updatedFormula)
            );
          }
          else if(updatedFormula.formulaType === FieldMappingFormulaType.Custom){
            dispatch(
              addOrUpdateCustomFormulaInFormulaPickerDataSource(updatedFormula)
            );
          }
          
        })
        .finally(hideLoadingPanel);
    },
    []
  );

  const onFormulaDeleteClick = useCallback((event: any, formulaId: number) => {
    event.stopPropagation();
    event.preventDefault();
    displayLoadingPanel();
    dispatch(removeFieldMappingFormulaAsync(formulaId))
      .unwrap()
      .then(() => {
        dispatch(removeCustomFormulaFromFormulaPickerDataSource(formulaId));
      })
      .finally(hideLoadingPanel);
  }, []);

  const handleRowClick = useCallback(
    (evt: { data: FieldMappingFormulaResponse }) => {
      onFormulaSelection(evt.data);
    },
    [onFormulaSelection]
  );

  const deleteIconColumnComponent = useCallback(
    (data: { data: FieldMappingFormulaResponse }) => {
      return data.data.isCurrentUserCreator ? (
        <div
          className="mt-[3px]"
          onClick={(event) => {
            onFormulaDeleteClick(event, data.data.id);
          }}
        >
          <TrashIcon />
        </div>
      ) : (
        <></>
      );
    },
    []
  );

  const favoriteIconColumnComponent = useCallback(
    (data: { data: FieldMappingFormulaResponse }) => {
      return dataSource.formulaType !== FieldMappingFormulaType.CoreField ? (
        <div className="">
          {data.data.isFavoriteOfCurrentUser ? (
            <div
              onClick={(event) =>
                onFormulaFavouriteChange(event, data.data.id, false)
              }
            >
              <FilledFavoriteIcon />
            </div>
          ) : (
            <div
              onClick={(event) =>
                onFormulaFavouriteChange(event, data.data.id, true)
              }
            >
              <FavoriteIcon />
            </div>
          )}
        </div>
      ) : (
        <></>
      );
    },
    []
  );

  return (
    <PromineoViewGrid
      height={300}
      width="auto"
      dataSource={gridDataSource}
      onRowClick={handleRowClick}
      className="promineo-ui-grid"
      showColumnHeaders={false}
      gridIdentifier="formulaPickerGrid"
    >
      <Scrolling mode={"virtual"} rowRenderingMode={"virtual"} />
      <Column caption={"formula"} dataField={"formula"} />
      <Column caption={"description"} dataField={"description"} />
      <Column
        width={30}
        caption={"delete icon"}
        cellRender={deleteIconColumnComponent}
      />
      <Column
        caption={"favourite icon"}
        width={30}
        cellRender={favoriteIconColumnComponent}
      />
    </PromineoViewGrid>
  );
}
